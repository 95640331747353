@font-face {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url("/assets/fonts/fa5/fa-regular-400.eot");
	src: url("/assets/fonts/fa5/fa-regular-400.eot?#iefix") format("embedded-opentype"),
	url("/assets/fonts/fa5/fa-regular-400.woff2") format("woff2"),
	url("/assets/fonts/fa5/fa-regular-400.woff") format("woff"),
	url("/assets/fonts/fa5/fa-regular-400.ttf") format("truetype"),
	url("/assets/fonts/fa5/fa-regular-400.svg#fontawesome") format("svg");
}


@font-face {
	font-family: 'Roboto';
	font-display: auto;
	src: url('/assets/fonts/robotoregular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/robotoregular/Roboto-Regular.woff') format('woff'),
	url('/assets/fonts/robotoregular/Roboto-Regular.ttf')  format('truetype'),
	url('/assets/fonts/robotoregular/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	font-display: auto;
	src: url('/assets/fonts/robotolight/RobotoLight.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/robotolight/RobotoLight.woff') format('woff'),
	url('/assets/fonts/robotolight/RobotoLight.ttf')  format('truetype');
	font-weight: 100;
	font-style: normal;
}


@font-face {
	font-family: 'Roboto';
	font-display: auto;
	src: url('/assets/fonts/robotomedium/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/robotomedium/Roboto-Medium.woff') format('woff'),
	url('/assets/fonts/robotomedium/Roboto-Medium.ttf')  format('truetype'),
	url('/assets/fonts/robotomedium/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: 600;
	font-style: normal;
}


@font-face {
	font-family: 'Roboto';
	font-display: auto;
	src: url('/assets/fonts/robotobold/RobotoBold.eot?#iefix') format('embedded-opentype'),
	url('/assets//fonts/robotobold/RobotoBold.woff') format('woff'),
	url('/assets//fonts/robotobold/RobotoBold.ttf')  format('truetype'),
	url('/assets//fonts/robotobold/RobotoBold.svg#Roboto-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
