@import "../vars";


.news-block {
	background: #fff;
	padding: 40px 0;

	&__title {
		color: #000;
		font-size: 32px;
		line-height: 32px;
		text-align: center;

		& > span {
			color: #777;
			display: block;
			font-size: 18px;
			font-weight: 100;
			line-height: 24px;
			padding: 7px 0 0;
			text-align: center;
		}
	}

	&__wrapper {
		padding: 35px 0;

		& > .row {
			margin: 0;

			@include media(md) {
				margin: 0 -0.5em;
			}
		}

	}
}



.last-news {

	&__item {
		margin: 0 0 20px;

		&.col-4 {
			@include media(md) {
				padding: 0 0.5em;
			}
		}

		@include media(md) {
			&.col-4 {
				float: none;
				width: 100%;
			}
		}
	}

	&__cat-title {
		border-bottom: 1px solid #e8e8e8;
		padding: 0 0 15px;
		text-align: center;

		& > span {
			border-bottom: 2px solid #0b7275;
			color: #000;
			display: inline-block;
			font-size: 22px;
			line-height: 33px;
			padding-bottom: 6px;
			position: relative;
		}
	}


	&__img {
		margin: 0 0 20px;

		& > a {
			display: block;

			& > img {
				display: block;
				height: auto;
				max-width: 400px;
				width: 100%;
			}
		}
	}

	&__title {
		margin: 0 0 10px;

		& > a {
			color: #000;
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			line-height: 19px;
		}
	}


	&__text {
		font-size: 13px;
	}
}

.no-mobile {
	.last-news {

		&__img {
			opacity: 1;

			&:hover {
				& > a {
					& > img {
						opacity: 0.8;
					}
				}
			}
		}

		&__title {
			& > a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}


.news-header {
	margin: 24px 0 38px;

	&__title {
		margin: 0 0 20px;
		text-align: center;

		& > span {
			color: #fff;
			display: inline-block;
			font-size: 28px;
			font-weight: bold;
			line-height: 42px;
		}
	}

	&__desc {
		color: #fff;
		font-size: 16px;
		text-align: center;
	}
}

.news-list {
	background: #fff;
	padding: 30px 0;

	&__title {
		border-bottom: 1px solid #e8e8e8;
		padding: 0 0 15px;
		text-align: center;

		& > span {
			border-bottom: 2px solid #0b7275;
			color: #000;
			display: inline-block;
			font-size: 21px;
			padding-bottom: 6px;
			position: relative;
			bottom: -1px;
		}
	}
}


.hot-news-item {
	margin: 0 7px;

	&__img {
		& > a {
			display: block;

			& > img {
				display: block;
				height: auto;
				max-width: 648px;
				width: 100%;
			}
		}

	}

	&__info {
		background: #f7f7f7;
		border: 1px solid #eee;
		padding: 15px 15px 10px;
	}


	&__title {
		margin: 0 0 5px;

		& > a {
			color: #000;
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			overflow-wrap: break-word;
			word-wrap: break-word;
		}
	}

	&__date {
		font-size: 12px;
		color: #777;
		list-style: none;
		margin: 0 0 5px;
	}
}

.no-mobile {
	.hot-news-item {

		&__img {
			& > a {
				& > img {
					opacity: 1;
					transition: all 0.25s;
				}
			}

			&:hover {
				& > a {
					& > img {
						opacity: 0.8;
					}
				}
			}
		}

		&__title {
			& > a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.hot-news-slider,
.job-news-slider {
	padding: 0 0 40px;

	@include media(md) {
		padding: 0 0 60px;
	}

	.slick-arrow {
		background: transparent;
		height: 32px;
		width: 32px;

		&:before {
			background: #2c87f0;
			border-radius: 2px;
			font-size: 19px;
			line-height: 19px;
			margin: -10px 0 0 -10px;
			height: 20px;
			width: 20px;
		}
	}

	.slick-next {
		right: auto;
		left: 3em;
		top: auto;
		bottom: 1em;
	}

	.slick-prev {
		left: 6px;
		top: auto;
		bottom: 1em;
	}


}

.no-mobile {
	.hot-news-slider,
	.job-news-slider {
		.slick-arrow {
			background: transparent;
		}
	}
}

.job-news-item {
	margin: 0 7px;

	&__img {
		& > a {
			display: block;

			& > img {
				display: block;
				height: auto;
				max-width: 680px;
				width: 100%;
			}
		}
	}

	&__info {
		background: #f7f7f7;
		border: 1px solid #eee;
		padding: 15px 15px 10px;
	}

	&__title {
		& > a {
			color: #000;
			display: inline-block;
			font-size: 14px;
			line-height: 23px;
			text-transform: uppercase;
		}
	}
}


.all-news-block {
	background: #fff;
	padding: 30px 0;

	&__title {
		border-bottom: 1px solid #e8e8e8;
		padding: 15px 0 10px;
		text-align: center;

		& > span {
			border-bottom: 2px solid #0b7275;
			color: #000;
			display: inline-block;
			font-size: 21px;
			padding-bottom: 6px;
			position: relative;
			bottom: -1px;
		}
	}

	.news-item {
		border-bottom: 1px solid #eee;
		position: relative;
		margin: 0 0 20px;
		padding-left: 85px;
		padding-bottom: 20px;

		&__date {
			position: absolute;
			left: 0;
			top: 0;
			width: 60px;
			padding: 5px 0 0;
			text-align: center;
			border: 1px solid #ddd;
			background: #f7f7f7;
			border-bottom: none;

			.day {
				display: block;
				font-size: 24px;
				color: #444;
				font-weight: 300;
				padding: 0 5px 5px;
			}

			.month {
				display: block;
				font-size: 11px;
				padding: 2px 5px;
				font-weight: 400;
				text-transform: uppercase;
				position: relative;
				margin: -1px -1px 0;
				background-color: #2c87f0;
				color: #fff;
			}
		}

		&__img {
			& > a {
				display: block;

				& > img {
					display: block;
					height: auto;
					max-width: 638px;
					width: 100%;
				}
			}
		}

		&__info {
			padding: 20px 0;
		}

		&__title {
			margin: 0 0 10px;

			& > a {
				color: #000;
				display: inline-block;
				font-size: 14px;
				font-weight: 600;
			}
		}

		.readmore-link {
			background: #333;
			border-radius: 3px;
			color: #fff;
			display: inline-block;
			font-size: 12px;
			line-height: 19px;
			height: 32px;
			padding: 6px 13px;
			transition: 0.15s all ease;
		}
	}
}


.no-mobile {
	.all-news-block {

		.news-item {

			&__title {
				& > a {
					&:hover {
						text-decoration: none;
					}
				}
			}

			.readmore-link {
				&:hover {
					background: #555;
					text-decoration: none;
				}
			}
		}
	}
}



