@import "../vars";


.input-text,
.input-textarea {
  @include input();
}


.input-textarea {
  padding: 1em 1em 0.5em 1em;
  height: 224px;
  line-height: 17px;
}


textarea {
  display: block;
  resize: none;
  padding: 0.5em 1em;
}


.check-status-form,
.profile-table {
  .input-text,
  .input-textarea {
    padding: 0 1em;
  }
}


.form__label {
  color: $grey;
  font-size: 14px;
  pointer-events: none;
  line-height: 1.5;
  transition: 0.2s ease-out;
}


.call-back-form {
  .input-form {
    padding: 13px 1em 0;
  }
}


.files_element {
  position: relative;
}


.field {
  margin: 18px 0 0;
  position: relative;
  text-align: left;

  &.file {

    .button {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;

      & > span {
        height: 36px;
        line-height: 35px;
      }
    }

    .input_upload {
      position: absolute;
      visibility: hidden;
    }
  }

  &.personal {
    padding: 0.5em 0;
    margin: 0;

    label {
      color: inherit;
      cursor: pointer;
      font-size: 13px;
      display: inline-block;
      line-height: 1.333;

      &.has-error {
        color: $colorError;
      }

      @include media(md) {
        font-size: 13px;
      }
    }

    .input-form {
      margin: 0 0.5em 0 0;
      vertical-align: bottom;
      width: auto;
    }
  }

  &.mail {
    margin: 1em 0 0;
    
    label {
      color: #a9a9a9;
    }
  }

  &.check-agree {
     & > .input__wrapper {
        & > label {
          font-size: 12px;
        }
     }
  }

  &.chb {
    & > .input__wrapper {
      & > label {
        color: $black;
        display: block;
        width: 100%;
      }
    }
  }

  &.ta {
    & > .form__label {
      top: 10px;

      @include media(md) {
        top: 8px;
      }
    }
  }

  &.payments {
    & > .form__label {
      color: $black;
      font-size: 16px;
      font-weight: bold;
      position: static;
      margin: 0 0 10px;
    }
  }

  &:not(.chb) {

    .has-error {
      @include fontawesome('\f00d') {
        color: $colorError;
        font-size: 18px;
        left: auto;
        right: 8px;
        margin: 0;
        top: 11px;
      }
    }

    .has-success {
      @include fontawesome('\f00c') {
        color: $colorOk;
        font-size: 18px;
        left: auto;
        right: 8px;
        margin: 0;
        top: 12px;
      }
    }
  }

  .form__label {
    pointer-events: none;
    position: absolute;
    top: 9px;
    left: 16px;
    z-index: 1;

    &.animate {
      font-size: 10px;
      top: 2px;
    }
  }
  
  &.last {

    .input__wrapper {
      margin: 2em 0 0;
      text-align: center;
    }
    
    & > .form__label {
      font-size: 12px;
      margin: 6px 0 0;
      position: static;
      text-align: center;
    }
  }
}


.has-error {
  position: relative;

  & > label {
    color: $colorError;
  }

  & > .input-text,
  & > .input-textarea {
    border-color: $colorError;
  }
}


.has-success {
  position: relative;

  & > .input-text,
  & > .input-textarea {
    border-color: $colorOk;
  }
}


.help-block {
  font-size: 11px;
  position: absolute;
  top: 5px;
  right: 1em;
  pointer-events: none;

  &.form-error {
    color: $colorError;
  }
}




input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: $inputBg !important;
  background-image: none;
  color: inherit !important;
}


.ui-datepicker .ui-widget-header {
  background: $linkColor;
  color: $white;
}


.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important;
}

.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important;
}

.ui-datepicker td.ui-datepicker-week-end > a {
  color: $linkColor !important;
  background: none !important;
  border: 1px solid transparent !important;
}

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
}


.ui-datepicker td.ui-datepicker-today > a {
  color: $white !important;
  background: $linkColor !important;
  border: 1px solid $linkColor !important;
}


.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid $linkColor !important;
}


.ui-datepicker th.ui-datepicker-week-end > span {
  color: $linkColor !important;
  background: none !important;
  border: 1px solid transparent !important;
}


.ui-datepicker td.ui-datepicker-week-end > a:hover {
  color: $linkColor !important;
}


