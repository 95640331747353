@import "../media";
@import "../vars";



.col-2 {
  float: left;
  padding: 0 1em;
  width: 16.666%;
}


.col-3 {
  float: left;
  padding: 0 1em;
  width: 25%;
}

.col-4 {
  float: left;
  padding: 0 1em;
  width: 33.33%;
}

.col-6 {
  float: left;
  padding: 0 1em;
  width: 50%;
}

.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%;
}

.col-8 {
  float: left;
  padding: 0 1em;
  width: 66.6666%;
}


.col-9 {
  float: left;
  padding: 0 1em;
  width: 75%;
}

.col-10 {
  float: left;
  padding: 0 1em;
  width: 83.33333%;
}

.col-12 {
  padding: 0 1em;
  width: 100%;
}


.col-3-r {
  float: right;
  padding: 0 1em;
  width: 25%;
}

.col-4-r {
  float: right;
  padding: 0 1em;
  width: 33.333%;
}

.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%;
}


@include media(lg) {
  .col-2 {
    width: 25%;
  }

  .col-3 {
    width: 33.33333%;
  }
}


@include media(md) {
  .col-2 {
    width: 50%;
  }

  .col-3 {
    width: 50%;
  }

  .col-4 {
    width: 50%;
  }

  .col-6 {
    width: 100%;
  }

  .col-3-r {
    width: 50%;
  }

  .col-4-r {
    width: 50%;
  }

  .col-6-r {
    width: 100%;
  }
}


@include media(sm) {
  .col-3,
  .col-4,
  .col-6,
  .col-3-r,
  .col-4-r,
  .col-6-r {
    width: 100%;
  }
}
