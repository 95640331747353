@import "../vars";

.preloader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: $linkColor;
  animation: spin 2s linear infinite;
  z-index: 3;

  &:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: $linkColor--hover;
    animation: spin 1.5s linear infinite;
  }

  &:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: $colorError;
    animation: spin 3s linear infinite;
  }

}


.aniback-block {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -2em;
  right: -2em;
  z-index: 50;

  & > .bottom,
  & > .top {
    background: $white;
    position: absolute;
    left: 0;
    right: 0;
    height: 50%;
  }

  & > .top {
    top: 0;
  }

  & > .bottom {
    top: 50%;
  }
}



.loaded {
  .aniback-block {

    & > .top {
      transform: translateY(-100%);
      transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.5s;
      visibility: hidden;
    }

    & > .bottom {
      transform: translateY(100%);
      transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.6s;
      visibility: hidden;
    }
  }
}


@-webkit-keyframes spin {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
