@import "vars";

// Стили слайдера на главной
.slider-block {
	overflow: hidden;
	position: relative;
	height: 715px;
	width: 100%;

	.ya-map {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 400px !important;
	}

	@include media(1600px) {
		height: calc(900px + 30vw - 600px);
	}

	& > .container {
		position: absolute;
		top: 117px;
		bottom: 0;
		left: calc(((100% - #{$containerWidth}) / 2) + 1em);
		right: calc(((100% - #{$containerWidth}) / 2) + 1em);
		z-index: 3;

		@include media(xlg) {
			left: 1em;
			right: 1em;
		}

		@include media(md) {
			top: 95px;
		}

		@include media(672px) {
			left: 0;
			right: 0;
		}

		@include media(508px) {
			top: 77px;
		}

		& > .row {

			& > .col-4 {
				width: 368px;

				@include media(672px) {
					display: none;
				}
			}

			& > .col-8 {
				padding: 0 5% 0 3px;
				width: calc(100% - 368px);

				@include media(lg) {
					padding: 0;
				}

				@include media(672px) {
					padding: 0 1em;
					width: 100%;
				}
			}
		}
	}

	.slider,
	.slick-list,
	.slick-track {
		height: 100%;
	}

	.slick-slider {
		z-index: 2;
	}

	.slick-arrow {
		margin: 0;
	}
	
	.slick-prev {
		left: auto;
		right: calc(((100% - 1800px) / 2) + 1em);
		top: 30%;

		@include media(1800px) {
			right: 21px;
		}

		&:before {
			content: '\f106';
			font-size: 45px;
			line-height: 22px;
			margin: -14px 0 0 -15px;
		}
	}
	
	.slick-next {
		right: calc(((100% - 1800px) / 2) + 1em);
		bottom: 30%;
		top: auto;

		@include media(1800px) {
			right: 21px;
		}

		&:before {
			content: '\f107';
			font-size: 45px;
			line-height: 22px;
			margin: -11px 0 0 -15px;
		}
	}

	.slick-dots {
		bottom: auto;
		left: auto;
		right: calc(((100% - 1800px) / 2) + 8px);
		top: 50%;
		transform: translateY(-50%);
		width: 48px;

		@include media(1800px) {
			right: 1em;
		}

		& > li {
			display: block;
		}
	}
}


.slider-layer {
	padding: 11% 0 4%;

	& > h1.title {
		color: #fff;
		display: inline-block;
		font-size: 46px;
		line-height: 50px;
		text-transform: uppercase;

		@include media(lg) {
			font-size: 31px;
			line-height: 1.333;
		}

		@include media(md) {
			font-size: 26px;
		}

		@include media(sm) {
			font-size: 22px;
		}
	}

	&__calc {
		background: rgba(20, 22, 28, 0.7);
		margin: 20% 0 1em;
		padding: 1em 2em 1.5em;
	}

	&__check-status {
		background: rgba(20, 22, 28, 0.7);
		padding: 10px 1.5em 15px;

		@include media(lg) {
			display: none;
		}
	}

	&__check-status-title,
	&__calc-title {
		color: #496040;
		font-size: 20px;
	}

	@include media(sm) {
		padding: 1em 0;
	}
}


.slider-item {
	&__wrapper {
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}


	&.slick-active {

		.slider-item__label {
			display: block;
			transition: 0.5s ease-out;
		}
	}

	&__label-wrapper {
		position: absolute;
		max-width: 670px;
		top: 270px;
		left: calc((100% - 1200px) / 2);

		@include media(1600px) {
			left: 192px;
			top: 50%;
			transform: translateY(-50%);
		}

		@include media(943px) {
			max-width: 100%;
			padding: 0 5em 0 0;
		}

		@include media(md) {
			left: 163px;
		}

		@include media(sm) {
			left: 16px;
			top: 140px;
			transform: translateY(0);
		}

	}

	&__label-title {
		color: #fff;
		font-size: 72px;
		font-weight: bold;
		line-height: 80px;
		margin: 0 0 31px;

		@include media(1600px) {
			font-size: 52px;
			line-height: 55px;
		}

		@include media(943px) {
			font-size: 34px;
			line-height: 36px;
		}

		@include media(md) {
			font-size: 34px;
			line-height: 36px;
		}

		@include media(sm) {
			font-size: 26px;
			line-height: 25px;
			margin: 0 0 24px;
		}

		@include media(xs) {
			font-size: 22px;
			line-height: 23px;
			margin: 0 0 11px;
		}
	}

	&__label-text {
		color: #fff;
		font-size: 15px;

		@include media(slg) {
			font-size: 14px;
		}

		@include media(md) {
			font-size: 13px;
		}
	}
}
