@import "../vars";

.inner-block__header,
.side-block__header {
	padding: 1em 0;

	& > span {
		display: block;
		font-size: 32px;
		font-weight: bold;
		color: $black;
		text-transform: uppercase;
		text-align: center;
	}
}

.inner-block__inner,
.side-block__inner {
	margin: 1em 0 2em;

	.slick-slider {
		padding: 2em 0;
	}
}


.inner-block__wrapper,
.side-block__wrapper {
	@extend .clearfix;

	padding: 1em 0;
	position: relative;
}


.inner-block {
	padding: 0 0 2em;
	position: relative;
	z-index: 2;
}



