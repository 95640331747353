@import "vars";



.container {
  padding: 0 1em;
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;

  @extend .clearfix;
}


.row {
  margin: 0 -1em;
}


main {
  min-height: calc(100vh - 578px);
  overflow: hidden;
  width: 100%;
}


.inner-wrapper {
  position: relative;
}


.content-inner {
  padding: 40px 0;
  position: relative;
  z-index: 1;

  @include media(md) {
    padding: 24px 0;
  }
}

aside {
  float: right;
  padding: 40px 1em;
  width: 345px;

  @include media(md) {
    width: 100%;
  }
}


.content-block {
  &.account {
    float: right;
    padding: 0 1em;
    width: calc(100% - 345px);
  }
}


.no-index {

  main {
    padding: 121px 0 0;

    @include media(md) {
      padding: 62px 0 0;
    }
  }

  .content-block {
    float: left;
    padding: 0 1em;
    width: calc(100% - 345px);

    @include media(md) {
      width: 100%;
    }
  }
}

