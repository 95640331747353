@import "../vars";


.news-cat-list-block,
.news-full-block {
  background: #fff;
  padding: 40px 0;

  @include media(md) {
    padding: 1em 0;
  }
}


.news-cat-list-block,
.news-full-block {

  @include media(md) {
    .col-4,
    .col-8 {
      float: none;
      width: 100%;
    }
  }
}



.news-detail-gallery {
  margin: 30px 0 0;

  & > .row {
    margin: 0 -10px;
  }
}


.news-gallery-item {
  float: left;
  padding: 0 10px;
  margin: 0 0 20px;
  width: 25%;

  @include media(xmd) {
    width: 33.333333%;
  }

  @include media(sm) {
    width: 50%;
  }

  &__img {
    & > a {
      display: block;

      & > img {
        display: block;
        height: auto;
        max-width: 220px;
        width: 100%;
      }
    }
  }
}

.news-cat-list {
  padding: 0 1em 0 0;

  &__item {
    padding: 0 0 20px;
    margin: 0 0 40px;
  }

  &__img {
    margin: 0 0 20px;

    & > a {
      display: block;

      & > img {
        display: block;
        height: auto;
        max-width: 680px;
        width: 100%;
      }
    }
  }
  
  &__title {
    margin: 0 0 10px;

    & > a {
      color: #000;
      display: inline-block;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__info {
    margin: 0 0 10px;

    .icon-user {
      display: inline-block;
      margin: 0 8px 0 0;
      height: 14px;
      position: relative;
      vertical-align: middle;
      width: 14px;

      @include fontawesome('\f2c0') {
        color: #777;
        font-size: 12px;
        line-height: 1;
        margin: -6px 0 0 -5px;
      }
    }

    .icon-date {
      display: inline-block;
      margin: 0 8px 0 0;
      height: 14px;
      position: relative;
      vertical-align: middle;
      width: 14px;

      @include fontawesome('\f017') {
        color: #777;
        font-size: 12px;
        line-height: 1;
        margin: -6px 0 0 -5px;
      }
    }

    .icon-folder {
      display: inline-block;
      margin: 0 8px 0 0;
      height: 14px;
      position: relative;
      vertical-align: middle;
      width: 14px;

      @include fontawesome('\f114') {
        color: #777;
        font-size: 12px;
        line-height: 1;

        margin: -6px 0 0 -5px;
      }
    }
  }

  &__author,
  &__date {
    color: #777;
    font-size: 12px;
    margin: 0 20px 5px 0;

    & > span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__text {
    font-size: 13px;
    margin: 0 0 20px;
  }


  &__cat {

    & > a {
      color: #777;
      display: inline-block;
      font-size: 12px;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  &__bottom {
    & > .button {
      background: #0b7275;
      border-color: #0b7275;
      border-radius: 3px;
      color: #fff;
      font-size: 12px;
      height: 32px;
      line-height: 28px;
    }
  }
}


.no-mobile {
  .news-cat-list__bottom {
    & > .button {
      &:hover {
        background: #08a5a0;
        border-color: #08a5a0;
        color: #fff;
      }
    }
  }
}



.news-detail {
  padding: 0 1em 0 0;

  @include media(md) {
    padding: 0;
  }

  &__img {
    margin: 0 0 20px;

    & > img {
      display: block;
      height: auto;
      max-width: 680px;
      width: 100%;
    }
  }


  &__title {
    color: #000;
    font-size: 24px;
    margin: 0 0 10px;
  }

  &__header {
    margin: 0 0 20px;
  }

  &__category {
    padding: 0 0 0 21px;
    position: relative;

    @include fontawesome('\f114') {
      color: #777;
      font-size: 14px;
      line-height: 1;
      margin: 0;
      left: 0;
      top: 4px;
    }

    & > a {
      color: #777;
      text-transform: uppercase;
    }
  }
}


.pop-news-posts {
  margin: 0 0 30px;

  &__title {
    margin: 0 0 20px;

    & > span {
      color: #000;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}

.pop-news-item {
  border-top: 1px solid #eee;
  padding: 10px 0;

  &:last-child {
    border-bottom: 1px solid #eee;
  }

  &__img {
    float: left;
    width: 65px;

    & > a {
      display: block;

      & > img {
        display: block;
        height: auto;
        max-width: 100px;
        width: 100%;
      }
    }
  }

  &__info {
    float: right;
    padding: 0 0 0 15px;
    width: calc(100% - 65px);
  }

  &__title {
    & > a {
      color: #000;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      line-height: 21px;
    }
  }

  &__date {
    font-size: 12px;
    opacity: .8;
  }
}

.no-mobile {
  .pop-news-item__title {
    & > a {
      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
  }
}

.recent-posts {
  &__title {
    margin: 0 0 20px;

    & > span {
      color: #000;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}

.recent-news-item {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 6px 0;

  &__title {
    padding: 0 0 0 21px;
    position: relative;
    
    @include fontawesome('\f0f6') {
      color: #999;
      font-size: 13px;
      margin: 0;
      top: 3px;
      left: 0;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }

    & > a {
      color: #999;
      display: inline-block;
      line-height: 21px;
    }
  }
}

.no-mobile {
  .recent-news-item__title {
    & > a {
      &:hover {
        color: #999;
        text-decoration: underline;
      }
    }
  }
}

.related-news {
  padding: 30px 0 0;

  .devider {
    display: none;
    pointer-events: none;
    @extend .clearfix;

    @include media(xmd) {
      display: block;
    }
  }

  &__title {
    margin: 0 0 20px;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      border-bottom: solid 1px #eee;
      left: 0;
      right: 0;
      z-index: 0;
    }

    & > span {
      background: #fff;
      color: #000;
      display: inline-block;
      font-size: 15px;
      font-weight: bold;
      position: relative;
      z-index: 2;
    }
  }
}

.news-navigation {
  background: #fff;
  border-top: 1px solid #eee;
  padding: 20px 0;
  
  .next-news {
    text-align: right;

    & > a {
      color: #0b7275;
      display: inline-block;
      line-height: 21px;

      & > .next-icon {
        display: inline-block;
        position: relative;

        @include fontawesome('\f101') {
          color: #0b7275;
          font-size: 13px;
          position: static;
          margin: 0 0 0 5px;
        }
      }
    }
  }
  
  .prev-news {
    
    & > a {
      color: #0b7275;
      display: inline-block;
      line-height: 21px;

      & > .prev-icon {
        display: inline-block;
        position: relative;

        @include fontawesome('\f100') {
          color: #0b7275;
          font-size: 13px;
          position: static;
          margin: 0 5px 0 0;
        }
      }
    }
  }

  @include media(md) {
    .next-news {
      margin: 10px 0 0;
    }
  }
}

.no-mobile {
  .news-navigation {
    a:hover {
      color: #0b7275;
      text-decoration: underline;
    }
  }
}


.rel-news-item {
  float: left;
  padding: 0 10px;
  margin: 0 0 20px;
  width: 25%;

  @include media(xmd) {
    width: 50%;
  }

  @include media(sm) {
    width: 100%;
  }

  &__img {
    margin: 0 0 14px;

    & > a {
      display: block;

      & > img {
        display: block;
        height: auto;
        max-width: 300px;
        width: 100%;
      }
    }
  }

  &__title {
    margin: 0 0 5px;

    & > a {
      color: #000;
      display: inline-block;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 20px;
  }

  &__wrapper {
    & > .row {
      margin: 0 -10px;
    }
  }
}





