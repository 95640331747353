@charset "UTF-8";
/* Импорт sass модулей, по порядку */
/* Неиспользуемые модули надо закомментировать, используемые - раскомментировать */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/* Forms
   ========================================================================== */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Restore the font weight unset by the previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**************************************/
/*    Миксины для медиа запросов      */
/*    Использование:                  */
/*    @include media(lg/md/.../) {    */
/*         селектор {}                */
/*    }                               */
/*                                    */
/**************************************/
/**************************************/
/*    Брейкпоинты                     */
/**************************************/
/* Первые десктопы */
/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.col-2 {
  float: left;
  padding: 0 1em;
  width: 16.666%; }

.col-3 {
  float: left;
  padding: 0 1em;
  width: 25%; }

.col-4 {
  float: left;
  padding: 0 1em;
  width: 33.33%; }

.col-6 {
  float: left;
  padding: 0 1em;
  width: 50%; }

.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%; }

.col-8 {
  float: left;
  padding: 0 1em;
  width: 66.6666%; }

.col-9 {
  float: left;
  padding: 0 1em;
  width: 75%; }

.col-10 {
  float: left;
  padding: 0 1em;
  width: 83.33333%; }

.col-12 {
  padding: 0 1em;
  width: 100%; }

.col-3-r {
  float: right;
  padding: 0 1em;
  width: 25%; }

.col-4-r {
  float: right;
  padding: 0 1em;
  width: 33.333%; }

.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%; }

@media only screen and (max-width: 992px) {
  .col-2 {
    width: 25%; }
  .col-3 {
    width: 33.33333%; } }

@media only screen and (max-width: 768px) {
  .col-2 {
    width: 50%; }
  .col-3 {
    width: 50%; }
  .col-4 {
    width: 50%; }
  .col-6 {
    width: 100%; }
  .col-3-r {
    width: 50%; }
  .col-4-r {
    width: 50%; }
  .col-6-r {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .col-3,
  .col-4,
  .col-6,
  .col-3-r,
  .col-4-r,
  .col-6-r {
    width: 100%; } }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.preloader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #1a73e8;
  animation: spin 2s linear infinite;
  z-index: 3; }
  .preloader:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: #1175e9;
    animation: spin 1.5s linear infinite; }
  .preloader:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: rgba(255, 10, 20, 0.8);
    animation: spin 3s linear infinite; }

.aniback-block {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -2em;
  right: -2em;
  z-index: 50; }
  .aniback-block > .bottom,
  .aniback-block > .top {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 50%; }
  .aniback-block > .top {
    top: 0; }
  .aniback-block > .bottom {
    top: 50%; }

.loaded .aniback-block > .top {
  transform: translateY(-100%);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.5s;
  visibility: hidden; }

.loaded .aniback-block > .bottom {
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.6s;
  visibility: hidden; }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/**************************************/
/*    Миксины для медиа запросов      */
/*    Использование:                  */
/*    @include media(lg/md/.../) {    */
/*         селектор {}                */
/*    }                               */
/*                                    */
/**************************************/
/**************************************/
/*    Брейкпоинты                     */
/**************************************/
/* Первые десктопы */
/* Медиа миксины. Как использовать см. внутри */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  position: relative;
  min-height: 1px;
  outline: none;
  display: none; }
  .slick-slide > a {
    outline: none; }
  .slick-slide:focus {
    outline: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  background: none;
  border-radius: 50%;
  border: 1px solid #6dae76;
  cursor: pointer;
  display: block;
  height: 44px;
  line-height: 41px;
  padding: 10px;
  position: absolute;
  margin: -22px 0 0;
  outline: none;
  top: 40%;
  width: 44px;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .slick-arrow > span {
    display: block;
    height: 19px;
    width: 19px;
    position: relative; }
    .slick-arrow > span:before {
      background: #6dae76;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0; }

.slick-vertical .slick-arrow {
  left: 50%;
  margin: 0 0 0 -14px;
  right: auto; }

.slick-vertical .slick-prev {
  top: auto;
  bottom: -30px; }
  .slick-vertical .slick-prev:before {
    margin: -8px 0 0 -3px;
    transform: rotate(-90deg); }

.slick-vertical .slick-next {
  top: -30px; }
  .slick-vertical .slick-next:before {
    margin: -9px 0 0 -3px;
    transform: rotate(-90deg); }

.slick-prev {
  left: 0; }
  .slick-prev:before {
    background: #6dae76;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    left: 9px;
    top: 24px;
    transform: rotate(45deg);
    width: 9px; }
  .slick-prev:after {
    background: #6dae76;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    left: 9px;
    top: 18px;
    transform: rotate(-45deg);
    width: 9px; }

.slick-next {
  right: 0; }
  .slick-next:before {
    background: #6dae76;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    right: 10px;
    top: 24px;
    transform: rotate(-45deg);
    width: 9px; }
  .slick-next:after {
    background: #6dae76;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    right: 10px;
    top: 18px;
    transform: rotate(45deg);
    width: 9px; }

.slick-dots {
  display: block;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2; }
  .slick-dots > li {
    display: inline-block;
    height: auto;
    width: auto; }
    .slick-dots > li > button {
      background: none;
      border: none;
      border-radius: 50%;
      cursor: default;
      outline: none;
      position: relative;
      height: 48px;
      text-indent: -9999px;
      width: 48px; }
      .slick-dots > li > button:before {
        border-radius: 50%;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border: none;
        background: #d1d1d1;
        box-shadow: none;
        margin: -4px 0 0 -4px;
        height: 8px;
        width: 8px; }
    .slick-dots > li:not(.slick-active) > button {
      cursor: pointer; }
    .slick-dots > li.slick-active > button {
      -webkit-tap-highlight-color: transparent; }
      .slick-dots > li.slick-active > button:before {
        background: #1067d1;
        border: 4px solid #1067d1;
        margin: -8px 0 0 -8px; }

.no-mobile .slick-prev {
  left: -46px;
  transition: 0.2s ease-out; }
  .no-mobile .slick-prev:hover {
    background: #496040; }
    .no-mobile .slick-prev:hover:after, .no-mobile .slick-prev:hover:before {
      background: #fff; }
    .no-mobile .slick-prev:hover > span:before {
      background: #fff; }

.no-mobile .slick-next {
  right: -46px;
  transition: 0.2s ease-out; }
  .no-mobile .slick-next:hover {
    background: #496040; }
    .no-mobile .slick-next:hover:after, .no-mobile .slick-next:hover:before {
      background: #fff; }
    .no-mobile .slick-next:hover > span:before {
      background: #fff; }

.no-mobile .services-slider {
  overflow: hidden; }
  .no-mobile .services-slider:hover .slick-prev {
    left: 0; }
  .no-mobile .services-slider:hover .slick-next {
    right: 0; }

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("/assets/fonts/fa5/fa-regular-400.eot");
  src: url("/assets/fonts/fa5/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/fa5/fa-regular-400.woff2") format("woff2"), url("/assets/fonts/fa5/fa-regular-400.woff") format("woff"), url("/assets/fonts/fa5/fa-regular-400.ttf") format("truetype"), url("/assets/fonts/fa5/fa-regular-400.svg#fontawesome") format("svg"); }

@font-face {
  font-family: 'Roboto';
  font-display: auto;
  src: url("/assets/fonts/robotoregular/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/robotoregular/Roboto-Regular.woff") format("woff"), url("/assets/fonts/robotoregular/Roboto-Regular.ttf") format("truetype"), url("/assets/fonts/robotoregular/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: auto;
  src: url("/assets/fonts/robotolight/RobotoLight.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/robotolight/RobotoLight.woff") format("woff"), url("/assets/fonts/robotolight/RobotoLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: auto;
  src: url("/assets/fonts/robotomedium/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/robotomedium/Roboto-Medium.woff") format("woff"), url("/assets/fonts/robotomedium/Roboto-Medium.ttf") format("truetype"), url("/assets/fonts/robotomedium/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: auto;
  src: url("/assets/fonts/robotobold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("/assets//fonts/robotobold/RobotoBold.woff") format("woff"), url("/assets//fonts/robotobold/RobotoBold.ttf") format("truetype"), url("/assets//fonts/robotobold/RobotoBold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

/* подгрузка шрифтов */
/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.container {
  padding: 0 1em;
  margin: 0 auto;
  max-width: 1208px;
  width: 100%; }

.row {
  margin: 0 -1em; }

main {
  min-height: calc(100vh - 578px);
  overflow: hidden;
  width: 100%; }

.inner-wrapper {
  position: relative; }

.content-inner {
  padding: 40px 0;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 768px) {
    .content-inner {
      padding: 24px 0; } }

aside {
  float: right;
  padding: 40px 1em;
  width: 345px; }
  @media only screen and (max-width: 768px) {
    aside {
      width: 100%; } }

.content-block.account {
  float: right;
  padding: 0 1em;
  width: calc(100% - 345px); }

.no-index main {
  padding: 121px 0 0; }
  @media only screen and (max-width: 768px) {
    .no-index main {
      padding: 62px 0 0; } }

.no-index .content-block {
  float: left;
  padding: 0 1em;
  width: calc(100% - 345px); }
  @media only screen and (max-width: 768px) {
    .no-index .content-block {
      width: 100%; } }

/* Основной склелет */
/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

input::-ms-clear {
  display: none; }

body {
  background: #fff;
  font: normal 14px/1.8 "Arial", "Helvetica", sans-serif;
  color: #6e6e6e;
  opacity: 0;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none; }

.loaded body {
  overflow-x: hidden;
  overflow-y: scroll;
  height: auto;
  opacity: 1; }

table {
  border-collapse: collapse; }

ul {
  margin-top: 0;
  margin-bottom: 0; }

.content ol,
.content ul {
  padding: 0 0 1em 22px; }

h1 {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  padding: 0; }

h2 {
  color: #212429;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.333; }
  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 24px; } }
  @media only screen and (max-width: 480px) {
    h2 {
      font-size: 18px; } }

h3 {
  color: #212429;
  font-size: 24px;
  line-height: 1.333; }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 21px; } }
  @media only screen and (max-width: 480px) {
    h3 {
      font-size: 18px; } }

h2, h3, h4, h5, h6, p {
  padding: 0 0 1em; }

h4, h5, h6 {
  color: #212429; }

a {
  color: #1a73e8;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }

.no-mobile a {
  transition: color 0.3s ease-out; }

.no-mobile a:hover {
  color: #1175e9; }

.alert {
  color: red; }

.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center; }

.noscript_alert > span {
  font-weight: bold;
  color: red; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }
  fieldset > legend {
    color: #212429;
    font-weight: bold;
    font-size: 18px; }

.breadcrumbs {
  display: block;
  padding: 24px 0; }
  .breadcrumbs > .container > ol {
    display: block;
    list-style: none; }
    .breadcrumbs > .container > ol > li {
      display: inline-block;
      position: relative; }
      .breadcrumbs > .container > ol > li:not(:last-child) {
        padding: 0 2em 0 0; }
      .breadcrumbs > .container > ol > li > span {
        color: #6e6e6e;
        font-size: 14px; }
      .breadcrumbs > .container > ol > li > a > span {
        color: #6e6e6e;
        font-size: 14px;
        transition: 0.2s ease-out; }
        .breadcrumbs > .container > ol > li > a > span:after {
          content: '/';
          position: absolute;
          right: 1em;
          top: 0; }

.no-mobile .breadcrumbs > .container > ol > li > a:hover {
  text-decoration: none; }
  .no-mobile .breadcrumbs > .container > ol > li > a:hover > span {
    color: #1a73e8; }
    .no-mobile .breadcrumbs > .container > ol > li > a:hover > span:after {
      color: #6e6e6e; }

.content {
  position: relative; }
  .content img {
    height: auto;
    max-width: 800px; }
    @media only screen and (max-width: 992px) {
      .content img {
        max-width: 500px; } }
    @media only screen and (max-width: 768px) {
      .content img {
        max-width: 100%; } }
    @media only screen and (max-width: 630px) {
      .content img {
        max-width: 300px; } }
    @media only screen and (max-width: 400px) {
      .content img {
        max-width: 100%; } }

.button {
  border: 2px solid #496040;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  pointer-events: all;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .button > span {
    background: #496040;
    display: block;
    color: #fff;
    font-size: 15px;
    height: 38px;
    padding: 0 1em;
    line-height: 37px;
    transition: all 0.2s ease-out;
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap; }
  .button.disabled {
    cursor: default;
    pointer-events: none; }
    .button.disabled > span {
      background: #999; }
  .button.--trans > span {
    background: transparent;
    border-color: #fff; }
  .button.--wt > span {
    background: #fff;
    color: #496040; }

.no-mobile .button:hover:after {
  width: 100%; }

.no-mobile .button:hover:before {
  height: 100%; }

.no-mobile .button:hover > span:after {
  width: 100%; }

.no-mobile .button:hover > span:before {
  height: 100%; }

.cookie-warning {
  background: #fff;
  display: none; }
  .cookie-warning.show {
    display: block; }
  .cookie-warning__text {
    float: left;
    padding: 17px 0;
    width: calc(100% - 79px); }
  .cookie-warning__btn {
    float: right;
    padding: 8px 0;
    width: 79px; }
    .cookie-warning__btn > .button {
      display: block; }

.mobile .cookie-warning {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000; }

.mobile-calc {
  background: #14161c; }
  .mobile-calc .ya-map {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 0 !important; }
  .mobile-calc .slider-layer__calc {
    background: transparent;
    border-right: 1px solid #333;
    float: left;
    padding: 1em 1em 1.5em;
    margin: 0;
    width: 70%; }
    @media only screen and (max-width: 768px) {
      .mobile-calc .slider-layer__calc {
        border-right: none;
        border-bottom: 1px solid #333;
        width: 100%; } }
  .mobile-calc .slider-layer__check-status {
    background: transparent;
    float: right;
    width: 30%; }
    @media only screen and (max-width: 992px) {
      .mobile-calc .slider-layer__check-status {
        display: block; } }
    @media only screen and (max-width: 768px) {
      .mobile-calc .slider-layer__check-status {
        width: 100%; } }
  .mobile-calc .calc-form > .field {
    float: left; }
    .mobile-calc .calc-form > .field:nth-child(1), .mobile-calc .calc-form > .field:nth-child(3) {
      padding: 0 1em 0 0;
      width: 55%; }
      @media only screen and (max-width: 768px) {
        .mobile-calc .calc-form > .field:nth-child(1), .mobile-calc .calc-form > .field:nth-child(3) {
          padding: 0;
          width: 50%; } }
      @media only screen and (max-width: 583px) {
        .mobile-calc .calc-form > .field:nth-child(1), .mobile-calc .calc-form > .field:nth-child(3) {
          width: 100%; } }
    .mobile-calc .calc-form > .field:nth-child(2), .mobile-calc .calc-form > .field:nth-child(4) {
      padding: 0 0 0 1em;
      width: 45%; }
      @media only screen and (max-width: 768px) {
        .mobile-calc .calc-form > .field:nth-child(2), .mobile-calc .calc-form > .field:nth-child(4) {
          width: 50%; } }
      @media only screen and (max-width: 583px) {
        .mobile-calc .calc-form > .field:nth-child(2), .mobile-calc .calc-form > .field:nth-child(4) {
          padding: 0;
          width: 100%; } }
    .mobile-calc .calc-form > .field:nth-child(2) .form__label {
      left: 30px; }

.yashare_text {
  text-align: right; }

html.ie9 * {
  border-radius: 0 !important; }

body > .wrapper {
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  z-index: 1; }

.loaded body > .wrapper {
  height: auto;
  overflow: hidden;
  visibility: visible; }

.locked .overlay {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0, 0, 0, 1);
  visibility: visible; }

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  position: relative;
  vertical-align: middle; }

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 50; }

.loader {
  background: url("/assets/images/loader.svg") 0 0 no-repeat;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -34px 0 0 -34px;
  width: 68px;
  height: 68px;
  z-index: 200; }

.pagination {
  margin: 4em 0 3em;
  text-align: center; }

.pagination-title {
  color: #6e6e6e;
  cursor: default;
  display: inline-block;
  font-size: 13px;
  height: 26px;
  margin: 0 1em 0 0;
  vertical-align: middle; }

.pagination-wrapper {
  color: #6e6e6e;
  display: inline-block;
  font-size: 15px;
  vertical-align: middle; }
  .pagination-wrapper > span,
  .pagination-wrapper > a {
    display: block;
    float: left;
    height: 30px;
    line-height: 28px;
    margin: 0 6px 0 0;
    text-align: center;
    width: 32px; }
  .pagination-wrapper > .empty {
    background: #e7e7e8;
    border: none; }
  .pagination-wrapper > a:not(.show-all) {
    border-radius: 3px;
    background: #e7e7e8;
    color: #3d3d3d; }
  .pagination-wrapper > a.show-all {
    margin: 0;
    width: auto; }
  .pagination-wrapper > a.active {
    color: #6e6e6e;
    pointer-events: none;
    text-decoration: none; }
    .pagination-wrapper > a.active:not(.show-all) {
      border-radius: 3px;
      background: #4d4d4e;
      color: #fff;
      cursor: default; }
    .pagination-wrapper > a.active.show-all {
      cursor: default; }
  .pagination-wrapper > span {
    border: 1px solid #1a73e8;
    border-radius: 3px;
    background: #1a73e8;
    color: #3d3d3d;
    cursor: default; }

.no-mobile .pagination-wrapper > a:not(.show-all):hover {
  background: #1a73e8;
  color: #fff;
  transition: 0.3s ease-out; }

.hidden {
  display: none; }

.h1title,
.h2title,
.index .apark__title {
  color: #212429;
  font-size: 30px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 1em;
  padding: 0 0 1em;
  position: relative;
  text-transform: uppercase;
  text-align: center; }
  .h1title:after,
  .h2title:after,
  .index .apark__title:after {
    background: #496040;
    content: '';
    position: absolute;
    height: 2px;
    left: 50%;
    bottom: 21px;
    margin: 0 0 0 -19px;
    width: 39px; }
  @media only screen and (max-width: 1208px) {
    .h1title,
    .h2title,
    .index .apark__title {
      font-size: 28px; } }
  @media only screen and (max-width: 768px) {
    .h1title,
    .h2title,
    .index .apark__title {
      font-size: 24px; }
      .h1title:after,
      .h2title:after,
      .index .apark__title:after {
        bottom: 14px; } }
  @media only screen and (max-width: 480px) {
    .h1title,
    .h2title,
    .index .apark__title {
      font-size: 21px; }
      .h1title:after,
      .h2title:after,
      .index .apark__title:after {
        bottom: 8px; } }

input::-webkit-input-placeholder {
  color: #bbbbbb;
  font-size: 15px; }

input::-moz-placeholder {
  color: #bbbbbb;
  font-size: 15px; }

input:-moz-placeholder {
  color: #bbbbbb;
  font-size: 15px; }

input:-ms-input-placeholder {
  color: #bbbbbb;
  font-size: 15px; }

@media only screen and (max-width: 1440px) {
  input::-webkit-input-placeholder {
    font-size: 14px; }
  input::-moz-placeholder {
    font-size: 14px; }
  input:-moz-placeholder {
    font-size: 14px; }
  input:-ms-input-placeholder {
    font-size: 14px; } }

.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden; }
  .video-responsive iframe,
  .video-responsive object,
  .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.content-bottom {
  margin: 2em 0 0; }

.red__star,
.red-star {
  color: rgba(255, 10, 20, 0.8);
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px; }

.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 6px 0 0; }
  .radio + label {
    cursor: pointer; }
  .radio:not(checked) {
    position: absolute;
    visibility: hidden; }
    .radio:not(checked) + label {
      position: relative;
      padding: 0 0 0 35px; }
      .radio:not(checked) + label:before {
        border-radius: 50%;
        background: #e6e6e6;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px; }
      .radio:not(checked) + label:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 4px;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        border-radius: 50%;
        background: #688557;
        opacity: 0;
        transition: background .2s ease; }
  .radio:checked + label:after {
    opacity: 1; }

.checkbox + label {
  cursor: pointer;
  vertical-align: middle;
  user-select: none; }

.checkbox:focus + label:before {
  box-shadow: 0 0 3px 0 #496040; }

.checkbox:not(checked) {
  position: absolute;
  opacity: 0; }
  .checkbox:not(checked) + label {
    position: relative;
    padding: 2px 0 0 29px; }
    .checkbox:not(checked) + label:before {
      background: #e6e6e6;
      border-radius: 3px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px; }
    .checkbox:not(checked) + label:after {
      content: '\f00c';
      color: #688557;
      font: normal 15px/1 FontAwesome;
      position: absolute;
      top: 2px;
      left: 3px;
      opacity: 0;
      visibility: hidden; }

.checkbox:checked + label:after {
  opacity: 1;
  transition: opacity 0.2s ease;
  visibility: visible; }

.select-wrapper {
  position: relative; }
  .select-wrapper:before {
    content: '\f0d7';
    font: normal 12px/1 FontAwesome;
    color: #000;
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -6px; }
  .select-wrapper > select {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    color: #666;
    cursor: pointer;
    display: block;
    font-size: 13px;
    height: 35px;
    outline: none;
    padding: 6px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%; }

.button-up {
  background: #496040;
  border-radius: 50%;
  cursor: pointer;
  padding: 13px 19px;
  position: fixed;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  height: 62px;
  width: 62px;
  text-align: center;
  transition: 0.2s ease-out;
  visibility: hidden;
  user-select: none;
  z-index: 1000;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .button-up.active {
    opacity: 1;
    visibility: visible; }
  @media only screen and (max-width: 1208px) {
    .button-up {
      padding: 10px 13px;
      height: 42px;
      width: 42px; } }
  .button-up > svg {
    display: block;
    height: auto;
    max-width: 24px;
    width: 100%; }

/*
.no-mobile {
  .button-up {
    &:hover {
    }
  }
}
*/
.social-repost {
  text-align: right; }

.payment-methods {
  margin: 0 0 2em; }
  .payment-methods__item {
    float: left;
    padding: 6em 1em 0 1em;
    position: relative;
    text-align: center;
    width: 33.333%; }
    .payment-methods__item:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -3em;
      height: 5em;
      width: 6em; }
    .payment-methods__item > span {
      display: inline-block;
      line-height: 1.2; }
    .payment-methods__item.bank:before {
      background: url(/assets/images/banktransfer.svg) 0 0 no-repeat;
      background-size: contain; }
    .payment-methods__item.card:before {
      background: url(/assets/images/creditcard.svg) 0 0 no-repeat;
      background-size: contain; }
    .payment-methods__item.nalich:before {
      background: url(/assets/images/nalik.svg) 0 0 no-repeat;
      background-size: contain; }
    @media only screen and (max-width: 480px) {
      .payment-methods__item {
        width: 100%; } }

.error-page {
  margin: 0 0 5em; }
  .error-page-pic {
    text-align: center; }
  .error-page-desc {
    margin: 3em auto 0;
    max-width: 615px; }
    .error-page-desc > ol {
      margin: 0 0 2em; }
  .error-page-desc__title {
    font-size: 24px;
    color: #111;
    line-height: 1.1;
    margin: 0 0 14px; }

.connect-block .feedback-form {
  padding: 0 0 40px; }
  .connect-block .feedback-form .checkbox:not(checked) + label:before {
    background: #fff; }
  .connect-block .feedback-form .input-form {
    background: #fafafa; }
  .connect-block .feedback-form .col-6 {
    width: 50%; }
    @media only screen and (max-width: 480px) {
      .connect-block .feedback-form .col-6 {
        width: 100%; } }
  .connect-block .feedback-form .field.check-agree > .input__wrapper > label {
    color: #fff; }
    .connect-block .feedback-form .field.check-agree > .input__wrapper > label > a {
      color: #fff; }

.search-block {
  background: #fff;
  padding: 40px 0; }
  .search-block__item {
    border-bottom: 1px solid #eee;
    margin: 0 0 20px;
    padding: 0 0 20px; }
  .search-block__img {
    float: left;
    padding: 0 25px 0 0;
    width: 125px; }
    .search-block__img > a {
      display: block; }
      .search-block__img > a > img {
        display: block;
        height: auto;
        max-width: 150px;
        width: 100%; }
  .search-block__wrapper {
    float: right;
    width: calc(100% - 125px); }
  .search-block__title {
    margin: 0 0 10px; }
    .search-block__title > a {
      color: #000;
      display: inline-block;
      font-size: 17px;
      font-weight: 600;
      line-height: 25px; }

.no-mobile .search-block__title > a:hover {
  text-decoration: none; }

.listview-item {
  float: left;
  padding: 0 1em;
  width: 50%; }

.services {
  padding: 60px 0; }

.services-item {
  float: left;
  position: relative;
  width: 25%; }
  @media only screen and (max-width: 1208px) {
    .services-item {
      width: 33.333333%; } }
  @media only screen and (max-width: 860px) {
    .services-item {
      width: 50%; } }
  @media only screen and (max-width: 480px) {
    .services-item {
      width: 100%; } }
  .services-item > img {
    border: 0.5px solid #fff;
    display: block;
    height: auto;
    max-width: 480px;
    width: 100%; }
  .services-item__wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 2; }
  .services-item__title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-out; }
    @media only screen and (max-width: 768px) {
      .services-item__title {
        font-size: 18px; } }

/*
.no-mobile {
  .services-item {

    &__btn{
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: height 0.3s ease-out, opacity 0.2s ease-out 0s;
      visibility: hidden;
    }

    & > img {
      transition: 0.3s ease-out;
    }

    &:hover {

      .services-item__title {
        margin: 0 0 1em;
      }

      .services-item__btn {
        opacity: 1;
        height: 64px;
        transition: height 0.3s ease-out, opacity 0.2s ease-out 0s;
        visibility: visible;
      }

      & > img {
        filter: brightness(60%);
      }
    }
  }
}

*/
.services-addit-block {
  background: url(/assets/images/serv-addit.jpg) no-repeat;
  background-size: cover;
  padding: 60px 0;
  position: relative;
  z-index: 2; }
  .services-addit-block .h2title, .services-addit-block .index .apark__title, .index .services-addit-block .apark__title {
    color: #fff; }

.services-addit {
  padding: 30px 0; }
  .services-addit.cols-5 > .services-addit__item {
    width: 20%; }
    @media only screen and (max-width: 630px) {
      .services-addit.cols-5 > .services-addit__item {
        margin: 0 0 2em;
        width: 33.333%; }
        .services-addit.cols-5 > .services-addit__item:nth-child(4n + 4) {
          margin: 0 0 0 16.665%; } }
  .services-addit__item {
    float: left;
    padding: 0 1em; }
    .services-addit__item > a {
      display: block;
      text-align: center; }
  .services-addit__icon {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    padding: 34px;
    margin: 0 0 1em;
    height: 148px;
    width: 148px; }
    .services-addit__icon > svg {
      display: block;
      height: auto;
      max-width: 80px;
      width: 100%; }
      .services-addit__icon > svg > path {
        fill: #111111; }
    @media only screen and (max-width: 1440px) {
      .services-addit__icon {
        padding: 31px;
        height: 120px;
        width: 120px; } }
    @media only screen and (max-width: 1208px) {
      .services-addit__icon {
        padding: 22px;
        height: 90px;
        width: 90px; } }
    @media only screen and (max-width: 768px) {
      .services-addit__icon {
        padding: 16px;
        height: 70px;
        width: 70px; } }
  .services-addit__title {
    color: #fff;
    display: block;
    font-size: 22px;
    line-height: 1.2;
    letter-spacing: 0.333px;
    min-height: 45px;
    padding: 0 1em; }
    @media only screen and (max-width: 1440px) {
      .services-addit__title {
        font-size: 18px; } }
    @media only screen and (max-width: 1208px) {
      .services-addit__title {
        font-size: 16px; } }
    @media only screen and (max-width: 992px) {
      .services-addit__title {
        font-size: 15px; } }
    @media only screen and (max-width: 860px) {
      .services-addit__title {
        font-size: 14px; } }
    @media only screen and (max-width: 768px) {
      .services-addit__title {
        font-size: 13px;
        padding: 0; } }
    @media only screen and (max-width: 480px) {
      .services-addit__title {
        font-size: 12px; } }

.no-mobile .services-addit__icon {
  transition: 0.3s ease-out; }
  .no-mobile .services-addit__icon > svg > path {
    transition: 0.3s ease-out; }

.no-mobile .services-addit__item > a:hover > .services-addit__icon {
  background: #496040; }
  .no-mobile .services-addit__item > a:hover > .services-addit__icon > svg > path {
    fill: #fff; }

.video-presentation {
  margin: 2em auto 4em; }
  .video-presentation > .video-responsive {
    padding-bottom: 56%; }

.connect-block {
  background: url(/assets/images/connect.jpg) no-repeat;
  background-size: cover;
  padding: 60px 0 0; }
  .connect-block > .container {
    max-width: 731px; }
  .connect-block .h2title, .connect-block .index .apark__title, .index .connect-block .apark__title {
    color: #fff;
    margin: 0 0 1em; }
    .connect-block .h2title:after, .connect-block .index .apark__title:after, .index .connect-block .apark__title:after {
      background: #fff; }
    @media only screen and (max-width: 768px) {
      .connect-block .h2title, .connect-block .index .apark__title, .index .connect-block .apark__title {
        margin: 0 0 1em; } }
    @media only screen and (max-width: 480px) {
      .connect-block .h2title, .connect-block .index .apark__title, .index .connect-block .apark__title {
        margin: 0 0 1em; } }
  .connect-block__desc {
    margin: 0 auto;
    max-width: 400px;
    text-align: center; }

.contact-block {
  position: relative;
  margin: 90px 0; }
  .contact-block > img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%; }
  .contact-block__wrapper {
    background: rgba(10, 75, 155, 0.79);
    left: 50%;
    padding: 26px 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px; }
    @media only screen and (max-width: 630px) {
      .contact-block__wrapper {
        transform: translateX(-50%) translateY(-50%); } }
    .contact-block__wrapper > .h2title, .index .contact-block__wrapper > .apark__title {
      color: #fff;
      padding: 0 0 24px;
      text-align: left; }
  .contact-block__adress > span {
    color: #fff;
    display: block;
    line-height: 47px; }
    .contact-block__adress > span > a {
      color: #fff;
      display: inline-block; }

.no-mobile .contact-block__adress > span > a:hover {
  color: #fff; }

.online-services {
  padding: 0 10% 0 0; }
  .online-services__wrapper {
    margin: 10% 0 0; }
    .online-services__wrapper a {
      display: block;
      padding: 0 1em 0 0; }
      .online-services__wrapper a > span {
        color: #fff;
        display: block;
        font-size: 20px;
        line-height: 1.1; }
        @media only screen and (max-width: 1208px) {
          .online-services__wrapper a > span {
            font-size: 18px; } }
        @media only screen and (max-width: 992px) {
          .online-services__wrapper a > span {
            font-size: 16px; } }
        @media only screen and (max-width: 768px) {
          .online-services__wrapper a > span {
            font-size: 15px; } }
        @media only screen and (max-width: 630px) {
          .online-services__wrapper a > span {
            font-size: 13px; } }
    .online-services__wrapper .col-4 {
      width: 33.333%; }
    @media only screen and (max-width: 768px) {
      .online-services__wrapper {
        margin: 5% 0 0; } }
  .online-services__title > a,
  .online-services__title > span {
    background: #496040;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 0.333px;
    padding: 7px 1em 6px;
    text-align: center;
    text-transform: uppercase;
    width: 274px; }
  .online-services__icon {
    height: 56px;
    margin: 0 0 11px;
    width: 56px; }
    .online-services__icon > svg {
      display: block;
      height: auto;
      max-width: 56px;
      width: 100%; }
      .online-services__icon > svg > path {
        fill: #ffffff; }
    @media only screen and (max-width: 992px) {
      .online-services__icon {
        height: 40px;
        margin: 0 0 9px;
        width: 40px; } }
  @media only screen and (max-width: 768px) {
    .online-services {
      padding: 0; } }

.no-mobile .online-services__wrapper a > span {
  transition: 0.3s ease-out; }
  .no-mobile .online-services__wrapper a > span > svg > path {
    transition: 0.3s ease-out; }

.no-mobile .online-services__wrapper a:hover > span {
  color: #496040; }
  .no-mobile .online-services__wrapper a:hover > span > svg > path {
    fill: #496040; }

.no-mobile .online-services__title > a:hover {
  background: #fff;
  color: #496040; }

.transportation-page-slider-block {
  margin: 0 0 2em; }

.transportation-page-slider-block__title,
.services-page-slider-block__title {
  color: #212429;
  font-size: 22px;
  text-transform: uppercase; }

.services-slider {
  padding: 0 40px; }

.transportation-page-slider,
.services-page-slider {
  padding: 2em 40px; }
  .transportation-page-slider .slick-prev,
  .services-page-slider .slick-prev {
    left: 0; }
  .transportation-page-slider .slick-next,
  .services-page-slider .slick-next {
    right: 0; }

.services-details {
  position: relative;
  opacity: 1;
  transition: 0.2s ease-out;
  visibility: visible; }
  .services-details.hide > .service-item {
    opacity: 0;
    visibility: hidden; }
  .services-details.hide > .loader {
    visibility: visible; }
  .services-details > .loader {
    position: absolute;
    visibility: hidden; }

.service-item {
  padding: 30px 0 50px 160px;
  position: relative;
  opacity: 1;
  transition: 0.2s ease-out;
  visibility: visible; }
  @media only screen and (max-width: 768px) {
    .service-item {
      padding: 30px 0 50px; } }
  .service-item.--right {
    padding: 30px 160px 50px 0; }
    @media only screen and (max-width: 768px) {
      .service-item.--right {
        padding: 30px 0 50px; } }
    .service-item.--right > .service-item__info {
      left: auto;
      right: 0;
      text-align: right; }
      @media only screen and (max-width: 768px) {
        .service-item.--right > .service-item__info {
          text-align: left; } }
    .service-item.--right .service-item__desc {
      padding: 0 0 0 11%; }
      @media only screen and (max-width: 768px) {
        .service-item.--right .service-item__desc {
          padding: 0 11% 0 0; } }
  .service-item__info {
    background: #496040;
    padding: 4em;
    position: absolute;
    top: 0;
    left: 0;
    width: 480px;
    z-index: 2; }
    @media only screen and (max-width: 992px) {
      .service-item__info {
        padding: 3em;
        width: 392px; } }
    @media only screen and (max-width: 768px) {
      .service-item__info {
        padding: 2em 1em;
        position: static;
        width: 100%; } }
  .service-item__title {
    margin: 0 0 1em; }
    .service-item__title > span {
      color: #fff;
      display: inline-block;
      font-size: 34px;
      font-weight: bold;
      line-height: 1.3;
      text-transform: uppercase; }
      @media only screen and (max-width: 992px) {
        .service-item__title > span {
          font-size: 26px; } }
  .service-item__img > img {
    display: block;
    height: auto;
    max-width: 1020px;
    width: 100%; }
  .service-item__desc {
    color: #fff;
    font-size: 17px;
    line-height: 1.3;
    padding: 0 11% 0 0; }
    @media only screen and (max-width: 992px) {
      .service-item__desc {
        font-size: 15px; } }
  .service-item__btn {
    margin: 3em 0 0; }
    .service-item__btn > .button {
      display: inline-block;
      padding: 0;
      max-width: 280px;
      width: 100%; }
      @media only screen and (max-width: 992px) {
        .service-item__btn > .button {
          max-width: 230px; } }
  .service-item:after {
    background: rgba(73, 96, 64, 0.79);
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    right: -36px;
    bottom: 0;
    height: 80px;
    width: 80px;
    z-index: 2; }

.services-slider-item {
  padding: 0 1em; }
  .services-slider-item__wrapper {
    cursor: pointer;
    margin: 0 auto;
    max-width: 176px;
    text-align: center;
    /*&.active {
      .icon {
        background: $haki;

        & > svg {
          & > path {
            fill: #fff;
          }
        }
      }
    }*/ }
    .services-slider-item__wrapper > a {
      display: block; }
      .services-slider-item__wrapper > a > .icon {
        background: #efefef;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px; }
        .services-slider-item__wrapper > a > .icon > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%; }
          .services-slider-item__wrapper > a > .icon > svg > path {
            fill: #6e6e6e; }
    .services-slider-item__wrapper:first-child {
      margin: 0 auto 1em; }
  .services-slider-item__title {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
    min-height: 61px;
    padding: 0 13%; }

.no-mobile .services-slider-item__wrapper > a > .services-slider-item__title {
  transition: 0.3s ease-out; }

.no-mobile .services-slider-item__wrapper > a > .icon {
  transition: 0.3s ease-out; }
  .no-mobile .services-slider-item__wrapper > a > .icon > svg > path {
    transition: 0.3s ease-out; }

.no-mobile .services-slider-item__wrapper:hover > a > .icon {
  background: #496040; }
  .no-mobile .services-slider-item__wrapper:hover > a > .icon > svg > path {
    fill: #fff; }

.no-mobile .services-slider-item__wrapper:hover > a > .services-slider-item__title {
  color: #496040; }

.services-page-slider-item {
  padding: 0 1em; }
  .services-page-slider-item__wrapper {
    cursor: pointer;
    margin: 0 auto;
    max-width: 176px;
    text-align: center; }
    .services-page-slider-item__wrapper > span {
      display: block; }
      .services-page-slider-item__wrapper > span > .icon {
        background: #496040;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px; }
        .services-page-slider-item__wrapper > span > .icon > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%; }
          .services-page-slider-item__wrapper > span > .icon > svg > path {
            fill: #fff; }
    .services-page-slider-item__wrapper:first-child {
      margin: 0 auto 1em; }
    .services-page-slider-item__wrapper > a {
      display: block; }
      .services-page-slider-item__wrapper > a > .icon {
        background: #efefef;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px; }
        .services-page-slider-item__wrapper > a > .icon > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%; }
          .services-page-slider-item__wrapper > a > .icon > svg > path {
            fill: #6e6e6e; }
  .services-page-slider-item__title {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
    min-height: 61px;
    padding: 0 13%; }

.no-mobile .services-page-slider-item__wrapper > .services-page-slider-item__title {
  transition: 0.3s ease-out; }

.no-mobile .services-page-slider-item__wrapper > .icon {
  transition: 0.3s ease-out; }
  .no-mobile .services-page-slider-item__wrapper > .icon > svg > path {
    transition: 0.3s ease-out; }

.no-mobile .services-page-slider-item__wrapper:hover > .icon {
  background: #496040; }
  .no-mobile .services-page-slider-item__wrapper:hover > .icon > svg > path {
    fill: #fff; }

.no-mobile .services-page-slider-item__wrapper:hover > .services-page-slider-item__title {
  color: #496040; }

.transportation-page-slider-item {
  padding: 0 1em; }
  .transportation-page-slider-item__wrapper {
    cursor: pointer;
    margin: 0 auto;
    max-width: 176px;
    text-align: center; }
    .transportation-page-slider-item__wrapper > span {
      display: block; }
      .transportation-page-slider-item__wrapper > span > .icon {
        background: #496040;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px; }
        .transportation-page-slider-item__wrapper > span > .icon > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%; }
          .transportation-page-slider-item__wrapper > span > .icon > svg > path {
            fill: #fff; }
    .transportation-page-slider-item__wrapper:first-child {
      margin: 0 auto 1em; }
    .transportation-page-slider-item__wrapper > a {
      display: block; }
      .transportation-page-slider-item__wrapper > a > .icon {
        background: #efefef;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px; }
        .transportation-page-slider-item__wrapper > a > .icon > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%; }
          .transportation-page-slider-item__wrapper > a > .icon > svg > path {
            fill: #6e6e6e; }
  .transportation-page-slider-item__title {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
    min-height: 61px;
    padding: 0 13%; }

.no-mobile .transportation-page-slider-item__wrapper > .transportation-page-slider-item__title {
  transition: 0.3s ease-out; }

.no-mobile .transportation-page-slider-item__wrapper > .icon {
  transition: 0.3s ease-out; }
  .no-mobile .transportation-page-slider-item__wrapper > .icon > svg > path {
    transition: 0.3s ease-out; }

.no-mobile .transportation-page-slider-item__wrapper:hover > .icon {
  background: #496040; }
  .no-mobile .transportation-page-slider-item__wrapper:hover > .icon > svg > path {
    fill: #fff; }

.no-mobile .transportation-page-slider-item__wrapper:hover > .services-page-slider-item__title,
.no-mobile .transportation-page-slider-item__wrapper:hover > .services-slider-item__title {
  color: #496040; }

.white-angle-bg {
  background: #fff url(/assets/images/wt-angle-bg.jpg) top left no-repeat;
  background-size: contain; }

.directions-block {
  padding: 60px 0; }
  .directions-block__map {
    float: left;
    padding: 5em 1em 0;
    width: calc(100% - 335px); }
    .directions-block__map > object {
      display: block;
      height: auto;
      max-width: 1200px;
      width: 100%; }
    @media only screen and (max-width: 992px) {
      .directions-block__map {
        padding: 3em 1em 0;
        width: 100%; } }
    @media only screen and (max-width: 768px) {
      .directions-block__map {
        padding: 2em 1em 0; } }
  .directions-block__info {
    float: right;
    padding: 8em 1em 0;
    opacity: 0;
    transition: 0.2s ease-out;
    visibility: hidden;
    width: 335px; }
    .directions-block__info.active {
      opacity: 1;
      visibility: visible; }
    @media only screen and (max-width: 992px) {
      .directions-block__info {
        padding: 2em 1em 0;
        width: 100%; } }

.directions-item__title {
  color: #496040;
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 14px; }
  @media only screen and (max-width: 992px) {
    .directions-item__title {
      font-size: 21px; } }
  @media only screen and (max-width: 768px) {
    .directions-item__title {
      font-size: 18px; } }

.directions-item__text {
  color: #6e6e6e;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 35px; }
  @media only screen and (max-width: 992px) {
    .directions-item__text {
      font-size: 15px;
      margin: 0 0 30px; } }
  @media only screen and (max-width: 768px) {
    .directions-item__text {
      font-size: 14px;
      margin: 0 0 25px; } }

.directions-item__btn > .button {
  max-width: 280px;
  width: 100%; }

.no-mobile .directions-block__map > svg > path:hover {
  fill: #333; }

.calc-form .button {
  width: 100%; }

.calc-form .info-wrapper > .col-6 {
  padding: 0;
  width: 50%; }
  .calc-form .info-wrapper > .col-6:last-child {
    text-align: right; }
  .calc-form .info-wrapper > .col-6 > .icon {
    margin: 0 10px 0 0;
    height: 22px;
    width: 22px; }
    .calc-form .info-wrapper > .col-6 > .icon > svg {
      display: block;
      height: auto;
      max-width: 22px;
      width: 100%; }
  .calc-form .info-wrapper > .col-6 > span {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle; }
    .calc-form .info-wrapper > .col-6 > span > i {
      color: #5d7a52;
      display: inline-block;
      font-size: 19px;
      font-style: normal;
      padding: 0 2px;
      vertical-align: middle; }

.calc-form .field .form__label.animate {
  top: 2px; }

.calc-form .input-text {
  padding: 10px 1em 0; }

.check-status-form .field {
  float: left;
  padding: 0 1em 0 0;
  width: calc(100% - 50px); }
  .check-status-form .field.btn {
    padding: 0;
    width: 50px; }

.transport-type-block-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 1; }

.transport-type-block__more {
  background: #fff;
  padding: 3em 0;
  position: relative;
  margin: -5px 0 4em;
  text-align: center;
  z-index: 2; }
  .transport-type-block__more > .button {
    max-width: 280px;
    width: 100%; }

.transport-type {
  margin: 4em 0 0;
  overflow: hidden;
  height: 484px;
  transition: 0.2s ease-out; }
  .transport-type.open {
    height: 100%; }
  @media only screen and (max-width: 630px) {
    .transport-type {
      height: 465px; } }
  @media only screen and (max-width: 414px) {
    .transport-type {
      height: 496px; } }
  @media only screen and (max-width: 350px) {
    .transport-type {
      height: 530px; } }

.transport-type-item {
  border-right: 1px solid #e4e0dc;
  border-bottom: 1px solid #e4e0dc;
  float: left;
  padding: 10px 3% 2em;
  min-height: 243px;
  width: 25%; }
  .transport-type-item:nth-child(4n + 4) {
    border-right: none; }
  @media only screen and (max-width: 992px) {
    .transport-type-item {
      width: 33.333%; }
      .transport-type-item:nth-child(4n + 4) {
        border-right: 1px solid #e4e0dc; }
      .transport-type-item:nth-child(3n + 3) {
        border-right: none; } }
  @media only screen and (max-width: 630px) {
    .transport-type-item {
      min-height: 232px;
      padding: 10px 3% 1em;
      width: 50%; }
      .transport-type-item:nth-child(3n + 3) {
        border-right: 1px solid #e4e0dc; }
      .transport-type-item:nth-child(2n + 2) {
        border-right: none; } }
  @media only screen and (max-width: 414px) {
    .transport-type-item {
      min-height: 247px; } }
  @media only screen and (max-width: 350px) {
    .transport-type-item {
      min-height: 264px; } }
  .transport-type-item > a {
    display: block; }
  .transport-type-item__icon {
    display: block;
    margin: 0 0 10px; }
    .transport-type-item__icon > svg {
      display: block;
      height: auto;
      max-width: 44px;
      width: 100%; }
  .transport-type-item__title {
    color: #212429;
    display: block;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.333;
    margin: 0 0 6px;
    min-height: 50px; }
    @media only screen and (max-width: 768px) {
      .transport-type-item__title {
        font-size: 16px; } }
  .transport-type-item__text {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.333; }
    @media only screen and (max-width: 768px) {
      .transport-type-item__text {
        font-size: 13px; } }

.aside-services-block {
  margin: 0 0 4em; }

.aside-services-item {
  background: #efefef;
  border-radius: 5px;
  display: table;
  margin: 0 0 5px;
  width: 100%; }
  .aside-services-item > .order-item,
  .aside-services-item > .review-item {
    cursor: pointer; }
  .aside-services-item.active {
    background: #496040; }
    .aside-services-item.active .aside-services-item__title {
      color: #fff; }
    .aside-services-item.active .aside-services-item__icon {
      background: #fff; }
      .aside-services-item.active .aside-services-item__icon > svg > path {
        fill: #496040; }
  .aside-services-item > span,
  .aside-services-item > a {
    display: block;
    padding: 35px 40px;
    position: relative;
    min-height: 152px; }
  .aside-services-item__icon {
    background: #496040;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 35px;
    left: 40px;
    height: 82px;
    padding: 18px;
    width: 82px; }
    .aside-services-item__icon > svg {
      display: block;
      height: auto;
      max-width: 45px;
      width: 100%; }
  .aside-services-item__title {
    color: #6e6e6e;
    display: block;
    font-size: 15px;
    padding: 19px 10px 0 114px;
    line-height: 1.2;
    transition: 0.2s ease-out;
    user-select: none; }

.no-mobile .aside-services-item {
  transition: background-color 0.2s ease-out; }
  .no-mobile .aside-services-item__icon {
    transition: background-color 0.2s ease-out; }
    .no-mobile .aside-services-item__icon > svg > path {
      transition: fill 0.2s ease-out; }
  .no-mobile .aside-services-item:hover {
    background: #496040; }
    .no-mobile .aside-services-item:hover .aside-services-item__title {
      color: #fff; }
    .no-mobile .aside-services-item:hover .aside-services-item__icon {
      background: #fff; }
      .no-mobile .aside-services-item:hover .aside-services-item__icon > svg > path {
        fill: #496040; }

.aside-services-all {
  margin: 13px 0 0; }
  .aside-services-all > .button {
    width: 100%; }

.r-block {
  margin: 0 0 2em; }
  .r-block__title {
    color: #212429;
    font-size: 23px;
    margin: 0 0 5px;
    text-transform: uppercase; }
  .r-block__item {
    background: #efefef;
    border-radius: 3px;
    margin: 3px 0; }
    .r-block__item > span {
      color: #496040;
      cursor: default;
      display: block;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap; }
    .r-block__item > a {
      color: #6e6e6e;
      display: block;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap; }

.online-services-page__item {
  float: left;
  padding: 0 1em;
  margin: 0 0 2em;
  width: 33.33333%; }
  .online-services-page__item > span {
    cursor: pointer; }
  .online-services-page__item > span,
  .online-services-page__item > a {
    display: block; }

.online-services-page__title {
  color: #6e6e6e;
  display: block;
  line-height: 1.333;
  text-align: center; }

.online-services-page__icon {
  display: block;
  padding: 0 1em 1em;
  text-align: center; }
  .online-services-page__icon > svg {
    display: inline-block;
    height: auto;
    max-width: 100px;
    width: 100%; }
    .online-services-page__icon > svg > path {
      fill: #000000 !important; }

.bottom-slider {
  padding: 0 3em;
  margin: 3em 0 0; }
  .bottom-slider .slick-arrow {
    border-color: #3b603e; }
    .bottom-slider .slick-arrow > span:before {
      background: #3b603e; }
    .bottom-slider .slick-arrow:after, .bottom-slider .slick-arrow:before {
      background: #3b603e; }

.no-mobile .bottom-slider .slick-next {
  right: 0; }

.no-mobile .bottom-slider .slick-prev {
  left: 0; }

.bottom-slider-block__title {
  color: #212429;
  font-size: 22px;
  text-transform: uppercase; }

.bottom-slider-item {
  padding: 0 1em;
  text-align: center; }
  .bottom-slider-item__icon {
    background: #efefef;
    border-radius: 50%;
    display: inline-block;
    height: 82px;
    padding: 18px;
    margin: 0 0 5px;
    width: 82px; }
    .bottom-slider-item__icon > svg {
      display: block;
      height: auto;
      max-width: 45px;
      width: 100%; }
      .bottom-slider-item__icon > svg > path {
        fill: #212429; }
  .bottom-slider-item__title {
    color: #6e6e6e;
    display: inline-block;
    line-height: 1.333;
    text-align: center; }

.popup-order-call {
  background: #fff;
  border-left: 1px solid #f8f8f8;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.13);
  cursor: default;
  position: absolute;
  padding: 1em 65px;
  top: 100%;
  right: 1em;
  opacity: 0;
  transition: 0.2s ease-out;
  visibility: hidden;
  width: 405px;
  z-index: 0; }
  .popup-order-call:before {
    content: '';
    display: block;
    position: absolute;
    left: 31px;
    top: -13px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 16px solid #fff;
    z-index: 1; }
  .popup-order-call.active {
    opacity: 1;
    visibility: visible;
    z-index: 3; }
  .popup-order-call__title {
    color: #3a3a3a;
    font-size: 20px;
    text-align: center; }
  .popup-order-call .field.chb > .input__wrapper > label {
    font-size: 11px;
    line-height: 1; }
  .popup-order-call .field.last {
    margin: 9px 0 0; }
    .popup-order-call .field.last .input__wrapper {
      margin: 1em 0 0; }
  @media only screen and (max-width: 992px) {
    .popup-order-call {
      right: 0;
      padding: 1em 2em;
      width: 339px; }
      .popup-order-call:before {
        left: 8em; } }
  @media only screen and (max-width: 580px) {
    .popup-order-call {
      width: 320px; }
      .popup-order-call:before {
        left: 12.68em; } }

.order-block.hide {
  display: none; }

.calc-block__title,
.order-choice__title {
  margin: 0 0 3em;
  text-align: center; }
  .calc-block__title > span,
  .order-choice__title > span {
    color: #212429;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2; }

.order-tabs__item {
  display: block;
  float: left;
  cursor: pointer;
  position: relative;
  width: 50%; }
  .order-tabs__item.complect {
    padding: 0 1em 0 0;
    text-align: right; }
  .order-tabs__item.car {
    padding: 0 0 0 1em;
    text-align: left; }
    .order-tabs__item.car > .order-tabs__title {
      max-width: 122px;
      text-align: center; }
    .order-tabs__item.car > .icon {
      padding: 1.7em 1.2em; }
  .order-tabs__item.active > .icon {
    background: #496040; }
    .order-tabs__item.active > .icon > svg > path {
      fill: #fff; }
  .order-tabs__item > .icon {
    background: #efefef;
    border-radius: 50%;
    display: inline-block;
    padding: 1.2em;
    margin: 0 0 1em;
    height: 120px;
    width: 120px; }
    .order-tabs__item > .icon > svg {
      display: block;
      height: auto;
      max-width: 100px;
      width: 100%; }
      .order-tabs__item > .icon > svg > path {
        fill: #496040; }
  .order-tabs__item__title {
    color: #686868;
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center; }

.no-mobile .order-choice-item > .icon {
  transition: 0.2s ease-out; }

.no-mobile .order-choice-item__title {
  transition: 0.2s ease-out; }

.no-mobile .order-choice-item:hover .order-choice-item__title {
  color: #496040; }

.no-mobile .order-choice-item:hover .order-choice-item > .icon {
  background: #496040; }
  .no-mobile .order-choice-item:hover .order-choice-item > .icon > svg > path {
    fill: #fff; }

.cargo-order-block {
  margin: 3em 0 0; }
  .cargo-order-block.hide {
    display: none; }
  .cargo-order-block__title {
    margin: 0 0 2em;
    text-align: center; }
    .cargo-order-block__title > span {
      display: inline-block;
      color: #212429;
      font-size: 20px;
      font-weight: bold; }

.cargo-order-result {
  padding: 2em 0; }
  .cargo-order-result__price {
    padding: 1em 0 4em;
    position: relative;
    text-align: center; }
    .cargo-order-result__price > .loader {
      margin: -12px 0 0 -34px;
      position: absolute; }
  .cargo-order-result__title {
    text-align: center; }
  .cargo-order-result__track {
    text-align: center; }
  .cargo-order-result__btn {
    margin: 2em 0 0;
    text-align: center; }
  .cargo-order-result.hide {
    display: none; }

.order-tabs {
  position: relative; }
  .order-tabs.hide {
    display: none; }
  .order-tabs__title {
    color: #496040;
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2; }

.field-wrapper {
  display: none; }
  .field-wrapper.active {
    display: block; }

.cargo-type {
  display: none; }
  .cargo-type.active {
    display: block; }

.field-set {
  margin: 1em 0; }
  .field-set__title {
    color: #212429;
    font-size: 18px;
    font-weight: bold;
    text-align: center; }

.calc-order-form .noop,
.cargo-order-form .noop {
  display: block;
  height: 1px;
  width: 100%; }

.calc-order-form .select-wrapper,
.cargo-order-form .select-wrapper {
  display: block; }

.calc-order-form .example,
.cargo-order-form .example {
  font-size: 12px;
  padding: 3px 0 0; }

.calc-order-form .dim-title,
.cargo-order-form .dim-title {
  height: 40px;
  line-height: 39px;
  text-align: center;
  width: 40px; }

.calc-order-form .field.lastest.hide,
.cargo-order-form .field.lastest.hide {
  display: none; }

.calc-order-form .field.check-place-dim,
.cargo-order-form .field.check-place-dim {
  display: none; }
  .calc-order-form .field.check-place-dim.active,
  .cargo-order-form .field.check-place-dim.active {
    display: block; }

.calc-order-form .field > .field__title,
.cargo-order-form .field > .field__title {
  float: left;
  padding: 0 1em 0 0;
  text-align: right;
  width: 150px; }
  .calc-order-form .field > .field__title > span,
  .cargo-order-form .field > .field__title > span {
    color: #212429;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    width: 100%; }

.calc-order-form .field > .input-wrapper,
.cargo-order-form .field > .input-wrapper {
  float: right;
  padding: 0 0 0 1em;
  width: calc(100% - 150px); }
  .calc-order-form .field > .input-wrapper.dop-addr,
  .cargo-order-form .field > .input-wrapper.dop-addr {
    display: none;
    margin: 1em 0 0; }
    .calc-order-form .field > .input-wrapper.dop-addr.active,
    .cargo-order-form .field > .input-wrapper.dop-addr.active {
      display: block; }
  .calc-order-form .field > .input-wrapper.chb > div,
  .cargo-order-form .field > .input-wrapper.chb > div {
    display: inline-block;
    margin: 0 1em 0 0;
    vertical-align: middle; }
    .calc-order-form .field > .input-wrapper.chb > div:before,
    .cargo-order-form .field > .input-wrapper.chb > div:before {
      display: none; }
    .calc-order-form .field > .input-wrapper.chb > div > label,
    .cargo-order-form .field > .input-wrapper.chb > div > label {
      padding: 0 0 0 21px; }

.calc-order-form .field .input-text,
.cargo-order-form .field .input-text {
  padding: 0 2em 0 1em; }

.calc-order-form .field.dim .fake-input,
.cargo-order-form .field.dim .fake-input {
  background: #e6e6e6;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  display: block; }
  .calc-order-form .field.dim .fake-input.has-success,
  .cargo-order-form .field.dim .fake-input.has-success {
    border-color: rgba(73, 208, 0, 0.8) !important; }
    .calc-order-form .field.dim .fake-input.has-success > .input-text,
    .cargo-order-form .field.dim .fake-input.has-success > .input-text {
      border-color: transparent !important; }
  .calc-order-form .field.dim .fake-input.has-error,
  .cargo-order-form .field.dim .fake-input.has-error {
    border-color: rgba(255, 10, 20, 0.8) !important; }
    .calc-order-form .field.dim .fake-input.has-error > .input-text,
    .cargo-order-form .field.dim .fake-input.has-error > .input-text {
      border-color: transparent !important; }
  .calc-order-form .field.dim .fake-input > span,
  .cargo-order-form .field.dim .fake-input > span {
    color: #686868;
    display: block;
    float: left;
    font-size: 15px;
    line-height: 37px;
    height: 38px;
    text-align: center;
    width: 20px; }
  .calc-order-form .field.dim .fake-input > .input-text,
  .cargo-order-form .field.dim .fake-input > .input-text {
    background: transparent;
    border-color: transparent;
    display: block;
    float: left;
    padding: 0 1em;
    height: 38px;
    line-height: 37px;
    text-align: center;
    width: calc(33.333% - 14px); }

.calc-order-form .field.dim > .input-wrapper,
.cargo-order-form .field.dim > .input-wrapper {
  float: left;
  padding: 0 1em;
  width: calc(100% - 150px); }
  .calc-order-form .field.dim > .input-wrapper.has-success:before, .calc-order-form .field.dim > .input-wrapper.has-error:before,
  .cargo-order-form .field.dim > .input-wrapper.has-success:before,
  .cargo-order-form .field.dim > .input-wrapper.has-error:before {
    right: 1em; }

.calc-order-form .field.dim > .select-wrapper,
.cargo-order-form .field.dim > .select-wrapper {
  background: #e6e6e6;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  float: right;
  height: 40px;
  width: 40px; }
  .calc-order-form .field.dim > .select-wrapper > select,
  .cargo-order-form .field.dim > .select-wrapper > select {
    cursor: pointer;
    height: 38px;
    padding: 0 13px 0 6px; }
  .calc-order-form .field.dim > .select-wrapper:before,
  .cargo-order-form .field.dim > .select-wrapper:before {
    right: 3px;
    margin-top: -4px; }

.calc-order-form .field.vol > .input-wrapper:before,
.cargo-order-form .field.vol > .input-wrapper:before {
  right: 48px; }

.calc-order-form .field.vol .input-text,
.cargo-order-form .field.vol .input-text {
  float: left;
  width: calc(100% - 40px); }

.calc-order-form .field.vol .volume-title,
.cargo-order-form .field.vol .volume-title {
  float: right;
  line-height: 39px;
  text-align: center;
  width: 40px; }

.calc-order-form .field.rad > .input-wrapper > div,
.cargo-order-form .field.rad > .input-wrapper > div {
  display: inline-block;
  vertical-align: middle; }
  .calc-order-form .field.rad > .input-wrapper > div > label,
  .cargo-order-form .field.rad > .input-wrapper > div > label {
    padding: 0 0 0 24px; }
  .calc-order-form .field.rad > .input-wrapper > div:first-child,
  .cargo-order-form .field.rad > .input-wrapper > div:first-child {
    margin: 0 2em 0 0; }

.flexbox .calc-order-form .field,
.flexbox .cargo-order-form .field {
  display: flex; }
  .flexbox .calc-order-form .field.check-place-dim,
  .flexbox .cargo-order-form .field.check-place-dim {
    display: none; }
    .flexbox .calc-order-form .field.check-place-dim.active,
    .flexbox .cargo-order-form .field.check-place-dim.active {
      display: flex; }
  .flexbox .calc-order-form .field.add-adr,
  .flexbox .cargo-order-form .field.add-adr {
    flex-wrap: wrap;
    justify-content: flex-end; }
  .flexbox .calc-order-form .field.dim > .select-wrapper,
  .flexbox .cargo-order-form .field.dim > .select-wrapper {
    flex-basis: 40px; }
  .flexbox .calc-order-form .field.dim > .input-wrapper,
  .flexbox .cargo-order-form .field.dim > .input-wrapper {
    flex-basis: calc(100% - 150px - 40px); }
  .flexbox .calc-order-form .field > .input-wrapper,
  .flexbox .cargo-order-form .field > .input-wrapper {
    float: none;
    flex-basis: calc(100% - 150px);
    width: auto; }
  .flexbox .calc-order-form .field > .field__title,
  .flexbox .cargo-order-form .field > .field__title {
    align-items: center;
    display: flex;
    float: none;
    flex-basis: 150px;
    flex-wrap: wrap; }

.sitemap-title {
  text-align: center; }
  .sitemap-title > span {
    color: #212429;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0 0 1em; }

.content-inner .sitemap_sub {
  display: block; }
  .content-inner .sitemap_sub li {
    color: #6e6e6e; }
    .content-inner .sitemap_sub li span {
      cursor: pointer; }
    .content-inner .sitemap_sub li span,
    .content-inner .sitemap_sub li a {
      color: #6e6e6e;
      display: inline-block;
      font-size: 15px; }

.contacts-map {
  margin: 2em 0 0; }

.tarif-table {
  table-layout: fixed;
  width: 100%; }
  .tarif-table > thead > tr > th {
    line-height: 1.2;
    text-align: left; }
    .tarif-table > thead > tr > th:nth-child(1) {
      width: 24%; }
    .tarif-table > thead > tr > th:last-child {
      width: 11%; }
    @media only screen and (max-width: 480px) {
      .tarif-table > thead > tr > th {
        font-size: 11px; } }
  .tarif-table > tbody > tr {
    border-bottom: 1px solid #efefef; }
    .tarif-table > tbody > tr > td {
      padding: 2px; }
      .tarif-table > tbody > tr > td[colspan="6"] {
        font-weight: bold;
        text-align: center; }
      @media only screen and (max-width: 480px) {
        .tarif-table > tbody > tr > td {
          font-size: 11px; } }

.apark__title {
  color: #212429;
  font-size: 22px;
  text-transform: uppercase; }

@media only screen and (max-width: 882px) {
  .index .apark-item {
    text-align: center; } }

.index .apark-item__buttons > .button {
  display: block; }
  .index .apark-item__buttons > .button:first-child {
    margin: 0 0 10px; }

@media only screen and (max-width: 882px) {
  .index .apark-item__header {
    display: inline-block; } }

@media only screen and (max-width: 882px) {
  .index .apark-item__wrapper {
    display: inline-block; } }

.index .apark-item__info {
  max-height: 181px;
  overflow: hidden;
  width: calc(100% - 154px); }
  @media only screen and (max-width: 882px) {
    .index .apark-item__info {
      text-align: left; } }

.index .apark-item__photo {
  width: 154px; }

.index .apark-slider {
  padding: 2em 55px; }
  .index .apark-slider .slick-next {
    right: 1em; }
  .index .apark-slider .slick-prev {
    left: 1em; }

.apark-slider {
  padding: 2em 40px; }
  .apark-slider .slick-arrow {
    top: 50%; }
  .apark-slider .slick-next {
    right: 0; }
  .apark-slider .slick-prev {
    left: 0; }

.apark-block .apark-item {
  border: 1px solid #eee;
  margin: 0 0 2em; }

.apark-item {
  padding: 1em; }
  .apark-item__header {
    padding: 0 0 0.5em; }
  .apark-item__title {
    font-size: 18px;
    font-weight: bold; }
  .apark-item__photo {
    float: left;
    width: 40%; }
    .apark-item__photo > img {
      display: block;
      height: auto;
      max-width: 334px;
      width: 100%; }
  .apark-item__info {
    float: left;
    padding: 0 0 0 2em;
    width: 60%; }
    .apark-item__info > p {
      line-height: 1.666;
      padding: 0; }
  .apark-item__buttons {
    margin: 1em 0 0; }
    .apark-item__buttons > .button:first-child {
      margin: 0 10px 0 0; }
    .apark-item__buttons > .button > span {
      font-size: 12px;
      height: 28px;
      line-height: 28px; }

@media only screen and (max-width: 480px) {
  .no-index .apark-item__title {
    text-align: center; }
  .no-index .apark-item__photo {
    float: none;
    width: 100%; }
    .no-index .apark-item__photo > img {
      margin: 0 auto; }
  .no-index .apark-item__buttons {
    text-align: center; }
  .no-index .apark-item__info {
    display: none; } }

.apark-header {
  margin: 0 0 2em; }
  .apark-header__img {
    float: left;
    padding: 0 1em 0 0;
    width: 50%; }
    .apark-header__img > a > img,
    .apark-header__img > span > img {
      display: block;
      height: auto;
      max-width: 551px;
      width: 100%; }
  .apark-header__info {
    float: right;
    padding: 0 0 0 1em;
    width: 50%; }
    .apark-header__info > p {
      padding: 0; }

.payment-search-order-form > .field {
  float: left;
  width: calc(100% - 168px); }
  .payment-search-order-form > .field .input-text {
    padding: 0 1em; }
  .payment-search-order-form > .field:first-child > .field__title {
    float: left;
    padding: 0 1em 0 0;
    text-align: right;
    width: 109px; }
    .payment-search-order-form > .field:first-child > .field__title > span {
      color: #212429;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      line-height: 38px;
      width: 100%; }
  .payment-search-order-form > .field:first-child > .input-wrapper {
    float: right;
    padding: 0 0 0 1em;
    width: calc(100% - 109px); }
  .payment-search-order-form > .field.btn {
    padding: 0 0 0 1em;
    width: 168px; }
    .payment-search-order-form > .field.btn .button > span {
      height: 36px;
      line-height: 36px; }

.payment-block__title {
  margin: 0 0 3em;
  text-align: center; }
  .payment-block__title > span {
    color: #212429;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2; }

.payment-block__icons {
  margin: 2em 0 0;
  text-align: center; }
  .payment-block__icons > span {
    display: inline-block; }
    .payment-block__icons > span:not(:last-child) {
      margin: 0 1em 0 0; }
    .payment-block__icons > span > svg {
      display: block;
      height: 21px;
      max-width: 68px;
      width: auto; }

.p-form {
  text-align: center; }
  .p-form__row.btn {
    margin: 1em 0 0; }

/* Основные стили */
/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.input-text,
.input-textarea {
  background: #e6e6e6;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  color: #686868;
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 22px;
  padding: 1em 1em 0 1em;
  outline: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.input-textarea {
  padding: 1em 1em 0.5em 1em;
  height: 224px;
  line-height: 17px; }

textarea {
  display: block;
  resize: none;
  padding: 0.5em 1em; }

.check-status-form .input-text,
.check-status-form .input-textarea,
.profile-table .input-text,
.profile-table .input-textarea {
  padding: 0 1em; }

.form__label {
  color: #686868;
  font-size: 14px;
  pointer-events: none;
  line-height: 1.5;
  transition: 0.2s ease-out; }

.call-back-form .input-form {
  padding: 13px 1em 0; }

.files_element {
  position: relative; }

.field {
  margin: 18px 0 0;
  position: relative;
  text-align: left; }
  .field.file .button {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2; }
    .field.file .button > span {
      height: 36px;
      line-height: 35px; }
  .field.file .input_upload {
    position: absolute;
    visibility: hidden; }
  .field.personal {
    padding: 0.5em 0;
    margin: 0; }
    .field.personal label {
      color: inherit;
      cursor: pointer;
      font-size: 13px;
      display: inline-block;
      line-height: 1.333; }
      .field.personal label.has-error {
        color: rgba(255, 10, 20, 0.8); }
      @media only screen and (max-width: 768px) {
        .field.personal label {
          font-size: 13px; } }
    .field.personal .input-form {
      margin: 0 0.5em 0 0;
      vertical-align: bottom;
      width: auto; }
  .field.mail {
    margin: 1em 0 0; }
    .field.mail label {
      color: #a9a9a9; }
  .field.check-agree > .input__wrapper > label {
    font-size: 12px; }
  .field.chb > .input__wrapper > label {
    color: #212429;
    display: block;
    width: 100%; }
  .field.ta > .form__label {
    top: 10px; }
    @media only screen and (max-width: 768px) {
      .field.ta > .form__label {
        top: 8px; } }
  .field.payments > .form__label {
    color: #212429;
    font-size: 16px;
    font-weight: bold;
    position: static;
    margin: 0 0 10px; }
  .field:not(.chb) .has-error:before {
    content: "";
    color: #1a73e8;
    font: normal 18px/18px "FontAwesome";
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -9px;
    top: 50%;
    left: 50%;
    z-index: 2;
    color: rgba(255, 10, 20, 0.8);
    font-size: 18px;
    left: auto;
    right: 8px;
    margin: 0;
    top: 11px; }
  .field:not(.chb) .has-success:before {
    content: "";
    color: #1a73e8;
    font: normal 18px/18px "FontAwesome";
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -9px;
    top: 50%;
    left: 50%;
    z-index: 2;
    color: rgba(73, 208, 0, 0.8);
    font-size: 18px;
    left: auto;
    right: 8px;
    margin: 0;
    top: 12px; }
  .field .form__label {
    pointer-events: none;
    position: absolute;
    top: 9px;
    left: 16px;
    z-index: 1; }
    .field .form__label.animate {
      font-size: 10px;
      top: 2px; }
  .field.last .input__wrapper {
    margin: 2em 0 0;
    text-align: center; }
  .field.last > .form__label {
    font-size: 12px;
    margin: 6px 0 0;
    position: static;
    text-align: center; }

.has-error {
  position: relative; }
  .has-error > label {
    color: rgba(255, 10, 20, 0.8); }
  .has-error > .input-text,
  .has-error > .input-textarea {
    border-color: rgba(255, 10, 20, 0.8); }

.has-success {
  position: relative; }
  .has-success > .input-text,
  .has-success > .input-textarea {
    border-color: rgba(73, 208, 0, 0.8); }

.help-block {
  font-size: 11px;
  position: absolute;
  top: 5px;
  right: 1em;
  pointer-events: none; }
  .help-block.form-error {
    color: rgba(255, 10, 20, 0.8); }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: #e6e6e6 !important;
  background-image: none;
  color: inherit !important; }

.ui-datepicker .ui-widget-header {
  background: #1a73e8;
  color: #fff; }

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important; }

.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a {
  color: #1a73e8 !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important; }

.ui-datepicker td.ui-datepicker-today > a {
  color: #fff !important;
  background: #1a73e8 !important;
  border: 1px solid #1a73e8 !important; }

.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid #1a73e8 !important; }

.ui-datepicker th.ui-datepicker-week-end > span {
  color: #1a73e8 !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a:hover {
  color: #1a73e8 !important; }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.mobile .menu-button {
  display: block; }

.header-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 51; }
  .header-top:before {
    content: '';
    background: #13151b;
    display: block;
    left: 0;
    right: 0;
    position: absolute;
    height: 56px;
    top: 0;
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .header-top:before {
        bottom: 0;
        height: 33px; } }
  .header-top:after {
    content: '';
    background: rgba(21, 23, 29, 0.65);
    display: block;
    left: 0;
    right: 0;
    position: absolute;
    top: 56px;
    bottom: 0;
    z-index: 0; }
    @media only screen and (max-width: 768px) {
      .header-top:after {
        top: 33px; } }
  .header-top > .container {
    position: relative;
    height: 100%; }
  .header-top__menu-top {
    display: none; }
    @media only screen and (max-width: 950px) {
      .header-top__menu-top {
        display: block; } }
  .header-top__menu {
    float: left;
    padding: 4px 0 0;
    width: calc(100% - 190px - 153px - 62px); }
    @media only screen and (max-width: 1208px) {
      .header-top__menu {
        width: calc(100% - 150px - 153px - 62px); } }
    @media only screen and (max-width: 1099px) {
      .header-top__menu {
        width: calc(100% - 150px - 62px); } }
    @media only screen and (max-width: 950px) {
      .header-top__menu {
        background: #1a73e8;
        position: fixed;
        padding: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: 0.2s ease-in-out;
        width: 320px;
        z-index: 1000; }
        .header-top__menu.open {
          transform: translateX(0); } }
  .header-top .search-icon {
    cursor: pointer;
    padding: 4px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
    .header-top .search-icon > svg {
      display: block;
      height: auto;
      max-width: 24px;
      width: 100%; }
  @media only screen and (max-width: 768px) {
    .header-top {
      position: fixed;
      padding: 33px 0 0; } }

@media only screen and (min-width: 769px) {
  .no-mobile.header-fixed .header-logo__wrapper > svg {
    max-width: 98px; }
  .no-mobile.header-fixed .header-logo__wrapper > a > svg {
    max-width: 98px; }
  .no-mobile.header-fixed .header-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; }
    .no-mobile.header-fixed .header-top:after {
      top: 0; }
    .no-mobile.header-fixed .header-top:before {
      visibility: hidden; }
  .no-mobile.header-fixed .header-info__top {
    display: none; } }

.no-index .header-top:after {
  background: rgba(21, 23, 29, 0.95); }

#icon-search {
  cursor: pointer; }

.header-logo {
  float: left;
  overflow: hidden;
  width: 28%; }
  .header-logo__wrapper {
    background: #fff;
    left: 1em;
    padding: 13px 5%;
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    width: 28%;
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .header-logo__wrapper {
        left: 0;
        width: 25%; } }
    @media only screen and (max-width: 580px) {
      .header-logo__wrapper {
        width: 120px; } }
    .header-logo__wrapper > a {
      display: block; }
      .header-logo__wrapper > a > svg {
        display: inline-block;
        height: auto;
        max-width: 195px;
        transition: width 0.2s ease-out;
        width: 100%; }
        @media only screen and (max-width: 768px) {
          .header-logo__wrapper > a > svg {
            max-width: 108px; } }
        @media only screen and (max-width: 580px) {
          .header-logo__wrapper > a > svg {
            max-width: 78px; } }
    .header-logo__wrapper > svg {
      display: inline-block;
      height: auto;
      max-width: 195px;
      transition: 0.1s ease-out;
      width: 100%; }
      @media only screen and (max-width: 768px) {
        .header-logo__wrapper > svg {
          max-width: 108px; } }
      @media only screen and (max-width: 580px) {
        .header-logo__wrapper > svg {
          max-width: 85px; } }
  @media only screen and (max-width: 1208px) {
    .header-logo {
      width: 22%; }
      .header-logo__wrapper {
        padding: 13px 2%;
        width: 22%; } }
  @media only screen and (max-width: 992px) {
    .header-logo__wrapper {
      padding: 13px 1em; } }
  @media only screen and (max-width: 768px) {
    .header-logo {
      width: 25%; }
      .header-logo__wrapper {
        padding: 6px 1em; } }
  @media only screen and (max-width: 580px) {
    .header-logo {
      width: 120px; }
      .header-logo__wrapper {
        padding: 4px 1em; } }

.flexbox .header-logo__wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.header-info {
  float: right;
  padding: 0 1em;
  position: relative;
  width: 72%;
  z-index: 2; }
  @media only screen and (max-width: 1208px) {
    .header-info {
      width: 78%; } }
  @media only screen and (max-width: 768px) {
    .header-info {
      width: 75%; } }
  @media only screen and (max-width: 580px) {
    .header-info {
      width: calc(100% - 120px); } }
  .header-info__top {
    padding: 13px 0 10px; }
    .header-info__top > nav {
      float: left;
      padding: 0 1em;
      width: calc(100% - 273px - 99px); }
      @media only screen and (max-width: 992px) {
        .header-info__top > nav > ul {
          display: none; } }
    .header-info__top > .header-info__phones {
      float: left;
      padding: 0 0.5em 0 0;
      width: 273px; }
      .header-info__top > .header-info__phones > span {
        display: block;
        float: left;
        width: 50%; }
        .header-info__top > .header-info__phones > span > a {
          color: #fff;
          display: inline-block;
          font-size: 15px; }
        .header-info__top > .header-info__phones > span:last-child {
          padding: 0 0 0 0.25em; }
        .header-info__top > .header-info__phones > span:first-child {
          padding: 0 0.25em 0 0;
          position: relative; }
          .header-info__top > .header-info__phones > span:first-child:after {
            color: #fff;
            content: '|';
            font-size: 14px;
            position: absolute;
            right: 2px;
            top: 0; }
        @media only screen and (max-width: 414px) {
          .header-info__top > .header-info__phones > span:last-child {
            display: none; }
          .header-info__top > .header-info__phones > span:first-child:after {
            display: none; } }
      @media only screen and (max-width: 992px) {
        .header-info__top > .header-info__phones {
          text-align: right;
          width: calc(100% - 99px); }
          .header-info__top > .header-info__phones > span {
            display: inline-block;
            float: none;
            width: auto; }
            .header-info__top > .header-info__phones > span:first-child {
              padding: 0 13px 0 0; }
            .header-info__top > .header-info__phones > span > a {
              color: #fff;
              display: inline-block;
              font-size: 15px; } }
    .header-info__top > .header-info__social {
      float: right;
      width: 99px; }
    @media only screen and (max-width: 768px) {
      .header-info__top {
        padding: 3px 1em 0;
        right: 0;
        position: fixed;
        top: 0;
        left: 0; } }
  .header-info__bottom {
    position: relative; }
    .header-info__bottom > .header-info__mail {
      float: left;
      padding: 17px 1em 17px;
      text-align: right;
      width: calc(100% - 208px - 122px - 198px); }
      .header-info__bottom > .header-info__mail > a {
        color: #fff;
        display: inline-block;
        font-size: 15px;
        text-decoration: underline;
        white-space: nowrap; }
        @media only screen and (max-width: 444px) {
          .header-info__bottom > .header-info__mail > a {
            display: none; } }
      @media only screen and (max-width: 992px) {
        .header-info__bottom > .header-info__mail {
          padding: 17px 1.5em 17px 1em;
          width: calc(100% - 40px - 122px - 59px); } }
      @media only screen and (max-width: 580px) {
        .header-info__bottom > .header-info__mail {
          padding: 7px 1em 10px 0;
          width: calc(100% - 32px - 68px - 32px); } }
  .header-info__ordercall {
    float: left;
    margin: 10px 0 0;
    padding: 0 40px 0 0;
    position: relative;
    width: 208px; }
    @media only screen and (max-width: 992px) {
      .header-info__ordercall {
        padding: 0;
        margin: 10px 19px 0 0;
        width: 40px; } }
    @media only screen and (max-width: 580px) {
      .header-info__ordercall {
        margin: 0;
        padding: 3px 0 5px;
        width: 32px; } }
    .header-info__ordercall > .button {
      border-color: transparent;
      position: static; }
      .header-info__ordercall > .button > span {
        position: relative;
        text-transform: none; }
        .header-info__ordercall > .button > span:before {
          border-top: 1px dotted #fff;
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          bottom: 10px;
          left: 1em;
          right: 1em;
          z-index: 1; }
      .header-info__ordercall > .button > .icon {
        font-style: normal;
        display: block;
        height: 40px;
        padding: 6px;
        position: absolute;
        top: 0;
        right: 20px;
        margin: 0;
        user-select: none;
        width: 40px; }
        .header-info__ordercall > .button > .icon > svg {
          display: block;
          height: auto;
          max-width: 28px;
          width: 100%; }
        @media only screen and (max-width: 580px) {
          .header-info__ordercall > .button > .icon {
            height: 32px;
            width: 32px; } }
      @media only screen and (max-width: 992px) {
        .header-info__ordercall > .button > .icon {
          position: static; }
        .header-info__ordercall > .button > span {
          display: none; } }
      @media only screen and (max-width: 580px) {
        .header-info__ordercall > .button {
          display: block; } }
  .header-info__icons {
    float: left;
    padding: 10px 0;
    width: 122px; }
    .header-info__icons > .icon {
      display: inline-block;
      height: 40px;
      padding: 6px;
      margin: 0 19px 0 0;
      user-select: none;
      width: 40px; }
      @media only screen and (max-width: 580px) {
        .header-info__icons > .icon {
          margin: 0;
          height: 32px;
          width: 32px; } }
      .header-info__icons > .icon.search {
        padding: 7px; }
      .header-info__icons > .icon > svg {
        display: block;
        height: auto;
        max-width: 28px;
        width: 100%; }
    @media only screen and (max-width: 580px) {
      .header-info__icons {
        padding: 5px 0 6px;
        width: 68px; } }
  .header-info__social > .icon {
    display: inline-block;
    height: 30px;
    padding: 5px;
    width: 30px;
    vertical-align: middle; }
    .header-info__social > .icon > a {
      display: block; }
      .header-info__social > .icon > a > svg {
        display: block;
        height: auto;
        max-width: 24px;
        width: 100%; }
  .header-info__auth {
    cursor: pointer;
    float: right;
    margin: 11px 0 0;
    padding: 7px 20px 12px 61px;
    position: relative;
    width: 198px; }
    .header-info__auth > .icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      padding: 6px;
      width: 40px; }
      .header-info__auth > .icon > a > svg {
        display: block;
        height: auto;
        max-width: 28px;
        width: 100%; }
      .header-info__auth > .icon > svg {
        display: block;
        height: auto;
        max-width: 28px;
        width: 100%; }
      @media only screen and (max-width: 580px) {
        .header-info__auth > .icon {
          height: 32px;
          width: 32px; } }
    .header-info__auth > a {
      color: #fff;
      display: inline-block;
      position: relative; }
      .header-info__auth > a:before {
        border-top: 1px dotted #fff;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        bottom: 3px;
        left: 0;
        right: 0;
        z-index: 1; }
    .header-info__auth:after {
      color: #fff;
      content: "\f0d7";
      font: normal 16px/16px "FontAwesome";
      pointer-events: none;
      position: absolute;
      margin: -10px 0 0 -9px;
      top: 50%;
      right: 10px;
      left: auto; }
    @media only screen and (max-width: 992px) {
      .header-info__auth {
        padding: 0;
        width: 40px; }
        .header-info__auth > .icon {
          position: static; }
        .header-info__auth:after {
          display: none; }
        .header-info__auth > a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          text-indent: -9999px; }
          .header-info__auth > a:before {
            display: none; } }
    @media only screen and (max-width: 580px) {
      .header-info__auth {
        margin: 4px 0 0;
        width: 32px; } }
  .header-info__auth-title {
    color: #fff;
    display: inline-block;
    position: relative;
    user-select: none;
    white-space: nowrap; }
    .header-info__auth-title:before {
      border-top: 1px dotted #fff;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      bottom: 3px;
      left: 0;
      right: 0;
      z-index: 1; }
    @media only screen and (max-width: 992px) {
      .header-info__auth-title {
        display: none; } }

.no-mobile .header-info__auth > a:hover {
  color: #fff; }

/*
.header-search {
	float: right;
	padding: 0 1em;
	position: relative;
	width: 62px;

	& > .search-icon {
		height: 32px;
		width: 32px;
	}
}
*/
/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.slider-block {
  overflow: hidden;
  position: relative;
  height: 715px;
  width: 100%; }
  .slider-block .ya-map {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 400px !important; }
  @media only screen and (max-width: 1600px) {
    .slider-block {
      height: calc(900px + 30vw - 600px); } }
  .slider-block > .container {
    position: absolute;
    top: 117px;
    bottom: 0;
    left: calc(((100% - 1208px) / 2) + 1em);
    right: calc(((100% - 1208px) / 2) + 1em);
    z-index: 3; }
    @media only screen and (max-width: 1208px) {
      .slider-block > .container {
        left: 1em;
        right: 1em; } }
    @media only screen and (max-width: 768px) {
      .slider-block > .container {
        top: 95px; } }
    @media only screen and (max-width: 672px) {
      .slider-block > .container {
        left: 0;
        right: 0; } }
    @media only screen and (max-width: 508px) {
      .slider-block > .container {
        top: 77px; } }
    .slider-block > .container > .row > .col-4 {
      width: 368px; }
      @media only screen and (max-width: 672px) {
        .slider-block > .container > .row > .col-4 {
          display: none; } }
    .slider-block > .container > .row > .col-8 {
      padding: 0 5% 0 3px;
      width: calc(100% - 368px); }
      @media only screen and (max-width: 992px) {
        .slider-block > .container > .row > .col-8 {
          padding: 0; } }
      @media only screen and (max-width: 672px) {
        .slider-block > .container > .row > .col-8 {
          padding: 0 1em;
          width: 100%; } }
  .slider-block .slider,
  .slider-block .slick-list,
  .slider-block .slick-track {
    height: 100%; }
  .slider-block .slick-slider {
    z-index: 2; }
  .slider-block .slick-arrow {
    margin: 0; }
  .slider-block .slick-prev {
    left: auto;
    right: calc(((100% - 1800px) / 2) + 1em);
    top: 30%; }
    @media only screen and (max-width: 1800px) {
      .slider-block .slick-prev {
        right: 21px; } }
    .slider-block .slick-prev:before {
      content: '\f106';
      font-size: 45px;
      line-height: 22px;
      margin: -14px 0 0 -15px; }
  .slider-block .slick-next {
    right: calc(((100% - 1800px) / 2) + 1em);
    bottom: 30%;
    top: auto; }
    @media only screen and (max-width: 1800px) {
      .slider-block .slick-next {
        right: 21px; } }
    .slider-block .slick-next:before {
      content: '\f107';
      font-size: 45px;
      line-height: 22px;
      margin: -11px 0 0 -15px; }
  .slider-block .slick-dots {
    bottom: auto;
    left: auto;
    right: calc(((100% - 1800px) / 2) + 8px);
    top: 50%;
    transform: translateY(-50%);
    width: 48px; }
    @media only screen and (max-width: 1800px) {
      .slider-block .slick-dots {
        right: 1em; } }
    .slider-block .slick-dots > li {
      display: block; }

.slider-layer {
  padding: 11% 0 4%; }
  .slider-layer > h1.title {
    color: #fff;
    display: inline-block;
    font-size: 46px;
    line-height: 50px;
    text-transform: uppercase; }
    @media only screen and (max-width: 992px) {
      .slider-layer > h1.title {
        font-size: 31px;
        line-height: 1.333; } }
    @media only screen and (max-width: 768px) {
      .slider-layer > h1.title {
        font-size: 26px; } }
    @media only screen and (max-width: 480px) {
      .slider-layer > h1.title {
        font-size: 22px; } }
  .slider-layer__calc {
    background: rgba(20, 22, 28, 0.7);
    margin: 20% 0 1em;
    padding: 1em 2em 1.5em; }
  .slider-layer__check-status {
    background: rgba(20, 22, 28, 0.7);
    padding: 10px 1.5em 15px; }
    @media only screen and (max-width: 992px) {
      .slider-layer__check-status {
        display: none; } }
  .slider-layer__check-status-title, .slider-layer__calc-title {
    color: #496040;
    font-size: 20px; }
  @media only screen and (max-width: 480px) {
    .slider-layer {
      padding: 1em 0; } }

.slider-item__wrapper {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.slider-item.slick-active .slider-item__label {
  display: block;
  transition: 0.5s ease-out; }

.slider-item__label-wrapper {
  position: absolute;
  max-width: 670px;
  top: 270px;
  left: calc((100% - 1200px) / 2); }
  @media only screen and (max-width: 1600px) {
    .slider-item__label-wrapper {
      left: 192px;
      top: 50%;
      transform: translateY(-50%); } }
  @media only screen and (max-width: 943px) {
    .slider-item__label-wrapper {
      max-width: 100%;
      padding: 0 5em 0 0; } }
  @media only screen and (max-width: 768px) {
    .slider-item__label-wrapper {
      left: 163px; } }
  @media only screen and (max-width: 480px) {
    .slider-item__label-wrapper {
      left: 16px;
      top: 140px;
      transform: translateY(0); } }

.slider-item__label-title {
  color: #fff;
  font-size: 72px;
  font-weight: bold;
  line-height: 80px;
  margin: 0 0 31px; }
  @media only screen and (max-width: 1600px) {
    .slider-item__label-title {
      font-size: 52px;
      line-height: 55px; } }
  @media only screen and (max-width: 943px) {
    .slider-item__label-title {
      font-size: 34px;
      line-height: 36px; } }
  @media only screen and (max-width: 768px) {
    .slider-item__label-title {
      font-size: 34px;
      line-height: 36px; } }
  @media only screen and (max-width: 480px) {
    .slider-item__label-title {
      font-size: 26px;
      line-height: 25px;
      margin: 0 0 24px; } }
  @media only screen and (max-width: 414px) {
    .slider-item__label-title {
      font-size: 22px;
      line-height: 23px;
      margin: 0 0 11px; } }

.slider-item__label-text {
  color: #fff;
  font-size: 15px; }
  @media only screen and (max-width: 860px) {
    .slider-item__label-text {
      font-size: 14px; } }
  @media only screen and (max-width: 768px) {
    .slider-item__label-text {
      font-size: 13px; } }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

nav {
  display: block;
  text-align: right;
  /* Всплывающее меню */ }
  nav > ul {
    display: inline-block;
    list-style: none;
    margin: -1px 0 0;
    width: auto; }
    nav > ul > li {
      display: table-cell;
      padding: 0 1em; }
      nav > ul > li > span {
        color: #fff;
        display: block;
        font-size: 15px;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap; }
      nav > ul > li > a {
        color: #fff;
        display: block;
        font-size: 15px;
        padding: 0;
        text-decoration: none;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap; }
      nav > ul > li.active {
        cursor: default; }
        nav > ul > li.active > span:not(.arrow) {
          color: #fff;
          padding: 0 20px 0 0;
          position: relative; }
    nav > ul a {
      display: block;
      text-decoration: none; }
  nav .sub-menu {
    background: #fff;
    display: table;
    list-style: none;
    padding: 3px 0;
    position: absolute;
    opacity: 0;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: left;
    text-transform: none;
    visibility: hidden;
    width: auto; }
    nav .sub-menu > li {
      display: block;
      padding: 6px 12px; }
      nav .sub-menu > li .arrow {
        display: none; }
      nav .sub-menu > li > span {
        color: #1a73e8;
        display: block;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        user-select: none; }
      nav .sub-menu > li > a {
        color: #000;
        display: block;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        user-select: none; }

/*
.mobile {
	nav {
		display: block;
		overflow-x: hidden;
		overflow-y: auto;
		height: calc(100vh - 40px);
		padding: 0 0 80px;
		-webkit-overflow-scrolling: touch;

		& > ul {
			display: block;
			padding: 0 20px;

			& > li {
				display: block;
				padding: 0 20px 0 10px;

				& > a {
					font-size: 15px;
					line-height: 3;
					padding: 0 0 0 10px;
					text-align: left;
				}

				& > span:not(.arrow) {
					font-size: 15px;
					line-height: 3;
					padding: 0 0 0 10px;
					text-align: left;
				}

				&.has_child {
					padding: 0 40px 0 10px;
					position: relative;
				}

				.arrow {
					height: 40px;
					width: 40px;

					&:before {
						font-size: 20px;
					}
				}

			}
		}
	}
}
*/
.no-mobile nav > ul > li > a {
  transition: 0.2s ease-out; }

.no-mobile nav > ul > li > a:hover {
  color: #fff;
  text-decoration: none; }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.inner-block__header,
.side-block__header {
  padding: 1em 0; }
  .inner-block__header > span,
  .side-block__header > span {
    display: block;
    font-size: 32px;
    font-weight: bold;
    color: #212429;
    text-transform: uppercase;
    text-align: center; }

.inner-block__inner,
.side-block__inner {
  margin: 1em 0 2em; }
  .inner-block__inner .slick-slider,
  .side-block__inner .slick-slider {
    padding: 2em 0; }

.inner-block__wrapper,
.side-block__wrapper {
  padding: 1em 0;
  position: relative; }

.inner-block {
  padding: 0 0 2em;
  position: relative;
  z-index: 2; }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.news-block {
  background: #fff;
  padding: 40px 0; }
  .news-block__title {
    color: #000;
    font-size: 32px;
    line-height: 32px;
    text-align: center; }
    .news-block__title > span {
      color: #777;
      display: block;
      font-size: 18px;
      font-weight: 100;
      line-height: 24px;
      padding: 7px 0 0;
      text-align: center; }
  .news-block__wrapper {
    padding: 35px 0; }
    .news-block__wrapper > .row {
      margin: 0; }
      @media only screen and (max-width: 768px) {
        .news-block__wrapper > .row {
          margin: 0 -0.5em; } }

.last-news__item {
  margin: 0 0 20px; }
  @media only screen and (max-width: 768px) {
    .last-news__item.col-4 {
      padding: 0 0.5em; } }
  @media only screen and (max-width: 768px) {
    .last-news__item.col-4 {
      float: none;
      width: 100%; } }

.last-news__cat-title {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 0 15px;
  text-align: center; }
  .last-news__cat-title > span {
    border-bottom: 2px solid #0b7275;
    color: #000;
    display: inline-block;
    font-size: 22px;
    line-height: 33px;
    padding-bottom: 6px;
    position: relative; }

.last-news__img {
  margin: 0 0 20px; }
  .last-news__img > a {
    display: block; }
    .last-news__img > a > img {
      display: block;
      height: auto;
      max-width: 400px;
      width: 100%; }

.last-news__title {
  margin: 0 0 10px; }
  .last-news__title > a {
    color: #000;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px; }

.last-news__text {
  font-size: 13px; }

.no-mobile .last-news__img {
  opacity: 1; }
  .no-mobile .last-news__img:hover > a > img {
    opacity: 0.8; }

.no-mobile .last-news__title > a:hover {
  text-decoration: none; }

.news-header {
  margin: 24px 0 38px; }
  .news-header__title {
    margin: 0 0 20px;
    text-align: center; }
    .news-header__title > span {
      color: #fff;
      display: inline-block;
      font-size: 28px;
      font-weight: bold;
      line-height: 42px; }
  .news-header__desc {
    color: #fff;
    font-size: 16px;
    text-align: center; }

.news-list {
  background: #fff;
  padding: 30px 0; }
  .news-list__title {
    border-bottom: 1px solid #e8e8e8;
    padding: 0 0 15px;
    text-align: center; }
    .news-list__title > span {
      border-bottom: 2px solid #0b7275;
      color: #000;
      display: inline-block;
      font-size: 21px;
      padding-bottom: 6px;
      position: relative;
      bottom: -1px; }

.hot-news-item {
  margin: 0 7px; }
  .hot-news-item__img > a {
    display: block; }
    .hot-news-item__img > a > img {
      display: block;
      height: auto;
      max-width: 648px;
      width: 100%; }
  .hot-news-item__info {
    background: #f7f7f7;
    border: 1px solid #eee;
    padding: 15px 15px 10px; }
  .hot-news-item__title {
    margin: 0 0 5px; }
    .hot-news-item__title > a {
      color: #000;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      overflow-wrap: break-word;
      word-wrap: break-word; }
  .hot-news-item__date {
    font-size: 12px;
    color: #777;
    list-style: none;
    margin: 0 0 5px; }

.no-mobile .hot-news-item__img > a > img {
  opacity: 1;
  transition: all 0.25s; }

.no-mobile .hot-news-item__img:hover > a > img {
  opacity: 0.8; }

.no-mobile .hot-news-item__title > a:hover {
  text-decoration: none; }

.hot-news-slider,
.job-news-slider {
  padding: 0 0 40px; }
  @media only screen and (max-width: 768px) {
    .hot-news-slider,
    .job-news-slider {
      padding: 0 0 60px; } }
  .hot-news-slider .slick-arrow,
  .job-news-slider .slick-arrow {
    background: transparent;
    height: 32px;
    width: 32px; }
    .hot-news-slider .slick-arrow:before,
    .job-news-slider .slick-arrow:before {
      background: #2c87f0;
      border-radius: 2px;
      font-size: 19px;
      line-height: 19px;
      margin: -10px 0 0 -10px;
      height: 20px;
      width: 20px; }
  .hot-news-slider .slick-next,
  .job-news-slider .slick-next {
    right: auto;
    left: 3em;
    top: auto;
    bottom: 1em; }
  .hot-news-slider .slick-prev,
  .job-news-slider .slick-prev {
    left: 6px;
    top: auto;
    bottom: 1em; }

.no-mobile .hot-news-slider .slick-arrow,
.no-mobile .job-news-slider .slick-arrow {
  background: transparent; }

.job-news-item {
  margin: 0 7px; }
  .job-news-item__img > a {
    display: block; }
    .job-news-item__img > a > img {
      display: block;
      height: auto;
      max-width: 680px;
      width: 100%; }
  .job-news-item__info {
    background: #f7f7f7;
    border: 1px solid #eee;
    padding: 15px 15px 10px; }
  .job-news-item__title > a {
    color: #000;
    display: inline-block;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase; }

.all-news-block {
  background: #fff;
  padding: 30px 0; }
  .all-news-block__title {
    border-bottom: 1px solid #e8e8e8;
    padding: 15px 0 10px;
    text-align: center; }
    .all-news-block__title > span {
      border-bottom: 2px solid #0b7275;
      color: #000;
      display: inline-block;
      font-size: 21px;
      padding-bottom: 6px;
      position: relative;
      bottom: -1px; }
  .all-news-block .news-item {
    border-bottom: 1px solid #eee;
    position: relative;
    margin: 0 0 20px;
    padding-left: 85px;
    padding-bottom: 20px; }
    .all-news-block .news-item__date {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      padding: 5px 0 0;
      text-align: center;
      border: 1px solid #ddd;
      background: #f7f7f7;
      border-bottom: none; }
      .all-news-block .news-item__date .day {
        display: block;
        font-size: 24px;
        color: #444;
        font-weight: 300;
        padding: 0 5px 5px; }
      .all-news-block .news-item__date .month {
        display: block;
        font-size: 11px;
        padding: 2px 5px;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;
        margin: -1px -1px 0;
        background-color: #2c87f0;
        color: #fff; }
    .all-news-block .news-item__img > a {
      display: block; }
      .all-news-block .news-item__img > a > img {
        display: block;
        height: auto;
        max-width: 638px;
        width: 100%; }
    .all-news-block .news-item__info {
      padding: 20px 0; }
    .all-news-block .news-item__title {
      margin: 0 0 10px; }
      .all-news-block .news-item__title > a {
        color: #000;
        display: inline-block;
        font-size: 14px;
        font-weight: 600; }
    .all-news-block .news-item .readmore-link {
      background: #333;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      line-height: 19px;
      height: 32px;
      padding: 6px 13px;
      transition: 0.15s all ease; }

.no-mobile .all-news-block .news-item__title > a:hover {
  text-decoration: none; }

.no-mobile .all-news-block .news-item .readmore-link:hover {
  background: #555;
  text-decoration: none; }

/* Цвет ссылок */
/* Цвет ссылок при наведении */
/* Цвет текста */
/* Цвет фона */
/* Цвет фона верхней шапки */
/* Цвет фона верхней шапки на мобильных */
/* Цвет фона нижней шапки  */
/* Цвета ошибок */
/* Цвета ОК */
/* Borders */
/* Закругления */
/* Вертикальный градиент вверх */
/* Кнопки */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет          */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка кнопок          */
/* Тень у кнопок         */
/* Цвет текста кнопок    */
/* Закругления           */
/* Инпуты  */
/* Есть ли рамка */
/* Есть ли тень  */
/* Цвет текста   */
/* Цвет фона     */
/* Цвет рамки    */
/* Тень          */
/* Модули */
/* Цвет фона модулей                      */
/* Цвет фона заголовков модулей в мобайл  */
/* Цвет фона модулей                      */
/* Цвет текста в шапке                    */
/* Цвет текста в шапке                    */
/* Рамка                                  */
/*********************************************/
/* Горизонтальное меню */
/* Градиент да/нет? Если нет, используется цвет 1 */
/* Тень, да/нет          */
/* Рамка, да/нет         */
/* Закругления, да/нет   */
/* Цвет фона 1 (нижний)  */
/* Цвет фона 2 (верхний) */
/* Рамка                 */
/* Тень у кнопок         */
/* Цвет текста           */
/* Закругления           */
/* Цвет текста на ховер  */
/* Цвет фон 1 на текущий пункт */
/* Цвет фон 2 на текущий пункт */
/* Товар */
.clearfix:before, .container:before, .mobile-calc .calc-form:before, .pagination-wrapper:before, .payment-methods:before, .services-addit:before, .online-services__wrapper:before, .service-item__info:before, .calc-form .info-wrapper:before, .check-status-form:before, .transport-type:before, .calc-order-form .field:before,
.cargo-order-form .field:before, .calc-order-form .field.dim .fake-input:before,
.cargo-order-form .field.dim .fake-input:before, .apark-header:before, .header-info__top:before, .header-info__top > .header-info__phones:before, .header-info__bottom:before, .inner-block__wrapper:before,
.side-block__wrapper:before, .related-news .devider:before,
.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  content: " ";
  display: table; }


.clearfix:after,
.container:after,
.mobile-calc .calc-form:after,
.pagination-wrapper:after,
.payment-methods:after,
.services-addit:after,
.online-services__wrapper:after,
.service-item__info:after,
.calc-form .info-wrapper:after,
.check-status-form:after,
.transport-type:after,
.calc-order-form .field:after,
.cargo-order-form .field:after,
.calc-order-form .field.dim .fake-input:after,
.cargo-order-form .field.dim .fake-input:after,
.apark-header:after,
.header-info__top:after,
.header-info__top > .header-info__phones:after,
.header-info__bottom:after,
.inner-block__wrapper:after,
.side-block__wrapper:after,
.related-news .devider:after {
  clear: both; }

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

.news-cat-list-block,
.news-full-block {
  background: #fff;
  padding: 40px 0; }
  @media only screen and (max-width: 768px) {
    .news-cat-list-block,
    .news-full-block {
      padding: 1em 0; } }

@media only screen and (max-width: 768px) {
  .news-cat-list-block .col-4,
  .news-cat-list-block .col-8,
  .news-full-block .col-4,
  .news-full-block .col-8 {
    float: none;
    width: 100%; } }

.news-detail-gallery {
  margin: 30px 0 0; }
  .news-detail-gallery > .row {
    margin: 0 -10px; }

.news-gallery-item {
  float: left;
  padding: 0 10px;
  margin: 0 0 20px;
  width: 25%; }
  @media only screen and (max-width: 630px) {
    .news-gallery-item {
      width: 33.333333%; } }
  @media only screen and (max-width: 480px) {
    .news-gallery-item {
      width: 50%; } }
  .news-gallery-item__img > a {
    display: block; }
    .news-gallery-item__img > a > img {
      display: block;
      height: auto;
      max-width: 220px;
      width: 100%; }

.news-cat-list {
  padding: 0 1em 0 0; }
  .news-cat-list__item {
    padding: 0 0 20px;
    margin: 0 0 40px; }
  .news-cat-list__img {
    margin: 0 0 20px; }
    .news-cat-list__img > a {
      display: block; }
      .news-cat-list__img > a > img {
        display: block;
        height: auto;
        max-width: 680px;
        width: 100%; }
  .news-cat-list__title {
    margin: 0 0 10px; }
    .news-cat-list__title > a {
      color: #000;
      display: inline-block;
      font-size: 24px;
      line-height: 28px; }
  .news-cat-list__info {
    margin: 0 0 10px; }
    .news-cat-list__info .icon-user {
      display: inline-block;
      margin: 0 8px 0 0;
      height: 14px;
      position: relative;
      vertical-align: middle;
      width: 14px; }
      .news-cat-list__info .icon-user:before {
        content: "";
        color: #1a73e8;
        font: normal 18px/18px "FontAwesome";
        pointer-events: none;
        position: absolute;
        margin: -9px 0 0 -9px;
        top: 50%;
        left: 50%;
        z-index: 2;
        color: #777;
        font-size: 12px;
        line-height: 1;
        margin: -6px 0 0 -5px; }
    .news-cat-list__info .icon-date {
      display: inline-block;
      margin: 0 8px 0 0;
      height: 14px;
      position: relative;
      vertical-align: middle;
      width: 14px; }
      .news-cat-list__info .icon-date:before {
        content: "";
        color: #1a73e8;
        font: normal 18px/18px "FontAwesome";
        pointer-events: none;
        position: absolute;
        margin: -9px 0 0 -9px;
        top: 50%;
        left: 50%;
        z-index: 2;
        color: #777;
        font-size: 12px;
        line-height: 1;
        margin: -6px 0 0 -5px; }
    .news-cat-list__info .icon-folder {
      display: inline-block;
      margin: 0 8px 0 0;
      height: 14px;
      position: relative;
      vertical-align: middle;
      width: 14px; }
      .news-cat-list__info .icon-folder:before {
        content: "";
        color: #1a73e8;
        font: normal 18px/18px "FontAwesome";
        pointer-events: none;
        position: absolute;
        margin: -9px 0 0 -9px;
        top: 50%;
        left: 50%;
        z-index: 2;
        color: #777;
        font-size: 12px;
        line-height: 1;
        margin: -6px 0 0 -5px; }
  .news-cat-list__author, .news-cat-list__date {
    color: #777;
    font-size: 12px;
    margin: 0 20px 5px 0; }
    .news-cat-list__author > span, .news-cat-list__date > span {
      display: inline-block;
      vertical-align: middle; }
  .news-cat-list__text {
    font-size: 13px;
    margin: 0 0 20px; }
  .news-cat-list__cat > a {
    color: #777;
    display: inline-block;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle; }
  .news-cat-list__bottom > .button {
    background: #0b7275;
    border-color: #0b7275;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    height: 32px;
    line-height: 28px; }

.no-mobile .news-cat-list__bottom > .button:hover {
  background: #08a5a0;
  border-color: #08a5a0;
  color: #fff; }

.news-detail {
  padding: 0 1em 0 0; }
  @media only screen and (max-width: 768px) {
    .news-detail {
      padding: 0; } }
  .news-detail__img {
    margin: 0 0 20px; }
    .news-detail__img > img {
      display: block;
      height: auto;
      max-width: 680px;
      width: 100%; }
  .news-detail__title {
    color: #000;
    font-size: 24px;
    margin: 0 0 10px; }
  .news-detail__header {
    margin: 0 0 20px; }
  .news-detail__category {
    padding: 0 0 0 21px;
    position: relative; }
    .news-detail__category:before {
      content: "";
      color: #1a73e8;
      font: normal 18px/18px "FontAwesome";
      pointer-events: none;
      position: absolute;
      margin: -9px 0 0 -9px;
      top: 50%;
      left: 50%;
      z-index: 2;
      color: #777;
      font-size: 14px;
      line-height: 1;
      margin: 0;
      left: 0;
      top: 4px; }
    .news-detail__category > a {
      color: #777;
      text-transform: uppercase; }

.pop-news-posts {
  margin: 0 0 30px; }
  .pop-news-posts__title {
    margin: 0 0 20px; }
    .pop-news-posts__title > span {
      color: #000;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5; }

.pop-news-item {
  border-top: 1px solid #eee;
  padding: 10px 0; }
  .pop-news-item:last-child {
    border-bottom: 1px solid #eee; }
  .pop-news-item__img {
    float: left;
    width: 65px; }
    .pop-news-item__img > a {
      display: block; }
      .pop-news-item__img > a > img {
        display: block;
        height: auto;
        max-width: 100px;
        width: 100%; }
  .pop-news-item__info {
    float: right;
    padding: 0 0 0 15px;
    width: calc(100% - 65px); }
  .pop-news-item__title > a {
    color: #000;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    line-height: 21px; }
  .pop-news-item__date {
    font-size: 12px;
    opacity: .8; }

.no-mobile .pop-news-item__title > a:hover {
  color: #000;
  text-decoration: none; }

.recent-posts__title {
  margin: 0 0 20px; }
  .recent-posts__title > span {
    color: #000;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5; }

.recent-news-item {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 6px 0; }
  .recent-news-item__title {
    padding: 0 0 0 21px;
    position: relative; }
    .recent-news-item__title:before {
      content: "";
      color: #1a73e8;
      font: normal 18px/18px "FontAwesome";
      pointer-events: none;
      position: absolute;
      margin: -9px 0 0 -9px;
      top: 50%;
      left: 50%;
      z-index: 2;
      color: #999;
      font-size: 13px;
      margin: 0;
      top: 3px;
      left: 0;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
    .recent-news-item__title > a {
      color: #999;
      display: inline-block;
      line-height: 21px; }

.no-mobile .recent-news-item__title > a:hover {
  color: #999;
  text-decoration: underline; }

.related-news {
  padding: 30px 0 0; }
  .related-news .devider {
    display: none;
    pointer-events: none; }
    @media only screen and (max-width: 630px) {
      .related-news .devider {
        display: block; } }
  .related-news__title {
    margin: 0 0 20px;
    position: relative; }
    .related-news__title:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      border-bottom: solid 1px #eee;
      left: 0;
      right: 0;
      z-index: 0; }
    .related-news__title > span {
      background: #fff;
      color: #000;
      display: inline-block;
      font-size: 15px;
      font-weight: bold;
      position: relative;
      z-index: 2; }

.news-navigation {
  background: #fff;
  border-top: 1px solid #eee;
  padding: 20px 0; }
  .news-navigation .next-news {
    text-align: right; }
    .news-navigation .next-news > a {
      color: #0b7275;
      display: inline-block;
      line-height: 21px; }
      .news-navigation .next-news > a > .next-icon {
        display: inline-block;
        position: relative; }
        .news-navigation .next-news > a > .next-icon:before {
          content: "";
          color: #1a73e8;
          font: normal 18px/18px "FontAwesome";
          pointer-events: none;
          position: absolute;
          margin: -9px 0 0 -9px;
          top: 50%;
          left: 50%;
          z-index: 2;
          color: #0b7275;
          font-size: 13px;
          position: static;
          margin: 0 0 0 5px; }
  .news-navigation .prev-news > a {
    color: #0b7275;
    display: inline-block;
    line-height: 21px; }
    .news-navigation .prev-news > a > .prev-icon {
      display: inline-block;
      position: relative; }
      .news-navigation .prev-news > a > .prev-icon:before {
        content: "";
        color: #1a73e8;
        font: normal 18px/18px "FontAwesome";
        pointer-events: none;
        position: absolute;
        margin: -9px 0 0 -9px;
        top: 50%;
        left: 50%;
        z-index: 2;
        color: #0b7275;
        font-size: 13px;
        position: static;
        margin: 0 5px 0 0; }
  @media only screen and (max-width: 768px) {
    .news-navigation .next-news {
      margin: 10px 0 0; } }

.no-mobile .news-navigation a:hover {
  color: #0b7275;
  text-decoration: underline; }

.rel-news-item {
  float: left;
  padding: 0 10px;
  margin: 0 0 20px;
  width: 25%; }
  @media only screen and (max-width: 630px) {
    .rel-news-item {
      width: 50%; } }
  @media only screen and (max-width: 480px) {
    .rel-news-item {
      width: 100%; } }
  .rel-news-item__img {
    margin: 0 0 14px; }
    .rel-news-item__img > a {
      display: block; }
      .rel-news-item__img > a > img {
        display: block;
        height: auto;
        max-width: 300px;
        width: 100%; }
  .rel-news-item__title {
    margin: 0 0 5px; }
    .rel-news-item__title > a {
      color: #000;
      display: inline-block;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px; }
  .rel-news-item__text {
    font-size: 12px;
    line-height: 20px; }
  .rel-news-item__wrapper > .row {
    margin: 0 -10px; }

/*@import "reg/reg";
@import "reg/profile";
*/
