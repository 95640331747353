$containerWidth: 1208px;
$mobileFixedHeaderHeight: 44px;
$desktopFixedHeaderHeight: 30px;

$isAdaptive: true;

$fontName: "Arial";
$fontSizeBase: 14px;

$white: #fff;
$black: #212429;

$linkColor: #1a73e8;             /* Цвет ссылок */
$linkColor--hover: #1175e9;      /* Цвет ссылок при наведении */
$linkColor--hover2: #1175e9;

$webkitHighlightTapColor: rgba(0,0,0, 0.15);


$textColor: #6e6e6e;                           /* Цвет текста */
$grey: #686868;
$haki: #496040;
$pink: #d9017f;
$green: #6dae76;
$lightGreen: #9ed178;
$violet: #cf98de;
$sand: #f6846a;
$transSand: rgba(246,132,106,0.8);
$lightGrey: #f2f2f2;
$transGrey: rgba(255,255,255,0.8);
$blue: #67b0d6;
$lightBlue: #bae4f9;

$iconColor: $linkColor--hover;

$bgColor: #fff;                             /* Цвет фона */
$headerTopBgColor: #13151b;  /* Цвет фона верхней шапки */
$headerTopBgColorMobile: #fff;              /* Цвет фона верхней шапки на мобильных */
$headerBottomBgColor: rgba(21, 23, 29, 0.65);                 /* Цвет фона нижней шапки  */


$footerTopBgColor: #212429;
$footerBottomBgColor: #222;
$footerLinkColor: #fff;

/* Цвета ошибок */
$colorError: rgba(255, 10, 20, 0.8);

/* Цвета ОК */
$colorOk: rgba(73, 208, 0, 0.8);


/* Borders */
$borderColor: #ccc;
$borderRadius: 0;               /* Закругления */
$photoBorderRadius: 0;
$border: 1px solid $borderColor;

/* Вертикальный градиент вверх */
@mixin gradient($startColor, $endColor, $gradiend) {

  @if($gradiend == false) {
    background: $startColor;
  }
  @else {
    background: $startColor;
    background: linear-gradient(to top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
  }
}


/* Кнопки */
$isButtonGradient: false;                 /* Градиент да/нет? Если нет, используется цвет 1 */
$isButtonShadow: false;                   /* Тень, да/нет          */
$isButtonBorder: false;                   /* Рамка, да/нет          */

$buttonBgColor1: $haki;                 /* Цвет фона 1 (нижний)  */
$buttonBgColor2: $haki;                 /* Цвет фона 2 (верхний) */
$buttonBorder: 2px solid $black;          /* Рамка кнопок          */
$buttonShadow: 1px 2px 3px 0 #a8a8a8;     /* Тень у кнопок         */
$buttonColor: #fff;                       /* Цвет текста кнопок    */
$buttonBorderRadius: 3px;                   /* Закругления           */
$buttonBorderColor: $black;


// наведение
$buttonBgColor1--hover: #fff;
$buttonBgColor2--hover: #fff;
$buttonColor--hover: #2a2a2a;
$buttonBorderColor--hover: #2a2a2a;
$buttonShadow--hover: none;


// актив
$buttonBgColor1--active: #d16961;
$buttonBgColor2--active: #d16961;
$buttonColor--active: #fff;
$buttonBorderColor--active: #1b9fae;
$buttonShadow--active: none;


/* Инпуты  */
$isInputBorder: true;                    /* Есть ли рамка */
$isInputShadow: false;                   /* Есть ли тень  */

$inputColor: #686868;                    /* Цвет текста   */
$inputBg: #e6e6e6;                       /* Цвет фона     */
$inputBorderColor: #e6e6e6;              /* Цвет рамки    */
$inputShadow: inset 0 2px 7px 0 #a6a6a7; /* Тень          */


@mixin input() {
  background: $inputBg;
  border-radius: 3px;
  border: 1px solid $inputBorderColor;
  color: $inputColor;
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 22px;
  padding: 1em 1em 0 1em;
  outline: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @content;
}



/* Модули */
$moduleBg: #fff;                            /* Цвет фона модулей                      */
$moduleMobileHeaderBg: #c1c1c1;             /* Цвет фона заголовков модулей в мобайл  */
$moduleHeaderBg: $linkColor;                /* Цвет фона модулей                      */
$moduleHeaderColor: #fff;                   /* Цвет текста в шапке                    */
$moduleMobileHeaderColor: #2c2c2c;          /* Цвет текста в шапке                    */
$moduleBorder: 0;                           /* Рамка                                  */
$module_top__box-shadow: 0px 2px 3px #808080;

@mixin module($isMobile) {

  @if($isMobile == false) {
    background: $moduleBg;
  }
  
  margin: 1em 0 2em;

  @if($moduleBorder != 0) {
    border: $moduleBorder;
  }


  @content;
}



/*********************************************/
/* Горизонтальное меню */
$isTopMenuGradient: false;              /* Градиент да/нет? Если нет, используется цвет 1 */
$isTopMenuShadow: false;                /* Тень, да/нет          */
$isTopMenuBorder: false;                /* Рамка, да/нет         */
$isTopMenuBorderRounded: false;         /* Закругления, да/нет   */

$topMenuBgColor1: #fff;              /* Цвет фона 1 (нижний)  */
$topMenuBgColor2: #fff;              /* Цвет фона 2 (верхний) */
$topMenuBorder: 1px solid #aaa;         /* Рамка                 */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;  /* Тень у кнопок         */
$topMenuColor: #fff;                    /* Цвет текста           */
$topMenuBorderRadius: 0;                /* Закругления           */
$topMenuColor--hover: #edac0a;          /* Цвет текста на ховер  */

$topMenuBgColor1--selected: #fff;    /* Цвет фон 1 на текущий пункт */
$topMenuBgColor2--selected: #fff;    /* Цвет фон 2 на текущий пункт */

@mixin navtop() {

  @content;
}


@mixin fontawesome($n) {

  &:before {
    @if($n != '') {
      content: $n;
    }
    @else {
      content: '';
    }

    color: $linkColor;
    font: normal 18px/18px "FontAwesome";
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -9px;
    top: 50%;
    left: 50%;
    z-index: 2;

    @content;
  }
}



@mixin sideBorder($side: 'left') {
  &:after {
    background: $headerTopBgColorMobile * 1.2;
    border-right: 1px solid $headerTopBgColorMobile * 0.8;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    #{$side}: 0;
    width: 1px;

    @content;
  }
}


/* Товар */
$prodTitleColor: $linkColor;
$prodTextColor: $textColor;
$prodSecondColor: #8f8f8f;
$prodCurrentPriceColor: $black;
$prodOldPriceColor: #a4a4a4;



.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

