@import "../vars";


nav {
	display: block;
	text-align: right;

	& > ul {
		display: inline-block;
		list-style: none;
		margin: -1px 0 0;
		width: auto;

		& > li {
			display: table-cell;
			padding: 0 1em;

			& > span {
				color: #fff;
				display: block;
				font-size: 15px;
				padding: 0;
				text-align: center;
				vertical-align: middle;
				white-space: nowrap;
			}

			& > a {
				color: #fff;
				display: block;
				font-size: 15px;
				padding: 0;
				text-decoration: none;
				text-align: center;
				vertical-align: middle;
				white-space: nowrap;
			}

			&.active {
				cursor: default;

				& > span:not(.arrow) {
					color: #fff;
					padding: 0 20px 0 0;
					position: relative;
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
		}
	}


	/* Всплывающее меню */
	.sub-menu {
        background: #fff;
		display: table;
		list-style: none;
		padding: 3px 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 2;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		width: auto;

		& > li {
			display: block;
			padding: 6px 12px;

			.arrow {
				display: none;
			}

			& > span {
				color: $linkColor;
				display: block;
				font-size: 14px;
				line-height: 16px;
				white-space: nowrap;
				user-select: none;
			}

			& > a {
				color: #000;
				display: block;
				font-size: 14px;
				line-height: 16px;
				white-space: nowrap;
				user-select: none;
			}
		}
	}
}

/*
.mobile {
	nav {
		display: block;
		overflow-x: hidden;
		overflow-y: auto;
		height: calc(100vh - 40px);
		padding: 0 0 80px;
		-webkit-overflow-scrolling: touch;

		& > ul {
			display: block;
			padding: 0 20px;

			& > li {
				display: block;
				padding: 0 20px 0 10px;

				& > a {
					font-size: 15px;
					line-height: 3;
					padding: 0 0 0 10px;
					text-align: left;
				}

				& > span:not(.arrow) {
					font-size: 15px;
					line-height: 3;
					padding: 0 0 0 10px;
					text-align: left;
				}

				&.has_child {
					padding: 0 40px 0 10px;
					position: relative;
				}

				.arrow {
					height: 40px;
					width: 40px;

					&:before {
						font-size: 20px;
					}
				}

			}
		}
	}
}
*/



$menuHideDelay: 0.3s;

.no-mobile {
	nav {

		& > ul {

			& > li {

				& > a {
					transition: 0.2s ease-out;
				}

				& > a {
					&:hover {
						color: #fff;
						text-decoration: none;
					}
				}
			}
		}
	}
}
