@import "../vars";
@import "../system/icon";


.mobile {
	.menu-button {
		display: block;
	}
}


.header-top {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 51;

	&:before {
		content: '';
		background: $headerTopBgColor;
		display: block;
		left: 0;
		right: 0;
		position: absolute;
		height: 56px;
		top: 0;
		z-index: 2;

		@include media(md) {
			bottom: 0;
			height: 33px;
		}
	}

	&:after {
		content: '';
		background: $headerBottomBgColor;
		display: block;
		left: 0;
		right: 0;
		position: absolute;
		top: 56px;
		bottom: 0;
		z-index: 0;

		@include media(md) {
			top: 33px;
		}
	}

	& > .container {
		position: relative;
		height: 100%;
	}

	&__menu-top {
		display: none;

		@include media(950px) {
			display: block;
		}
	}

	&__menu {
		float: left;
		padding: 4px 0 0;
		width: calc(100% - 190px - 153px - 62px);

		@include media(xlg) {
			width: calc(100% - 150px - 153px - 62px);
		}

		@include media(1099px) {
			width: calc(100% - 150px - 62px);
		}

		@include media(950px) {
			background: $linkColor;
			position: fixed;
			padding: 0;
			top: 0;
			right: 0;
			bottom: 0;
			transform: translateX(100%);
			transition: 0.2s ease-in-out;
			width: 320px;
			z-index: 1000;

			&.open {
				transform: translateX(0);
			}
		}
	}

	.search-icon {
		cursor: pointer;
		padding: 4px;
		-webkit-tap-highlight-color: $webkitHighlightTapColor;
		
		& > svg {
			display: block;
			height: auto;
			max-width: 24px;
			width: 100%;
		}
	}

	@include media(md) {
		position: fixed;
		padding: 33px 0 0;
	}
}


.no-mobile.header-fixed {

	@include media-min(md) {
		.header-logo__wrapper {
			& > svg {
				max-width: 98px;
			}

			& > a {
				& > svg {
					max-width: 98px;
				}
			}
		}


		.header-top {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1000;

			&:after {
				top: 0;
			}

			&:before {
				visibility: hidden;
			}
		}

		.header-info__top {
			display: none;
		}
	}
}


.no-index {
	.header-top {
		&:after {
			background: rgba(21, 23, 29, 0.95)
		}
	}
}


#icon-search {
	cursor: pointer;
}


.header-logo {
	float: left;
	overflow: hidden;
	width: 28%;

	&__wrapper {
		background: #fff;
		left: 1em;
		padding: 13px 5%;
		position: absolute;
		top: 0;
		bottom: 0;
		text-align: center;
		width: 28%;
		z-index: 2;

		@include media(md) {
			left: 0;
			width: 25%;
		}

		@include media(580px) {
			width: 120px;
		}

		& > a {
			display: block;

			& > svg {
				display: inline-block;
				height: auto;
				max-width: 195px;
				transition: width 0.2s ease-out;
				width: 100%;

				@include media(md) {
					max-width: 108px;
				}

				@include media(580px) {
					max-width: 78px;
				}
			}

		}

		& > svg {
			display: inline-block;
			height: auto;
			max-width: 195px;
			transition: 0.1s ease-out;
			width: 100%;

			@include media(md) {
				max-width: 108px;
			}

			@include media(580px) {
				max-width: 85px;
			}
		}
	}

	@include media(xlg) {
		width: 22%;

		&__wrapper {
			padding: 13px 2%;
			width: 22%;
		}
	}

	@include media(lg) {
		&__wrapper {
			padding: 13px 1em;
		}
	}

	@include media(md) {
		width: 25%;

		&__wrapper {
			padding: 6px 1em;
		}
	}

	@include media(580px) {
		width: 120px;

		&__wrapper {
			padding: 4px 1em;
		}
	}
}

.flexbox {
	.header-logo__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


.header-info {
	float: right;
	padding: 0 1em;
	position: relative;
	width: 72%;
	z-index: 2;

	@include media(xlg) {
		width: 78%;
	}

	@include media(md) {
		width: 75%;
	}

	@include media(580px) {
		width: calc(100% - 120px);
	}


	&__top {
		padding: 13px 0 10px;

		@extend .clearfix;

		& > nav {
			float: left;
			padding: 0 1em;
			width: calc(100% - 273px - 99px);

			@include media(lg) {
				& > ul {
					display: none;
				}
			}
		}

		& > .header-info__phones {
			float: left;
			padding: 0 0.5em 0 0;
			width: 273px;

			@extend .clearfix;

			& > span {
				display: block;
				float: left;
				width: 50%;

				& > a {
					color: #fff;
					display: inline-block;
					font-size: 15px;
				}
				
				&:last-child {
					padding: 0 0 0 0.25em;
				}

				&:first-child {
					padding: 0 0.25em 0 0;
					position: relative;

					&:after {
						color: #fff;
						content: '|';
						font-size: 14px;
						position: absolute;
						right: 2px;
						top: 0;
					}
				}

				@include media(xs) {

					&:last-child {
						display: none;
					}

					&:first-child {
						&:after {
							display: none;
						}
					}
				}
			}

			@include media(lg) {
				text-align: right;
				width: calc(100% - 99px);

				& > span {
					display: inline-block;
					float: none;
					width: auto;

					&:first-child {
						padding: 0 13px 0 0;
					}

					& > a {
						color: #fff;
						display: inline-block;
						font-size: 15px;
					}
				}
			}

		}

		& > .header-info__social {
			float: right;
			width: 99px;
		}

		@include media(md) {
			padding: 3px 1em 0;
			right: 0;
			position: fixed;
			top: 0;
			left: 0;
		}
	}

	&__bottom {
		@extend .clearfix;

		position: relative;

		& > .header-info__mail {
			float: left;
			padding: 17px 1em 17px;
			text-align: right;
			width: calc(100% - 208px - 122px - 198px);

			& > a {
				color: #fff;
				display: inline-block;
				font-size: 15px;
				text-decoration: underline;
				white-space: nowrap;

				@include media(444px) {
					display: none;
				}
			}

			@include media(lg) {
				padding: 17px 1.5em 17px 1em;
				width: calc(100% - 40px - 122px - 59px);
			}

			@include media(580px) {
				padding: 7px 1em 10px 0;
				width: calc(100% - 32px - 68px - 32px);
			}
		}
	}

	&__ordercall {
		float: left;
		margin: 10px 0 0;
		padding: 0 40px 0 0;
		position: relative;
		width: 208px;

		@include media(lg) {
			padding: 0;
			margin: 10px 19px 0 0;
			width: 40px;
		}

		@include media(580px) {
			margin: 0;
			padding: 3px 0 5px;
			width: 32px;
		}

		& > .button {
			border-color: transparent;
			position: static;

			& > span {
				position: relative;
				text-transform: none;

				&:before {
					border-top: 1px dotted #fff;
					content: '';
					display: block;
					height: 1px;
					position: absolute;
					bottom: 10px;
					left: 1em;
					right: 1em;
					z-index: 1;
				}
			}

			& > .icon {
				font-style: normal;
				display: block;
				height: 40px;
				padding: 6px;
				position: absolute;
				top: 0;
				right: 20px;
				margin: 0;
				user-select: none;
				width: 40px;

				& > svg {
					display: block;
					height: auto;
					max-width: 28px;
					width: 100%;
				}

				@include media(580px) {
					height: 32px;
					width: 32px;
				}
			}

			@include media(lg) {
				& > .icon {
					position: static;
				}

				& > span {
					display: none;
				}
			}
			@include media(580px) {
				display: block;
			}
		}
	}

	&__icons {
		float: left;
		padding: 10px 0;
		width: 122px;

		& > .icon {
			display: inline-block;
			height: 40px;
			padding: 6px;
			margin: 0 19px 0 0;
			user-select: none;
			width: 40px;

			@include media(580px) {
				margin: 0;
				height: 32px;
				width: 32px;
			}

			&.search {
				padding: 7px;
			}

			& > svg {
				display: block;
				height: auto;
				max-width: 28px;
				width: 100%;
			}
		}


		@include media(580px) {
			padding: 5px 0 6px;
			width: 68px;
		}
	}

	&__social {

		& > .icon {
			display: inline-block;
			height: 30px;
			padding: 5px;
			width: 30px;
			vertical-align: middle;

			& > a {
				display: block;

				& > svg {
					display: block;
					height: auto;
					max-width: 24px;
					width: 100%;
				}
			}
		}
	}

	&__auth {
		cursor: pointer;
		float: right;
		margin: 11px 0 0;
		padding: 7px 20px 12px 61px;
		position: relative;
		width: 198px;

		& > .icon {
			position: absolute;
			top: 0;
			left: 0;
			height: 40px;
			padding: 6px;
			width: 40px;

			& > a {
				& > svg {
					display: block;
					height: auto;
					max-width: 28px;
					width: 100%;
				}
			}

			& > svg {
				display: block;
				height: auto;
				max-width: 28px;
				width: 100%;
			}

			@include media(580px) {
				height: 32px;
				width: 32px;
			}
		}

		& > a {
			color: #fff;
			display: inline-block;
			position: relative;
			
			&:before {
				border-top: 1px dotted #fff;
				content: '';
				display: block;
				height: 1px;
				position: absolute;
				bottom: 3px;
				left: 0;
				right: 0;
				z-index: 1;
			}
		}

		&:after {
			color: #fff;
			content: "\f0d7";
			font: normal 16px/16px "FontAwesome";
			pointer-events: none;
			position: absolute;
			margin: -10px 0 0 -9px;
			top: 50%;
			right: 10px;
			left: auto;
		}

		@include media(lg) {
			padding: 0;
			width: 40px;

			& > .icon {
				position: static;
			}

			&:after {
				display: none;
			}

			& > a {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				text-indent: -9999px;

				&:before {
					display: none;
				}
			}
		}

		@include media(580px) {
			margin: 4px 0 0;
			width: 32px;
		}
	}

	&__auth-title {
		color: #fff;
		display: inline-block;
		position: relative;
		user-select: none;
		white-space: nowrap;

		&:before {
			border-top: 1px dotted #fff;
			content: '';
			display: block;
			height: 1px;
			position: absolute;
			bottom: 3px;
			left: 0;
			right: 0;
			z-index: 1;
		}

		@include media(lg) {
			display: none;
		}
	}
}


.no-mobile {

	.header-info__auth {
		& > a {
			&:hover {
				color: #fff;
			}
		}
	}
}

/*
.header-search {
	float: right;
	padding: 0 1em;
	position: relative;
	width: 62px;

	& > .search-icon {
		height: 32px;
		width: 32px;
	}
}
*/
