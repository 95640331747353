.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    position: relative;
    min-height: 1px;
    outline: none;

    & > a {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}


.slick-arrow {
    background: none;
    border-radius: 50%;
    border: 1px solid $green;
    cursor: pointer;
    display: block;
    height: 44px;
    line-height: 41px;
    padding: 10px;
    position: absolute;
    margin: -22px 0 0;
    outline: none;
    top: 40%;
    width: 44px;
    z-index: 1;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;

    & > span {
        display: block;
        height: 19px;
        width: 19px;
        position: relative;

        &:before {
            background: $green;
            content: '';
            display: block;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
        }
    }
}


.slick-vertical {
    .slick-arrow {
        left: 50%;
        margin: 0 0 0 -14px;
        right: auto;
    }

    .slick-prev {
        top: auto;
        bottom: -30px;

        &:before {
            margin: -8px 0 0 -3px;
            transform: rotate(-90deg);
        }
    }

    .slick-next {
        top: -30px;

        &:before {
            margin: -9px 0 0 -3px;
            transform: rotate(-90deg);
        }
    }
}


.slick-prev {
    left: 0;

    &:before {
        background: $green;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        left: 9px;
        top: 24px;
        transform: rotate(45deg);
        width: 9px;
    }

    &:after {
        background: $green;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        left: 9px;
        top: 18px;
        transform: rotate(-45deg);
        width: 9px;
    }
}

.slick-next {
    right: 0;

    &:before {
        background: $green;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        right: 10px;
        top: 24px;
        transform: rotate(-45deg);
        width: 9px;
    }

    &:after {
        background: $green;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        right: 10px;
        top: 18px;
        transform: rotate(45deg);
        width: 9px;
    }
}


.slick-dots {
    display: block;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;

    & > li {
        display: inline-block;
        height: auto;
        width: auto;

        & > button {
            background: none;
            border: none;
            border-radius: 50%;
            cursor: default;
            outline: none;
            position: relative;
            height: 48px;
            text-indent: -9999px;
            width: 48px;

            &:before {
                border-radius: 50%;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                border: none;
                background: #d1d1d1;
                box-shadow: none;
                margin: -4px 0 0 -4px;
                height: 8px;
                width: 8px;
            }
        }

        &:not(.slick-active) {
            & > button {
                cursor: pointer;
            }
        }

        &.slick-active {

            & > button {
                -webkit-tap-highlight-color: transparent;

                &:before {
                    background: #1067d1;
                    border: 4px solid #1067d1;
                    margin: -8px 0 0 -8px;
                }
            }
        }
    }
}

.no-mobile {

    .slick-prev {
        left: -46px;
        transition: 0.2s ease-out;

        &:hover {
            background: $haki;

            &:after,
            &:before {
                background: #fff;
            }

            & > span {
                &:before {
                    background: #fff;
                }
            }
        }
    }

    .slick-next {
        right: -46px;
        transition: 0.2s ease-out;

        &:hover {
            background: $haki;

            &:after,
            &:before {
                background: #fff;
            }

            & > span {
                &:before {
                    background: #fff;
                }
            }
        }
    }

    .services-slider {
        overflow: hidden;

        &:hover {
            .slick-prev {
                left: 0;
            }
            .slick-next {
                right: 0;
            }
        }
    }


}

