@import "vars";

input::-ms-clear {
  display: none;
}

html {

}

body {
  background: $bgColor;
  font: normal #{$fontSizeBase}/1.8 $fontName, "Helvetica", sans-serif;
  color: $textColor;
  opacity: 0;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
}

.loaded {
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: auto;
    opacity: 1;
  }
}


table {
  border-collapse: collapse;
}


ul {
  margin-top: 0;
  margin-bottom: 0;
}


.content {
  ol,
  ul {
    padding: 0 0 1em 22px;

    & > li {
      //color: $black;
    }
  }
}


h1 {
  color: $white;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}


h2 {
  color: $black;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.333;

  @include media(md) {
    font-size: 24px;
  }

  @include media(sm) {
    font-size: 18px;
  }
}


h3 {
  color: $black;
  font-size: 24px;
  line-height: 1.333;

  @include media(md) {
    font-size: 21px;
  }

  @include media(sm) {
    font-size: 18px;
  }
}


h2, h3, h4, h5, h6, p {
  padding: 0 0 1em;
}


h4, h5, h6 {
  color: $black;
}


a {
  color: $linkColor;
  text-decoration: none;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
}



.no-mobile {
  a {
    transition: color 0.3s ease-out;
  }

  a:hover {
    color: $linkColor--hover;
  }
}

.alert {
  color: red;
}


.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center;
}

.noscript_alert > span {
  font-weight: bold;
  color: red;
}


fieldset {
  border: none;
  padding: 0;
  margin: 0;
  
  & > legend {
    color: $black;
    font-weight: bold;
    font-size: 18px;
  }
}



.breadcrumbs {
  display: block;
  padding: 24px 0;

  & > .container {
    & > ol {
      display: block;
      list-style: none;

      & > li {
        display: inline-block;
        position: relative;

        &:not(:last-child) {
          padding: 0 2em 0 0;
        }

        & > span {
          color: $textColor;
          font-size: 14px;
        }

        & > a {

          & > span {
            color: $textColor;
            font-size: 14px;
            transition: 0.2s ease-out;

            &:after {
              content: '/';
              position: absolute;
              right: 1em;
              top: 0;
            }
          }
        }
      }
    }
  }
}


.no-mobile {
  .breadcrumbs {
    & > .container {
      & > ol {
        & > li {
          & > a {
            &:hover {
              text-decoration: none;

              & > span {
                color: $linkColor;

                &:after {
                  color: $textColor;
                }
              }
            }
          }
        }
      }
    }
  }
}


.content {
  position: relative;

  img {
    height: auto;
    max-width: 800px;

    @include media(lg) {
      max-width: 500px;
    }

    @include media(md) {
      max-width: 100%;
    }

    @include media(xmd) {
      max-width: 300px;
    }

    @include media(400px) {
      max-width: 100%;
    }
  }
}

.button {
  border: 2px solid $haki;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  pointer-events: all;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;


  & > span {
    background: $buttonBgColor1;
    display: block;
    color: $buttonColor;
    font-size: 15px;
    height: 38px;
    padding: 0 1em;
    line-height: 37px;
    transition: all 0.2s ease-out;
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;

    & > span {
      background: #999;
    }
  }

  &.--trans {
    & > span {
      background: transparent;
      border-color: $white;
    }
  }

  &.--wt {

    & > span {
      background: #fff;
      color: $haki;
    }
  }
}


.no-mobile {
  .button {
    &:hover {
      &:after {
        width: 100%;
      }

      &:before {
        height: 100%;
      }

      & > span {
        &:after {
          width: 100%;
        }

        &:before {
          height: 100%;
        }
      }
    }
  }
}


.cookie-warning {
  background: #fff;
  display: none;

  &.show {
    display: block;
  }

  &__text {
    float: left;
    padding: 17px 0;
    width: calc(100% - 79px);
  }

  &__btn {
    float: right;
    padding: 8px 0;
    width: 79px;

    & > .button {
      display: block;
    }
  }
}

.mobile {
  .cookie-warning {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
  }
}


.mobile-calc {
  background: rgba(20,22,28,1);

  .ya-map {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 0 !important;
  }

  .slider-layer__calc {
    background: transparent;
    border-right: 1px solid #333;
    float: left;
    padding: 1em 1em 1.5em;
    margin: 0;
    width: 70%;

    @include media(md) {
      border-right: none;
      border-bottom: 1px solid #333;
      width: 100%;
    }
  }

  .slider-layer__check-status {
    background: transparent;
    float: right;
    width: 30%;

    @include media(lg) {
      display: block;
    }

    @include media(md) {
      width: 100%;
    }
  }

  .calc-form {
    @extend .clearfix;

    & > .field {
      float: left;

      &:nth-child(1),
      &:nth-child(3) {
        padding: 0 1em 0 0;
        width: 55%;

        @include media(md) {
          padding: 0;
          width: 50%;
        }

        @include media(583px) {
          width: 100%;
        }
      }

      &:nth-child(2),
      &:nth-child(4) {
        padding: 0 0 0 1em;
        width: 45%;

        @include media(md) {
          width: 50%;
        }

        @include media(583px) {
          padding: 0;
          width: 100%;
        }
      }

      &:nth-child(2) {
        .form__label {
          left: 30px;
        }
      }
    }
  }
}


.yashare_text {
  text-align: right;
}

html.ie9 * {
  border-radius: 0 !important;
}

body {
  & > .wrapper {
    height: 100vh;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
  }
}


.loaded {
  body {
    & > .wrapper {
      height: auto;
      overflow: hidden;
      visibility: visible;
    }
  }
}

.locked {
  .overlay {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0, 0, 0, 1);
    visibility: visible;
  }
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  position: relative;
  vertical-align: middle;
}


.overlay {
  position: fixed;
  background: rgba(0,0,0,0.85);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 50;
}


.loader {
  background: url('/assets/images/loader.svg') 0 0 no-repeat;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -34px 0 0 -34px;
  width: 68px;
  height: 68px;
  z-index: 200;
}


.pagination {
  margin: 4em 0 3em;
  text-align: center;
}

.pagination-title {
  color: $textColor;
  cursor: default;
  display: inline-block;
  font-size: 13px;
  height: 26px;
  margin: 0 1em 0 0;
  vertical-align: middle;
}

.pagination-wrapper {
  color: $textColor;
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;

  @extend .clearfix;


  & > span,
  & > a {
    display: block;
    float: left;
    height: 30px;
    line-height: 28px;
    margin: 0 6px 0 0;
    text-align: center;
    width: 32px;
  }

  & > .empty {
    background: #e7e7e8;
    border: none;
  }

  & > a {
    &:not(.show-all) {
      border-radius: 3px;
      background: #e7e7e8;
      color: #3d3d3d;
    }

    &.show-all {
      margin: 0;
      width: auto;
    }

    &.active {
      color: $textColor;
      pointer-events: none;
      text-decoration: none;

      &:not(.show-all) {
        border-radius: 3px;
        background: #4d4d4e;
        color: $white;
        cursor: default;
      }

      &.show-all {
        cursor: default;
      }
    }
  }
  
  & > span {
    border: 1px solid $linkColor;
    border-radius: 3px;
    background: $linkColor;
    color: #3d3d3d;
    cursor: default;
  }
}


.no-mobile {
  .pagination-wrapper {

    & > a {
      &:not(.show-all) {
        &:hover {
          background: $linkColor;
          color: $white;
          transition: 0.3s ease-out;
        }
      }
    }
  }
}


.hidden {
  display: none;
}


.h1title,
.h2title {
  color: $black;
  font-size: 30px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 1em;
  padding: 0 0 1em;
  position: relative;
  text-transform: uppercase;
  text-align: center;

  &:after {
    background: $haki;
    content: '';
    position: absolute;
    height: 2px;
    left: 50%;
    bottom: 21px;
    margin: 0 0 0 -19px;
    width: 39px;
  }


  @include media(xlg) {
    font-size: 28px;
  }

  @include media(md) {
    font-size: 24px;

    &:after {
      bottom: 14px;
    }
  }

  @include media(sm) {
    font-size: 21px;

    &:after {
      bottom: 8px;
    }
  }
}


input::-webkit-input-placeholder {
  color: #bbbbbb;
  font-size: 15px;
}

input::-moz-placeholder {
  color: #bbbbbb;
  font-size: 15px;
}

input:-moz-placeholder {
  color: #bbbbbb;
  font-size: 15px;
}

input:-ms-input-placeholder {
  color: #bbbbbb;
  font-size: 15px;
}


@include media(wide) {

  input::-webkit-input-placeholder {
    font-size: 14px;
  }

  input::-moz-placeholder {
    font-size: 14px;
  }

  input:-moz-placeholder {
    font-size: 14px;
  }

  input:-ms-input-placeholder {
    font-size: 14px;
  }
}


.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content-bottom {
  margin: 2em 0 0;
}

.red__star,
.red-star {
  color: $colorError;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
}


.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 6px 0 0;

  & + label {
    cursor: pointer;
  }

  &:not(checked) {
    position: absolute;
    visibility: hidden;

    &  + label {
      position: relative;
      padding: 0 0 0 35px;

      &:before {
        border-radius: 50%;
        background: #e6e6e6;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 4px;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        border-radius: 50%;
        background: #688557;
        opacity: 0;
        transition: background .2s ease;
      }
    }
  }

  &:checked {
    & + label {
      &:after {
        opacity: 1;
      }
    }
  }
}


.checkbox {

  & + label {
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
  }

  &:focus {
    & + label {
      &:before {
        box-shadow: 0 0 3px 0 $haki;
      }
    }
  }

  &:not(checked) {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      padding: 2px 0 0 29px;

      &:before {
        background: #e6e6e6;
        border-radius: 3px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }

      &:after {
        content: '\f00c';
        color: #688557;
        font: normal 15px/1 FontAwesome;
        position: absolute;
        top: 2px;
        left: 3px;
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:checked {

    & + label:after {
      opacity: 1;
      transition: opacity 0.2s ease;
      visibility: visible;
    }
  }
}



.select-wrapper {
  position: relative;

  &:before {
    content: '\f0d7';
    font: normal 12px/1 FontAwesome;
    color: #000;
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -6px;
  }

  & > select {
    background: #e6e6e6;
    border: 1px solid $inputBorderColor;
    border-radius: 2px;
    color: #666;
    cursor: pointer;
    display: block;
    font-size: 13px;
    height: 35px;
    outline: none;
    padding: 6px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
  }
}

.button-up {
  background: $haki;
  border-radius: 50%;
  cursor: pointer;
  padding: 13px 19px;
  position: fixed;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  height: 62px;
  width: 62px;
  text-align: center;
  transition: 0.2s ease-out;
  visibility: hidden;
  user-select: none;
  z-index: 1000;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  @include media(xlg) {
    padding: 10px 13px;
    height: 42px;
    width: 42px;
  }

  & > svg {
      display: block;
      height: auto;
      max-width: 24px;
      width: 100%;
  }
}

/*
.no-mobile {
  .button-up {
    &:hover {
    }
  }
}
*/

.social-repost {
  text-align: right;
}


.payment-methods {
  @extend .clearfix;

  margin: 0 0 2em;

  &__item {
    float: left;
    padding: 6em 1em 0 1em;
    position: relative;
    text-align: center;
    width: 33.333%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -3em;
      height: 5em;
      width: 6em;
    }

    & > span {
      display: inline-block;
      line-height: 1.2;
    }

    &.bank {
      &:before {
        background: url(/assets/images/banktransfer.svg) 0 0 no-repeat;
        background-size: contain;
      }
    }

    &.card {
      &:before {
        background: url(/assets/images/creditcard.svg) 0 0 no-repeat;
        background-size: contain;
      }
    }

    &.nalich {

      &:before {
        background: url(/assets/images/nalik.svg) 0 0 no-repeat;
        background-size: contain;
      }
    }

    @include media(sm) {
      width: 100%;
    }
  }
}

.error-page {
  margin: 0 0 5em;

  &-pic {
    text-align: center;
  }

  &-desc {
    margin: 3em auto 0;
    max-width: 615px;

    & > ol {
      margin: 0 0 2em;
    }
  }

  &-desc__title {
    font-size: 24px;
    color: #111;
    line-height: 1.1;
    margin: 0 0 14px;
  }

}

.connect-block {
  .feedback-form {
    padding: 0 0 40px;

    .checkbox:not(checked) + label:before {
      background: #fff;
    }

    .input-form {
      background: #fafafa;
    }

    .col-6 {
      width: 50%;

      @include media(sm) {
        width: 100%;
      }
    }

    .field.check-agree {
      & > .input__wrapper {
        & > label {
          color: #fff;

          & > a {
            color: #fff;
          }
        }
      }
    }

  }
}


.search-block {
  background: #fff;
  padding: 40px 0;

  &__item {
    border-bottom: 1px solid #eee;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }

  &__img {
    float: left;
    padding: 0 25px 0 0;
    width: 125px;

    & > a {
      display: block;

      & > img {
        display: block;
        height: auto;
        max-width: 150px;
        width: 100%;
      }
    }
  }

  &__wrapper {
    float: right;
    width: calc(100% - 125px);
  }

  &__title {
    margin: 0 0 10px;

    & > a {
      color: #000;
      display: inline-block;
      font-size: 17px;
      font-weight: 600;
      line-height: 25px;
    }
  }
}

.no-mobile {
  .search-block__title {
    & > a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}



.listview-item {
  float: left;
  padding: 0 1em;
  width: 50%;
}


.services {
  padding: 60px 0;
}


.services-item {
  float: left;
  position: relative;
  width: 25%;

  @include media(xlg) {
    width: 33.333333%;
  }

  @include media(slg) {
    width: 50%;
  }

  @include media(sm) {
    width: 100%;
  }

  & > img {
    border: 0.5px solid #fff;
    display: block;
    height: auto;
    max-width: 480px;
    width: 100%;
  }
  
  &__wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 2;
  }

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-out;

    @include media(md) {
      font-size: 18px;
    }
  }
}



/*
.no-mobile {
  .services-item {

    &__btn{
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: height 0.3s ease-out, opacity 0.2s ease-out 0s;
      visibility: hidden;
    }

    & > img {
      transition: 0.3s ease-out;
    }

    &:hover {

      .services-item__title {
        margin: 0 0 1em;
      }

      .services-item__btn {
        opacity: 1;
        height: 64px;
        transition: height 0.3s ease-out, opacity 0.2s ease-out 0s;
        visibility: visible;
      }

      & > img {
        filter: brightness(60%);
      }
    }
  }
}

*/


.services-addit-block {
  background: url(/assets/images/serv-addit.jpg) no-repeat;
  background-size: cover;
  padding: 60px 0;
  position: relative;
  z-index: 2;
  
  .h2title {
    color: #fff;
  }
}

.services-addit {
  @extend .clearfix;

  padding: 30px 0;

  &.cols-5 {
    & > .services-addit__item {
      width: 20%;

      @include media(xmd) {
        margin: 0 0 2em;
        width: 33.333%;

        &:nth-child(4n + 4) {
          margin: 0 0 0 16.665%;
        }
      }
    }
  }

  &__item {
    float: left;
    padding: 0 1em;

    & > a {
      display: block;
      text-align: center;
    }
  }

  &__icon {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    padding: 34px;
    margin: 0 0 1em;
    height: 148px;
    width: 148px;


    & > svg {
      display: block;
      height: auto;
      max-width: 80px;
      width: 100%;

      & > path {
        fill: #111111;
      }
    }


    @include media(wide) {
      padding: 31px;
      height: 120px;
      width: 120px;
    }

    @include media(xlg) {
      padding: 22px;
      height: 90px;
      width: 90px;
    }

    @include media(md) {
      padding: 16px;
      height: 70px;
      width: 70px;
    }

  }


  &__title {
    color: #fff;
    display: block;
    font-size: 22px;
    line-height: 1.2;
    letter-spacing: 0.333px;
    min-height: 45px;
    padding: 0 1em;

    @include media(wide) {
      font-size: 18px;
    }

    @include media(xlg) {
      font-size: 16px;
    }

    @include media(lg) {
      font-size: 15px;
    }

    @include media(slg) {
      font-size: 14px;
    }

    @include media(md) {
      font-size: 13px;
      padding: 0;
    }

    @include media(sm) {
      font-size: 12px;
    }
  }
}


.no-mobile {

  .services-addit__icon {
    transition: 0.3s ease-out;

    & > svg {
      & > path {
        transition: 0.3s ease-out;
      }
    }
  }

  .services-addit__item {

    & > a {
      &:hover {

        & > .services-addit__icon {
          background: $haki;

          & > svg {
            & > path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}


.video-presentation {
  margin: 2em auto 4em;

  & > .video-responsive {
    padding-bottom: 56%;
  }
}


.connect-block {
  background: url(/assets/images/connect.jpg) no-repeat;
  background-size: cover;
  padding: 60px 0 0;

  & > .container {
    max-width: 731px;
  }

  .h2title {
    color: #fff;
    margin: 0 0 1em;

    &:after {
      background: #fff;
    }

    @include media(md) {
      margin: 0 0 1em;
    }

    @include media(sm) {
      margin: 0 0 1em;
    }
  }

  &__desc {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
  }
}


.contact-block {
  position: relative;
  margin: 90px 0;

  & > img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  &__wrapper {
    background: rgba(10, 75, 155, 0.79);
    left: 50%;
    padding: 26px 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;

    @include media(xmd) {
      transform: translateX(-50%) translateY(-50%);
    }

    & > .h2title {
      color: #fff;
      padding: 0 0 24px;
      text-align: left;
    }
  }

  &__adress {
    & > span {
      color: #fff;
      display: block;
      line-height: 47px;

      & > a {
        color: #fff;
        display: inline-block;
      }
    }
  }
}

.no-mobile {

  .contact-block__adress {
    & > span {
      & > a {
        &:hover {
          color: #fff;
        }
      }
    }
  }
}



.online-services {
  padding: 0 10% 0 0;

  &__wrapper {
    @extend .clearfix;

    margin: 10% 0 0;

    a {
      display: block;
      padding: 0 1em 0 0;

      & > span {
        color: #fff;
        display: block;
        font-size: 20px;
        line-height: 1.1;

        @include media(xlg) {
          font-size: 18px;
        }

        @include media(lg) {
          font-size: 16px;
        }

        @include media(md) {
          font-size: 15px;
        }

        @include media(xmd) {
          font-size: 13px;
        }

      }
    }

    .col-4 {
      width: 33.333%;
    }

    @include media(md) {
      margin: 5% 0 0;
    }
  }


  &__title {
    & > a,
    & > span {
      background: $haki;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      font-size: 15px;
      letter-spacing: 0.333px;
      padding: 7px 1em 6px;
      text-align: center;
      text-transform: uppercase;
      width: 274px;
    }
  }

  &__icon {
    height: 56px;
    margin: 0 0 11px;
    width: 56px;

    & > svg {
      display: block;
      height: auto;
      max-width: 56px;
      width: 100%;

      & > path {
        fill: #ffffff;
      }
    }

    @include media(lg) {
      height: 40px;
      margin: 0 0 9px;
      width: 40px;
    }
  }

  @include media(md) {
    padding: 0;
  }
}


.no-mobile {
  .online-services {
    &__wrapper {
      a {
        & > span {
          transition: 0.3s ease-out;

          & > svg {
            & > path {
              transition: 0.3s ease-out;
            }
          }
        }

        &:hover {
          & > span {
            color: $haki;

            & > svg {
              & > path {
                fill: $haki;
              }
            }
          }
        }
      }
    }

    &__title {
      & > a {
        &:hover {
          background: #fff;
          color: $haki;
        }
      }
    }
  }
}

.transportation-page-slider-block {
  margin: 0 0 2em;
}

.transportation-page-slider-block,
.services-page-slider-block {

  &__title {
    color: #212429;
    font-size: 22px;
    text-transform: uppercase;
  }
}


.services-slider {
  padding: 0 40px;
}


.transportation-page-slider,
.services-page-slider {
  padding: 2em 40px;

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}


.services-details {
  position: relative;
  opacity: 1;
  transition: 0.2s ease-out;
  visibility: visible;

  &.hide {

    & > .service-item {
      opacity: 0;
      visibility: hidden;
    }

    & > .loader {
      visibility: visible;
    }

  }

  & > .loader {
    position: absolute;
    visibility: hidden;
  }
}

.service-item {
  padding: 30px 0 50px 160px;
  position: relative;
  opacity: 1;
  transition: 0.2s ease-out;
  visibility: visible;

  @include media(md) {
    padding: 30px 0 50px;
  }

  &.--right {
    padding: 30px 160px 50px 0;

    @include media(md) {
      padding: 30px 0 50px;
    }

    & > .service-item__info {
      left: auto;
      right: 0;
      text-align: right;

      @include media(md) {
        text-align: left;
      }
    }

    .service-item__desc {
      padding: 0 0 0 11%;

      @include media(md) {
        padding: 0 11% 0 0;
      }
    }
  }

  &__info {
    background: $haki;
    padding: 4em;
    position: absolute;
    top: 0;
    left: 0;
    width: 480px;
    z-index: 2;

    @extend .clearfix;

    @include media(lg) {
      padding: 3em;
      width: 392px;
    }

    @include media(md) {
      padding: 2em 1em;
      position: static;
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 1em;

    & > span {
      color: #fff;
      display: inline-block;
      font-size: 34px;
      font-weight: bold;
      line-height: 1.3;
      text-transform: uppercase;

      @include media(lg) {
        font-size: 26px;
      }
    }
  }

  &__img {

    & > img {
      display: block;
      height: auto;
      max-width: 1020px;
      width: 100%;
    }
  }

  &__desc {
    color: #fff;
    font-size: 17px;
    line-height: 1.3;
    padding: 0 11% 0 0;

    @include media(lg) {
      font-size: 15px;
    }
  }
  
  &__btn {
    margin: 3em 0 0;

    & > .button {
      display: inline-block;
      padding: 0;
      max-width: 280px;
      width: 100%;

      @include media(lg) {
        max-width: 230px;
      }
    }
  }

  &:after {
    background: rgba(73, 96, 64, 0.79);
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    right: -36px;
    bottom: 0;
    height: 80px;
    width: 80px;
    z-index: 2;
  }
}


.services-slider-item {
  padding: 0 1em;

  &__wrapper {
    cursor: pointer;
    margin: 0 auto;
    max-width: 176px;
    text-align: center;

    & > a {
      display: block;

      & > .icon {
        background: #efefef;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px;

        & > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%;

          & > path {
            fill: #6e6e6e;
          }
        }
      }
    }

    /*&.active {
      .icon {
        background: $haki;

        & > svg {
          & > path {
            fill: #fff;
          }
        }
      }
    }*/

    &:first-child {
      margin: 0 auto 1em;
    }
  }

  &__title {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
    min-height: 61px;
    padding: 0 13%;
  }
}


.no-mobile {
  .services-slider-item {

    &__wrapper {

      & > a {
        & > .services-slider-item__title {
          transition: 0.3s ease-out;
        }

        & > .icon {
          transition: 0.3s ease-out;

          & > svg {
            & > path {
              transition: 0.3s ease-out;
            }
          }
        }
      }

      &:hover {
        & > a {
          & > .icon {
            background: $haki;

            & > svg {
              & > path {
                fill: $white;
              }
            }
          }

          & > .services-slider-item__title {
            color: $haki;
          }
        }
      }
    }
  }
}


.services-page-slider-item {
  padding: 0 1em;

  &__wrapper {
    cursor: pointer;
    margin: 0 auto;
    max-width: 176px;
    text-align: center;

    & > span {
      display: block;

      & > .icon {
        background: $haki;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px;

        & > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%;

          & > path {
            fill: #fff;
          }
        }
      }
    }

    &:first-child {
      margin: 0 auto 1em;
    }

    & > a {
      display: block;

      & > .icon {
        background: #efefef;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px;

        & > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%;

          & > path {
            fill: #6e6e6e;
          }
        }
      }
    }

  }

  &__title {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
    min-height: 61px;
    padding: 0 13%;
  }
}

.no-mobile {
  .services-page-slider-item {

    &__wrapper {


      & > .services-page-slider-item__title {
        transition: 0.3s ease-out;
      }

      & > .icon {
        transition: 0.3s ease-out;

        & > svg {
          & > path {
            transition: 0.3s ease-out;
          }
        }
      }

      &:hover {
        & > .icon {
          background: $haki;

          & > svg {
            & > path {
              fill: $white;
            }
          }
        }

        & > .services-page-slider-item__title {
          color: $haki;
        }
      }
    }
  }
}


.transportation-page-slider-item {
  padding: 0 1em;

  &__wrapper {
    cursor: pointer;
    margin: 0 auto;
    max-width: 176px;
    text-align: center;

    & > span {
      display: block;

      & > .icon {
        background: $haki;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px;

        & > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%;

          & > path {
            fill: #fff;
          }
        }
      }
    }

    &:first-child {
      margin: 0 auto 1em;
    }

    & > a {
      display: block;

      & > .icon {
        background: #efefef;
        border-radius: 50%;
        padding: 21px;
        height: 82px;
        width: 82px;

        & > svg {
          display: block;
          height: auto;
          max-width: 41px;
          width: 100%;

          & > path {
            fill: #6e6e6e;
          }
        }
      }
    }

  }

  &__title {
    color: #6e6e6e;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
    min-height: 61px;
    padding: 0 13%;
  }
}

.no-mobile {
  .transportation-page-slider-item {

    &__wrapper {

      & > .transportation-page-slider-item__title {
        transition: 0.3s ease-out;
      }

      & > .icon {
        transition: 0.3s ease-out;

        & > svg {
          & > path {
            transition: 0.3s ease-out;
          }
        }
      }

      &:hover {
        & > .icon {
          background: $haki;

          & > svg {
            & > path {
              fill: $white;
            }
          }
        }

        & > .services-page-slider-item__title,
        & > .services-slider-item__title {
          color: $haki;
        }
      }

    }
  }
}


.white-angle-bg {
  background: #fff url(/assets/images/wt-angle-bg.jpg) top left no-repeat;
  background-size: contain;
}



.directions-block {
  padding: 60px 0;

  &__map {
    float: left;
    padding: 5em 1em 0;
    width: calc(100% - 335px);

    & > object {
      display: block;
      height: auto;
      max-width: 1200px;
      width: 100%;
    }

    @include media(lg) {
      padding: 3em 1em 0;
      width: 100%;
    }

    @include media(md) {
      padding: 2em 1em 0;
    }
  }

  &__info {
    float: right;
    padding: 8em 1em 0;
    opacity: 0;
    transition: 0.2s ease-out;
    visibility: hidden;
    width: 335px;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    @include media(lg) {
      padding: 2em 1em 0;
      width: 100%;
    }
  }
}

.directions-item {

  &__title {
    color: $haki;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 14px;

    @include media(lg) {
      font-size: 21px;
    }
    @include media(md) {
      font-size: 18px;
    }
  }

  &__text {
    color: #6e6e6e;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 35px;

    @include media(lg) {
      font-size: 15px;
      margin: 0 0 30px;
    }

    @include media(md) {
      font-size: 14px;
      margin: 0 0 25px;
    }
  }

  &__btn {
    & > .button {
      max-width: 280px;
      width: 100%;
    }
  }
}


.no-mobile {

  .directions-block {
    &__map {

      & > svg {
        & > path {
          &:hover {
            fill: #333;
          }
        }
      }
    }
  }
}


.calc-form {

  .button {
    width: 100%;
  }

  .info-wrapper {
    @extend .clearfix;

    & > .col-6 {
      padding: 0;
      width: 50%;

      &:last-child {
        text-align: right;
      }

      & > .icon {
        margin: 0 10px 0 0;
        height: 22px;
        width: 22px;

        & > svg {
          display: block;
          height: auto;
          max-width: 22px;
          width: 100%;
        }
      }

      & > span {
        color: #fff;
        display: inline-block;
        font-size: 14px;
        vertical-align: middle;

        & > i {
          color: #5d7a52;
          display: inline-block;
          font-size: 19px;
          font-style: normal;
          padding: 0 2px;
          vertical-align: middle;
        }
      }
    }
  }

  .field {
    .form__label.animate {
      top: 2px;
    }
  }

  .input-text {
    padding: 10px 1em 0;
  }
}


.check-status-form {
  @extend .clearfix;

  .field {
    float: left;
    padding: 0 1em 0 0;
    width: calc(100% - 50px);

    &.btn {
      padding: 0;
      width: 50px;
    }
  }
}


.transport-type-block-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.transport-type-block {

  &__more {
    background: #fff;
    padding: 3em 0;
    position: relative;
    margin: -5px 0 4em;
    text-align: center;
    z-index: 2;

    & > .button {
      max-width: 280px;
      width: 100%;
    }
  }
}

.transport-type {
  @extend .clearfix;

  margin: 4em 0 0;
  overflow: hidden;
  height: 484px;
  transition: 0.2s ease-out;

  &.open {
    height: 100%;
  }

  @include media(xmd) {
    height: 465px;
  }

  @include media(xs) {
    height: 496px;
  }

  @include media(350px) {
    height: 530px;
  }
}


.transport-type-item {
  border-right: 1px solid #e4e0dc;
  border-bottom: 1px solid #e4e0dc;
  float: left;
  padding: 10px 3% 2em;
  min-height: 243px;
  width: 25%;

  &:nth-child(4n + 4) {
    border-right: none;
  }


  @include media(lg) {
    width: 33.333%;

    &:nth-child(4n + 4) {
      border-right: 1px solid #e4e0dc;
    }
    &:nth-child(3n + 3) {
      border-right: none;
    }
  }

  @include media(xmd) {
    min-height: 232px;
    padding: 10px 3% 1em;
    width: 50%;

    &:nth-child(3n + 3) {
      border-right: 1px solid #e4e0dc;
    }

    &:nth-child(2n + 2) {
      border-right: none;
    }
  }

  @include media(xs) {
    min-height: 247px;
  }

  @include media(350px) {
    min-height: 264px;
  }

  & > a {
    display: block;
  }

  &__icon {
    display: block;
    margin: 0 0 10px;

    & > svg {
      display: block;
      height: auto;
      max-width: 44px;
      width: 100%;
    }
  }

  &__title {
    color: $black;
    display: block;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.333;
    margin: 0 0 6px;
    min-height: 50px;

    @include media(md) {
      font-size: 16px;
    }
  }

  &__text {
    color: $textColor;
    display: block;
    font-size: 14px;
    line-height: 1.333;

    @include media(md) {
      font-size: 13px;
    }
  }
}

.aside-services-block {
  margin: 0 0 4em;
}


.aside-services-item {
  background: #efefef;
  border-radius: 5px;
  display: table;
  margin: 0 0 5px;
  width: 100%;

  & > .order-item,
  & > .review-item {
    cursor: pointer;
  }

  &.active {
    background: $haki;

    .aside-services-item__title {
      color: #fff;
    }

    .aside-services-item__icon {
      background: #fff;

      & > svg {
        & > path {
          fill: $haki;
        }
      }
    }
  }

  & > span,
  & > a {
    display: block;
    padding: 35px 40px;
    position: relative;
    min-height: 152px;
  }

  &__icon {
    background: $haki;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 35px;
    left: 40px;
    height: 82px;
    padding: 18px;
    width: 82px;

    & > svg {
      display: block;
      height: auto;
      max-width: 45px;
      width: 100%;
    }
  }

  &__title {
    color: $textColor;
    display: block;
    font-size: 15px;
    padding: 19px 10px 0 114px;
    line-height: 1.2;
    transition: 0.2s ease-out;
    user-select: none;
  }
}

.no-mobile {
  .aside-services-item {
    transition: background-color 0.2s ease-out;

    &__icon {
      transition: background-color 0.2s ease-out;

      & > svg {
        & > path {
          transition: fill 0.2s ease-out;
        }
      }
    }

    &:hover {
      background: $haki;
      
      .aside-services-item__title {
        color: #fff;
      }

      .aside-services-item__icon {
        background: #fff;

        & > svg {
          & > path {
            fill: $haki;
          }
        }
      }
    }
  }
}


.aside-services-all {
  margin: 13px 0 0;

  & > .button {
    width: 100%;
  }
}




.r-block {
  margin: 0 0 2em;

  &__title {
    color: $black;
    font-size: 23px;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  &__item {
    background: #efefef;
    border-radius: 3px;
    margin: 3px 0;
    
    & > span {
      color: #496040;
      cursor: default;
      display: block;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
    }

    & > a {
      color: $textColor;
      display: block;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.online-services-page {

  &__item {
    float: left;
    padding: 0 1em;
    margin: 0 0 2em;
    width: 33.33333%;

    & > span {
      cursor: pointer;
    }

    & > span,
    & > a {
      display: block;
    }
  }
  
  &__title {
    color: #6e6e6e;
    display: block;
    line-height: 1.333;
    text-align: center;
  }

  &__icon {
    display: block;
    padding: 0 1em 1em;
    text-align: center;

    & > svg {
      display: inline-block;
      height: auto;
      max-width: 100px;
      width: 100%;

      & > path {
        fill: #000000 !important;
      }
    }
  }
}


.bottom-slider {
  padding: 0 3em;
  margin: 3em 0 0;

  .slick-arrow {
    border-color: #3b603e;

    & > span:before {
      background: #3b603e;
    }

    &:after,
    &:before {
      background: #3b603e;
    }
  }
}

.no-mobile {
  .bottom-slider {
    .slick-next {
      right: 0;
    }
    .slick-prev {
      left: 0;
    }
  }
}


.bottom-slider-block {
  &__title {
    color: $black;
    font-size: 22px;
    text-transform: uppercase;
  }
}


.bottom-slider-item {
  padding: 0 1em;
  text-align: center;

  &__icon {
    background: #efefef;
    border-radius: 50%;
    display: inline-block;
    height: 82px;
    padding: 18px;
    margin: 0 0 5px;
    width: 82px;

    & > svg {
      display: block;
      height: auto;
      max-width: 45px;
      width: 100%;

      & > path {
        fill: $black;
      }
    }
  }

  &__title {
    color: $textColor;
    display: inline-block;
    line-height: 1.333;
    text-align: center;
  }
}


.popup-order-call {
  background: #fff;
  border-left: 1px solid #f8f8f8;
  box-shadow: 10px 10px 20px 0 rgba(0,0,0,.13);
  cursor: default;
  position: absolute;
  padding: 1em 65px;
  top: 100%;
  right: 1em;
  opacity: 0;
  transition: 0.2s ease-out;
  visibility: hidden;
  width: 405px;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 31px;
    top: -13px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 16px solid #fff;
    z-index: 1;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 3;
  }

  &__title {
    color: #3a3a3a;
    font-size: 20px;
    text-align: center;
  }

  .field {

    &.chb {
      & > .input__wrapper > label {
        font-size: 11px;
        line-height: 1;
      }
    }

    &.last {
      margin: 9px 0 0;

      .input__wrapper {
        margin: 1em 0 0;
      }
    }
  }


  @include media(lg) {
    right: 0;
    padding: 1em 2em;
    width: 339px;

    &:before {
      left: 8em;
    }
  }

  @include media(580px) {
    width: 320px;

    &:before {
      left: 12.68em;
    }
  }

}


.order-block {

  &.hide {
    display: none;
  }


}


.calc-block,
.order-choice {

  &__title {
    margin: 0 0 3em;
    text-align: center;

    & > span {
      color: $black;
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
    }
  }
}



.order-tabs__item {
  display: block;
  float: left;
  cursor: pointer;
  position: relative;
  width: 50%;

  &.complect {
    padding: 0 1em 0 0;
    text-align: right;
  }

  &.car {
    padding: 0 0 0 1em;
    text-align: left;

    & > .order-tabs__title {
      max-width: 122px;
      text-align: center;
    }

    & > .icon {
      padding: 1.7em 1.2em;
    }
  }

  &.active {
    & > .icon {
      background: $haki;

      & > svg {
        & > path {
          fill: #fff;
        }
      }
    }
  }

  & > .icon {
    background: #efefef;
    border-radius: 50%;
    display: inline-block;
    padding: 1.2em;
    margin: 0 0 1em;
    height: 120px;
    width: 120px;

    & > svg {
      display: block;
      height: auto;
      max-width: 100px;
      width: 100%;

      & > path {
        fill: $haki;
      }
    }
  }

  &__title {
    color: $grey;
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
  }
}


.no-mobile {
  .order-choice-item {
    & > .icon {
      transition: 0.2s ease-out;
    }

    &__title {
      transition: 0.2s ease-out;
    }

    &:hover {
      .order-choice-item__title {
        color: $haki;
      }

      .order-choice-item > .icon {
        background: $haki;

        & > svg {
          & > path {
            fill: #fff;
          }
        }
      }
    }
  }
}


.cargo-order-block {
  margin: 3em 0 0;

  &.hide {
    display: none;
  }

  &__title {
    margin: 0 0 2em;
    text-align: center;

    & > span {
      display: inline-block;
      color: $black;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.cargo-order-result {
  padding: 2em 0;

  &__price {
    padding: 1em 0 4em;
    position: relative;
    text-align: center;

    & > .loader {
      margin: -12px 0 0 -34px;
      position: absolute;
    }
  }

  &__title {
    text-align: center;
  }

  &__track {
    text-align: center;
  }

  &__btn {
    margin: 2em 0 0;
    text-align: center;
  }

  &.hide {
    display: none;
  }
}


.order-tabs {
  position: relative;

  &.hide {
    display: none;
  }

  &__title {
    color: $haki;
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
  }
}


.field-wrapper {
  display: none;

  &.active {
    display: block;
  }
}


.cargo-type {
  display: none;

  &.active {
    display: block;
  }
}

.field-set {
  margin: 1em 0;

  &__title {
    color: #212429;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}


.calc-order-form,
.cargo-order-form {

  .noop {
    display: block;
    height: 1px;
    width: 100%;
  }

  .select-wrapper {
    display: block;
  }

  .example {
    font-size: 12px;
    padding: 3px 0 0;
  }

  .dim-title {
    height: 40px;
    line-height: 39px;
    text-align: center;
    width: 40px;
  }

  .field {
    @extend .clearfix;

    &.lastest {
      &.hide {
        display: none;
      }
    }

    &.check-place-dim {
      display: none;

      &.active {
        display: block;
      }
    }

    & > .field__title {
      float: left;
      padding: 0 1em 0 0;
      text-align: right;
      width: 150px;

      & > span {
        color: $black;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;
      }
    }

    & > .input-wrapper {
      float: right;
      padding: 0 0 0 1em;
      width: calc(100% - 150px);


      &.dop-addr {
        display: none;
        margin: 1em 0 0;

        &.active {
          display: block;
        }
      }


      &.chb {

        & > div {
          display: inline-block;
          margin: 0 1em 0 0;
          vertical-align: middle;

          &:before {
            display: none;
          }

          & > label {
            padding: 0 0 0 21px;
          }
        }
      }
    }

    .input-text {
      padding: 0 2em 0 1em;
    }

    &.dim {

      .fake-input {
        background: #e6e6e6;
        border: 1px solid #e6e6e6;
        border-radius: 3px;
        display: block;


        @extend .clearfix;

        &.has-success {
          border-color: $colorOk !important;

          & > .input-text {
            border-color: transparent !important;
          }
        }

        &.has-error {
          border-color: $colorError !important;

          & > .input-text {
            border-color: transparent !important;
          }
        }

        & > span {
          color: $inputColor;
          display: block;
          float: left;
          font-size: 15px;
          line-height: 37px;
          height: 38px;
          text-align: center;
          width: 20px;
        }

        & > .input-text {
          background: transparent;
          border-color: transparent;
          display: block;
          float: left;
          padding: 0 1em;
          height: 38px;
          line-height: 37px;
          text-align: center;
          width: calc(33.333% - 14px);
        }
      }

      & > .input-wrapper {
        float: left;
        padding: 0 1em;
        width: calc(100% - 150px);

        &.has-success:before,
        &.has-error:before {
          right: 1em;
        }
      }

      & > .select-wrapper {
        background: #e6e6e6;
        border-radius: 3px;
        border: 1px solid #e6e6e6;
        float: right;
        height: 40px;
        width: 40px;

        & > select {
          cursor: pointer;
          height: 38px;
          padding: 0 13px 0 6px;
        }

        &:before {
          right: 3px;
          margin-top: -4px;
        }
      }
    }

    &.vol {

      & > .input-wrapper {
        &:before {
          right: 48px;
        }
      }

      .input-text {
        float: left;
        width: calc(100% - 40px);
      }

      .volume-title {
        float: right;
        line-height: 39px;
        text-align: center;
        width: 40px;
      }
    }

    &.rad {
      & > .input-wrapper {
        & > div {
          display: inline-block;
          vertical-align: middle;

          & > label {
            padding: 0 0 0 24px;
          }

          &:first-child {
            margin: 0 2em 0 0;
          }
        }
      }
    }
  }
}

.flexbox {

  .calc-order-form,
  .cargo-order-form {

    .field {
      display: flex;

      &.check-place-dim {
        display: none;

        &.active {
          display: flex;
        }
      }

      &.add-adr {
        flex-wrap: wrap;
        justify-content: flex-end;

        & > .input-wrapper {
          &.dop-addr {

          }
        }
      }

      &.dim {

        .fake-input {

        }

        & > .select-wrapper {
          flex-basis: 40px;
        }

        & > .input-wrapper {
          flex-basis: calc(100% - 150px - 40px);
        }
      }

      &.vol {
      }

      & > .input-wrapper {
        float: none;
        flex-basis: calc(100% - 150px);
        width: auto;
      }

      & > .field__title {
        align-items: center;
        display: flex;
        float: none;
        flex-basis: 150px;
        flex-wrap: wrap;
      }
    }
  }
}

.sitemap-title {
  text-align: center;

  & > span {
    color: #212429;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0 0 1em;
  }
}

.content-inner .sitemap_sub {
  display: block;

  li {
    color: $textColor;

    span {
      cursor: pointer;
    }

    span,
    a {
      color: $textColor;
      display: inline-block;
      font-size: 15px;
    }
  }
}


.contacts-map {
  margin: 2em 0 0;
}

.tarif-table {
  table-layout: fixed;
  width: 100%;
  
  & > thead {
    & > tr {
      & > th {
        line-height: 1.2;
        text-align: left;

        &:nth-child(1) {
          width: 24%;
        }

        &:last-child {
          width: 11%;
        }

        @include media(sm) {
          font-size: 11px;
        }
      }
    }
  }
  
  & > tbody {
    & > tr {
      border-bottom: 1px solid #efefef;

      & > td {
        padding: 2px;

        &[colspan="6"] {
          font-weight: bold;
          text-align: center;
        }

        @include media(sm) {
          font-size: 11px;
        }
      }
    }
  }
}

.apark {

  &__title {
    color: #212429;
    font-size: 22px;
    text-transform: uppercase;
  }
}

.index {
  .apark {
    &__title {
      @extend .h2title;
    }
  }

  .apark-item {
    @include media(882px) {
      text-align: center;
    }
  }

  .apark-item__buttons {
    & > .button {
      display: block;

      &:first-child {
        margin: 0 0 10px;
      }
    }
  }

  .apark-item__header {
    @include media(882px) {
      display: inline-block;
    }
  }

  .apark-item__wrapper {
    @include media(882px) {
      display: inline-block;
    }
  }

  .apark-item__info {
    max-height: 181px;
    overflow: hidden;
    width: calc(100% - 154px);

    @include media(882px) {
      text-align: left;
    }
  }

  .apark-item__photo {
    width: 154px;
  }

  .apark-slider {
    padding: 2em 55px;

    .slick-next {
      right: 1em;
    }

    .slick-prev {
      left: 1em;
    }
  }
}


.apark-slider {
  padding: 2em 40px;

  .slick-arrow {
    top: 50%;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }
}


.apark-block {
  .apark-item {
    border: 1px solid #eee;
    margin: 0 0 2em;
  }
}


.apark-item {
  padding: 1em;

  &__header {
    padding: 0 0 0.5em;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
  }

  &__photo {
    float: left;
    width: 40%;

    & > img {
      display: block;
      height: auto;
      max-width: 334px;
      width: 100%;
    }
  }

  &__info {
    float: left;
    padding: 0 0 0 2em;
    width: 60%;

    & > p {
      line-height: 1.666;
      padding: 0;
    }
  }

  &__buttons {
    margin: 1em 0 0;

    & > .button {
      &:first-child {
        margin: 0 10px 0 0;
      }

      & > span {
        font-size: 12px;
        height: 28px;
        line-height: 28px;
      }
    }
  }
}

.no-index {

  @include media(480px) {

    .apark-item__title {
      text-align: center;
    }

    .apark-item__photo {
      float: none;
      width: 100%;

      & > img {
        margin: 0 auto;
      }
    }

    .apark-item__buttons {
      text-align: center;
    }

    .apark-item__info {
      display: none;
    }
  }
}


.apark-header {
  @extend .clearfix;

  margin: 0 0 2em;

  &__img {
    float: left;
    padding: 0 1em 0 0;
    width: 50%;

    & > a,
    & > span {
      & > img {
        display: block;
        height: auto;
        max-width: 551px;
        width: 100%;
      }
    }
  }

  &__info {
    float: right;
    padding: 0 0 0 1em;
    width: 50%;

    & > p {
      padding: 0;
    }
  }
}


.payment-search-order-form {
  & > .field {
    float: left;
    width: calc(100% - 168px);

    .input-text {
      padding: 0 1em;
    }

    &:first-child {

      & > .field__title {
        float: left;
        padding: 0 1em 0 0;
        text-align: right;
        width: 109px;

        & > span {
          color: #212429;
          display: inline-block;
          font-size: 14px;
          font-weight: 700;
          line-height: 38px;
          width: 100%;
        }
      }

      & > .input-wrapper {
        float: right;
        padding: 0 0 0 1em;
        width: calc(100% - 109px);
      }
    }

    &.btn {
      padding: 0 0 0 1em;
      width: 168px;

      .button {
        & > span {
          height: 36px;
          line-height: 36px;
        }
      }
    }
  }
}


.payment-block {

  &__title {
    margin: 0 0 3em;
    text-align: center;

    & > span {
      color: #212429;
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;
    }
  }

  &__icons {
    margin: 2em 0 0;
    text-align: center;

    & > span {
      display: inline-block;

      &:not(:last-child) {
        margin: 0 1em 0 0;
      }

      & > svg {
        display: block;
        height: 21px;
        max-width: 68px;
        width: auto;
      }
    }
  }

}


.p-form {
  text-align: center;

  &__row {
    &.btn {
      margin: 1em 0 0;
    }
  }
}

