@charset "UTF-8";


/* Импорт sass модулей, по порядку */
/* Неиспользуемые модули надо закомментировать, используемые - раскомментировать */
@import "system/normalize";
@import "system/grid";
@import "system/preloader";
@import "media"; /* Медиа миксины. Как использовать см. внутри */
@import "slick/slick";
@import "system/fonts";        /* подгрузка шрифтов */
@import "system/icon";
@import "layout";              /* Основной склелет */
@import "styles";              /* Основные стили */
@import "system/forms";
@import "header/header";
@import "header/headermobile";
@import "slider";
@import "menu/menu_top";
//@import "menu/menu_side";
@import "menu/mobile_menu";
//@import "store/ajaxflowcart";




//@import "photo/photos";
//@import "qa";
//@import "messages";

@import "system/modules";

@import "news/news";
@import "news/newsfull";
@import "news/navigation";
@import "news/news-cats";

/*@import "reg/reg";
@import "reg/profile";
*/
